import { useState, useEffect, useMemo } from "react";

import { generatePath } from "react-router-dom";

import { getDataAnalysis } from "../../../api/api";
import { getDataCacheKey } from "../../../api/urlBuilder";
import { DashboardChart } from "../../../common/DashboardChart/DashboardChart";
import { SaveChartPopover, SaveChartPopoverAction } from "../../../common/SaveChartPopover/SaveChartPopover";
import { transform } from "../../../containers/analytics/dataTransformer";
import { ContextMenu, MenuItemKind } from "../../../library/ContextMenu/ContextMenu";
import { logError } from "../../../logger";
import { type AnalysisSubject } from "../../../models/AnalysisSubject";
import { type AnalysisType } from "../../../models/AnalysisType";
import { type BreakdownType } from "../../../models/BreakdownType";
import { type DataAnalysis } from "../../../models/DataAnalysis";
import { type Filter } from "../../../models/Filter";
import { type Project } from "../../../models/Project";
import { getHumanReadableSlug } from "../../../models/SavedChart";
import { type Tag } from "../../../models/Tag";
import { useDataCacheStore } from "../../../providers/DataCacheProvider/DataCacheProvider";
import { RoutePath } from "../../../RoutePath";
import { trackSavedDashboardChartTitleClick } from "../../../tracking";

interface Props {
    workspaceSlug: string;
    slug: string;
    tags: Tag[];
    projectMap: Record<string, Project>;
    analysisType: AnalysisType;
    analysisSubject: AnalysisSubject;
    filters: Filter[];
    breakdownType?: BreakdownType;
    title: string;
    description: string;
    linksDisabled: boolean;
    readOnly: boolean;
    onUpdateInfo(slug: string, name: string, description: string): void;
    onRemove(slug: string, name: string): void;
}

export function SavedDashboardChart({
    workspaceSlug,
    tags,
    projectMap,
    slug,
    analysisType,
    analysisSubject,
    filters,
    breakdownType,
    title,
    description,
    linksDisabled,
    readOnly,
    onUpdateInfo,
    onRemove,
}: Props) {
    const [saveChartPopoverOpen, setSaveChartPopoverOpen] = useState(false);

    const {
        selectors: { getAnalysisData },
        actions: { cacheAnalysisData },
    } = useDataCacheStore();

    const dataCacheKey = getDataCacheKey({ workspaceSlug, analysisType, analysisSubject, filters });
    const analysisData = getAnalysisData(dataCacheKey);

    const chartData = useMemo(() =>
        analysisData && transform(analysisData, projectMap, tags, analysisType, analysisSubject, breakdownType, workspaceSlug)
    , [analysisData, breakdownType]);

    function getLink() {
        return generatePath(RoutePath.SavedChart, {
            workspaceSlug,
            savedChartSlug: getHumanReadableSlug(title, slug),
        });
    }

    async function handleUpdateInfo(name: string, description: string) {
        setSaveChartPopoverOpen(false);

        onUpdateInfo(slug, name, description);
    }

    async function handleRemoveMenuItemClick() {
        onRemove(slug, title);
    }

    useEffect(() => {
        let abortController: AbortController | undefined;

        async function fetchData() {
            let dataAnalysis: DataAnalysis[] | null = [];

            try {
                abortController = new AbortController();

                dataAnalysis = await getDataAnalysis(workspaceSlug, analysisType, analysisSubject, filters, abortController.signal);
            } catch (error) {
                if (!(error instanceof DOMException) || error.name !== "AbortError") {
                    logError(error);

                    dataAnalysis = [];
                } else {
                    dataAnalysis = null;
                }
            }

            if (dataAnalysis) {
                cacheAnalysisData(dataCacheKey, dataAnalysis);
            }
        }

        fetchData();

        return () => {
            abortController?.abort();
        };
    }, []);

    function renderContextMenuItems() {
        if (readOnly) {
            return null;
        }

        return (
            <>
                <ContextMenu.Button
                    onClick={() => setSaveChartPopoverOpen(true)}>
                    Edit name &amp; description
                </ContextMenu.Button>
                <ContextMenu.Separator/>
                <ContextMenu.Button
                    kind={MenuItemKind.Critical}
                    onClick={handleRemoveMenuItemClick}>
                    Remove from dashboard
                </ContextMenu.Button>
            </>
        );
    }

    function renderSaveChartPopover(anchor: HTMLElement | null) {
        if (!anchor || !saveChartPopoverOpen) {
            return null;
        }

        return (
            <SaveChartPopover
                anchor={anchor}
                action={SaveChartPopoverAction.Update}
                offset={5}
                chartName={title}
                chartDescription={description}
                onSave={handleUpdateInfo}
                onCancel={() => setSaveChartPopoverOpen(false)}/>
        );
    }

    return (
        <DashboardChart
            workspaceSlug={workspaceSlug}
            data={chartData}
            tags={tags}
            projectMap={projectMap}
            title={title}
            description={description}
            analysisType={analysisType}
            analysisSubject={analysisSubject}
            breakdownType={breakdownType}
            link={getLink()}
            linksDisabled={linksDisabled}
            contextMenuItems={renderContextMenuItems()}
            emptyStateMessage="No results with this selection."
            renderSaveChartPopover={renderSaveChartPopover}
            onHeaderClick={() => trackSavedDashboardChartTitleClick({ title, description, slug })}/>
    );
}
