import { showNewMessage } from "../../../../intercom";
import { EggType, Egg } from "../../../../library/Egg/Egg";
import { PopoverDirection, Popover } from "../../../../library/Popover/Popover";

import classes from "./TalkToAnExpertPopup.module.css";

interface Props {
    anchor: HTMLElement;
    onCancel(): void;
}
export function TalkToAnExpertPopup({
    anchor,
    onCancel,
}: Props) {
    function handleSendMessageButtonClick() {
        showNewMessage("Omlet Feedback: ");
    }

    return (
        <Popover
            className={classes.talkToAnExpertPopover}
            anchor={anchor}
            direction={PopoverDirection.Vertical}
            offset={0}
            onClose={onCancel}
            onCancel={onCancel}>
            <div className={classes.talkToAnExportPopoverContent}>
                <Egg type={EggType.Cracked_2}/>
                <p>We’re happy to guide you on how you can use Omlet to track adoption.</p>
                <a href="/l/meeting-calendar">
                    Book a meeting
                </a>
                <p className={classes.sendMessage}>
                    You can also{" "}
                    <button onClick={handleSendMessageButtonClick}>
                        send us a message
                    </button>.
                </p>
            </div>
        </Popover>
    );
}
