import classNames from "classnames";

import { Callout, CalloutKind } from "../../../library/Callout/Callout";
import { IconComponents } from "../../../library/icons/IconComponents";
import { Feature } from "../../../models/Feature";
import { isFeatureEnabled, type Subscription } from "../../../models/Subscription";
import { subscriptionPlanToString } from "../../../models/SubscriptionPlan";
import { hasCustomPropertyFilter, type Tag } from "../../../models/Tag";
import { pluralize } from "../../../utils";

import { TagItem } from "./tagItem/TagItem";

import classes from "./TagList.module.css";

interface Props {
    tags: Tag[];
    selectedTag?: Tag;
    hasOverrides?: boolean;
    subscription: Subscription;
    isTagsCalloutHidden: boolean;
    readOnly?: boolean;
    onSeePlansClick(): void;
    onTagsCalloutDismiss(): void;
    onSelect(tagSlug?: string): void;
    onRename(tagSlug: string, name: string): void;
    onDelete(tag: Tag): void;
}

export function TagList({
    tags,
    selectedTag,
    hasOverrides = false,
    subscription,
    isTagsCalloutHidden,
    readOnly = false,
    onSeePlansClick,
    onTagsCalloutDismiss,
    onSelect,
    onRename,
    onDelete,
}: Props) {
    function renderTagsCallout() {
        if (isTagsCalloutHidden) {
            return null;
        }

        return (
            <section>
                <Callout
                    kind={CalloutKind.Onboarding}
                    onDismiss={onTagsCalloutDismiss}>
                    Use tags to group a subset<br/>
                    of your components using various filters.<br/>
                    <br/>
                    To create a new tag, start by filtering components on the right. 👉
                </Callout>
            </section>
        );
    }

    function renderTagLimitCallout() {
        const isCustomPropertiesEnabled = isFeatureEnabled(subscription, Feature.CustomProperties);
        const hasTagWithCustomPropertyFilters = tags.some(tag => hasCustomPropertyFilter(tag));
        const isWithinCustomPropertyLimit = isCustomPropertiesEnabled || !hasTagWithCustomPropertyFilters;

        const isWithinTagLimit = tags.length <= subscription.features.tagLimit;

        if (isWithinTagLimit && isWithinCustomPropertyLimit) {
            return null;
        }

        const tagLimitNotice = `you can have up to ${pluralize("tag", subscription.features.tagLimit)}`;
        const customPropertyNotice = "tags using custom properties are not available";

        let abilities;
        if (!isWithinTagLimit && isWithinCustomPropertyLimit) {
            abilities = tagLimitNotice;
        } else if (isWithinTagLimit && !isWithinCustomPropertyLimit) {
            abilities = customPropertyNotice;
        } else {
            abilities = `${tagLimitNotice} and ${customPropertyNotice}`;
        }

        return (
            <section>
                <Callout>
                    You’re currently on the{" "}
                    {subscriptionPlanToString(subscription.plan)} plan where {abilities}.{" "}
                    <button type="button" onClick={onSeePlansClick}>See plans</button>{" "}
                    and upgrade to activate your previously created tags.
                </Callout>
            </section>
        );
    }

    return (
        <div className={classes.tagList}>
            <section>
                <button
                    type="button"
                    className={classNames(classes.allComponentsButton, {
                        [classes.selected]: selectedTag === undefined,
                    })}
                    onClick={() => onSelect()}>
                    <IconComponents/> All components
                </button>
            </section>
            <section>
                <h3>Tags</h3>
                {tags.map(tag =>
                    <TagItem
                        key={tag.slug}
                        tag={tag}
                        tags={tags}
                        selected={tag === selectedTag}
                        hasOverrides={tag === selectedTag && hasOverrides}
                        readOnly={readOnly}
                        onSelect={onSelect}
                        onRename={onRename}
                        onDelete={onDelete}/>
                )}
            </section>
            {renderTagsCallout()}
            {renderTagLimitCallout()}
        </div>
    );
}
