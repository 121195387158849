export function AngelIllustration() {
    return (
        <svg width="240" height="96" viewBox="0 0 240 96" fill="none">
            <circle cx="120" cy="62" r="18" fill="#262B2E"/>
            <circle opacity=".2" cx="120" cy="62" r="15" transform="rotate(10 120 62)"
                fill="#6B7276"/>
            <path
                d="M122.605 47.228a3 3 0 0 0-3.476 2.433l-4.167 23.636a3 3 0 0 0 2.433 3.475c8.159 1.439 15.939-4.009 17.377-12.167 1.439-8.159-4.009-15.939-12.167-17.377z"
                fill="#F69833"/>
            <path fillRule="evenodd" clipRule="evenodd"
                d="M116.108 76.212c6.927-.242 12.98-5.289 14.232-12.388 1.252-7.1-2.711-13.913-9.138-16.51a3 3 0 0 0-2.073 2.347l-4.167 23.636a2.998 2.998 0 0 0 1.146 2.915z"
                fill="#FFC738"/>
            <path fillRule="evenodd" clipRule="evenodd"
                d="M118.21 28.681c10.011-1.323 18.396-.367 18.727 2.136.33 2.504-7.518 5.605-17.53 6.928-10.012 1.323-18.396.367-18.727-2.136-.33-2.503 7.518-5.605 17.53-6.928zm.224 1.7c9.386-1.24 17.163-.977 17.37.587.206 1.564-7.235 3.838-16.621 5.078-9.386 1.24-17.163.978-17.37-.587-.206-1.564 7.235-3.838 16.621-5.078z"
                fill="#F49229"/>
            <path fillRule="evenodd" clipRule="evenodd"
                d="M100.722 35.098a1.26 1.26 0 0 0-.042.512c.331 2.503 8.715 3.46 18.727 2.136 10.012-1.323 17.86-4.425 17.529-6.927a1.237 1.237 0 0 0-.173-.483c-.743 2.003-8.241 4.476-17.58 5.71-9.34 1.234-17.223.793-18.461-.948z"
                fill="url(#yunqd6fk2a)"/>
            <path
                d="M186.258 1.42c-10.181 33.027-30.399 43.252-41.547 47.846a.295.295 0 0 0-.18.298c.427 5.78 2.078 9.138 2.926 10.2a.29.29 0 0 0 .187.102c28.298 3.873 30.744-1.88 28.993-2.323-.931-.235-2.73-1.056-3.917-1.634-.239-.116-.203-.466.049-.552 20.526-7.024 22.049-14.157 17.019-13.43-1.206.174-3.741.535-6.283.883-.355.049-.519-.551-.199-.712 9.543-4.788 14.914-14.999 10.866-13.303a367.46 367.46 0 0 1-10.437 4.14c-.263.1-.503-.196-.365-.442 11.34-20.232 4.621-35.983 2.888-31.073z"
                fill="url(#rp0v627x3b)"/>
            <path
                d="M186.194 1.45c-10.352 33.544-29.746 43.364-41.361 48.195-.11.045-.055.203.063.18 25.02-4.833 39.667-31.51 44.427-44.968a.292.292 0 0 0 .009-.161c-.835-3.85-2.411-5.304-3.138-3.245z"
                fill="#53B7CE"/>
            <path fillRule="evenodd" clipRule="evenodd"
                d="M186.747 1.582c-5.145 16.687-12.85 27.605-20.684 34.927-7.833 7.32-15.773 11.026-21.352 13.314a.515.515 0 1 1-.391-.954c5.527-2.267 13.337-5.914 21.039-13.113 7.7-7.196 15.312-17.96 20.406-34.488l.004-.01.003-.01c.227-.643.637-1.248 1.324-1.248.321 0 .604.142.835.33.232.188.444.447.636.752.761 1.203 1.419 3.438 1.662 6.397.481 5.86-.65 14.762-6.133 24.767a366.335 366.335 0 0 0 9.877-3.927c.526-.22 1.09-.338 1.537-.086.5.282.573.845.527 1.298-.049.478-.243 1.052-.536 1.672-.296.627-.71 1.332-1.225 2.081-1.967 2.856-5.497 6.455-10.027 8.904 2.253-.312 4.389-.616 5.465-.772.679-.098 1.295-.071 1.798.104.514.179.924.522 1.11 1.035.179.497.115 1.064-.118 1.64-.234.58-.653 1.212-1.257 1.882-2.367 2.627-7.841 6.132-17.822 9.599 1.149.545 2.579 1.175 3.338 1.367.365.093.695.351.793.769.094.4-.058.804-.328 1.147-.534.677-1.702 1.354-3.707 1.867-4.044 1.035-11.839 1.49-26.063-.465a.515.515 0 1 1 .141-1.022c14.183 1.95 21.819 1.473 25.666.489 1.94-.497 2.833-1.101 3.154-1.508.115-.146.132-.233.134-.263a.192.192 0 0 0-.043-.014c-.986-.25-2.82-1.09-3.995-1.66-.713-.347-.531-1.302.108-1.521 10.228-3.502 15.633-7.005 17.858-9.475.554-.614.893-1.145 1.067-1.577.176-.435.169-.727.104-.905-.059-.163-.195-.313-.479-.412-.296-.103-.732-.14-1.311-.057-1.195.173-3.69.529-6.206.873-.547.076-.848-.365-.93-.66-.082-.296-.052-.81.421-1.048 4.661-2.358 8.306-6.035 10.28-8.901.492-.715.875-1.372 1.143-1.938.271-.574.41-1.026.442-1.336.019-.191-.004-.27-.018-.298-.039-.01-.211-.031-.623.142a368.261 368.261 0 0 1-10.429 4.137c-.763.29-1.364-.554-1.009-1.188 5.612-10.023 6.74-18.911 6.269-24.655-.237-2.89-.874-4.932-1.506-5.931a2.095 2.095 0 0 0-.415-.503c-.118-.096-.178-.1-.185-.1-.003 0-.007 0-.016.005a.244.244 0 0 0-.07.057c-.073.077-.169.227-.263.49zm8.239 27.534.005.006c-.004-.003-.005-.006-.005-.006zm-18.423 28.95-.005-.005.005.004z"
                fill="#000"/>
            <path fillRule="evenodd" clipRule="evenodd"
                d="M161.923 47.411a21.11 21.11 0 0 0-2.908.206.516.516 0 0 1-.14-1.02 22.136 22.136 0 0 1 3.057-.217c.972.008 1.939.112 2.579.434.329.166.646.428.766.836.121.41.004.828-.227 1.214-.45.751-1.494 1.663-3.248 2.798a.516.516 0 0 1-.56-.865c1.75-1.133 2.608-1.934 2.923-2.462.151-.253.132-.365.124-.393-.009-.029-.048-.11-.242-.208-.409-.206-1.163-.315-2.124-.323zM172.937 42.282c-.918.175-1.927.465-2.812.77a.515.515 0 0 1-.336-.974 22.1 22.1 0 0 1 2.955-.809c.955-.181 1.924-.27 2.614-.078.356.098.718.294.915.67.199.378.165.811.014 1.235-.294.825-1.14 1.923-2.639 3.379a.514.514 0 1 1-.718-.74c1.495-1.452 2.18-2.406 2.386-2.985.099-.278.058-.384.045-.41-.014-.026-.068-.098-.278-.156-.441-.122-1.202-.082-2.146.098z"
                fill="#000"/>
            <path fillRule="evenodd" clipRule="evenodd"
                d="M163.186 54.846c-.857.375-1.852.706-2.755.955a.515.515 0 1 0 .274.994c.935-.258 1.98-.605 2.894-1.005.89-.39 1.739-.866 2.2-1.414.238-.282.426-.648.376-1.07-.05-.424-.323-.762-.687-1.026-.709-.514-2.028-.941-4.087-1.294a.515.515 0 1 0-.174 1.016c2.054.352 3.158.751 3.656 1.112.238.173.265.284.268.313.004.03 0 .12-.14.286-.295.35-.945.747-1.825 1.133z"
                fill="#000"/>
            <path
                d="M49.113 1.574C59.69 35.88 80.693 46.498 92.27 51.267c.12.05.19.17.18.298-.442 6.014-2.162 9.506-3.042 10.605a.291.291 0 0 1-.187.102C59.824 66.29 56.216 58.18 58.035 57.72c.973-.246 2.86-1.11 4.092-1.71.24-.116.2-.466-.051-.552C40.8 48.206 42.19 42.923 45.446 43.642c1.201.173 2.599.525 4.497.872.352.064.518-.508.2-.674-9.976-5.22-15.608-17.744-11.39-15.977 1.67.699 6.07 2.492 10.87 4.31.263.1.502-.196.364-.442-11.749-20.977-2.674-35.258-.874-30.157z"
                fill="url(#n21yml2hcc)"/>
            <path
                d="M49.18 1.605C65.17 35.422 81.057 47.121 93 52.014 71.093 45.067 58.788 29.052 44.368 7.697a.297.297 0 0 1-.044-.23c.9-4.022 4.102-7.993 4.856-5.862z"
                fill="#53B7CE"/>
            <path fillRule="evenodd" clipRule="evenodd"
                d="M48.077 1.074c.109.016.336.122.532.677l.009.026.011.025c14.766 31.255 31.941 45.245 43.633 50.04a.535.535 0 1 0 .406-.99c-11.373-4.665-28.374-18.41-43.06-49.484C49.353.662 48.906.112 48.23.015c-.652-.094-1.27.276-1.772.793-1.025 1.055-1.989 3.133-2.511 5.954-1.036 5.592-.383 14.33 5.273 24.685A380.976 380.976 0 0 1 38.96 27.37c-.547-.23-1.189-.35-1.648.054-.224.197-.33.462-.37.717-.041.254-.025.532.024.815.192 1.11.972 2.735 2.154 4.517 2.274 3.43 6.196 7.678 10.999 10.392-.776-.125-1.514-.25-2.188-.362-.958-.161-1.786-.3-2.407-.39-.899-.13-1.912.087-2.324.92-.413.837-.016 1.895.96 2.977 1.964 2.176 6.899 5.209 17.143 8.749-.298.123-.62.253-.948.386-.927.374-1.909.77-2.56 1.096-.44.22-.766.598-.867 1.092-.1.485.035 1 .344 1.483.61.952 1.973 1.912 4.243 2.67 4.566 1.523 13.112 2.332 27.893.301a.535.535 0 0 0-.145-1.06c-14.721 2.023-23.075 1.19-27.409-.256-2.179-.727-3.265-1.583-3.68-2.232-.204-.318-.226-.55-.197-.69.026-.132.112-.258.298-.35.621-.312 1.511-.67 2.4-1.029.56-.226 1.12-.451 1.61-.664a.821.821 0 0 0 .497-.833.859.859 0 0 0-.582-.738c-10.606-3.616-15.446-6.649-17.245-8.642-.922-1.022-.894-1.586-.795-1.786.101-.205.482-.44 1.21-.335.62.09 1.425.225 2.365.383.892.15 1.907.32 3.004.493.568.089.888-.364.977-.67.089-.308.06-.838-.423-1.092-4.914-2.581-8.973-6.924-11.281-10.404-1.163-1.754-1.839-3.226-1.991-4.108a1.479 1.479 0 0 1-.023-.463.331.331 0 0 1 .02-.077c.04-.009.183-.022.529.123 1.668.698 6.046 2.483 10.83 4.297.8.303 1.412-.584 1.048-1.236-5.798-10.377-6.429-19.051-5.427-24.46.505-2.725 1.412-4.564 2.227-5.403.416-.43.71-.5.852-.48zM38.026 28.22l-.001.002v-.002z"
                fill="#000"/>
            <path fillRule="evenodd" clipRule="evenodd"
                d="M63.885 45.055c.971-.008 2.057.083 3.021.215a.535.535 0 0 0 .145-1.061 22.975 22.975 0 0 0-3.175-.224c-1.009.008-2.014.115-2.678.45-.342.173-.67.445-.796.869-.125.425-.003.86.237 1.26.466.78 1.55 1.727 3.373 2.906a.535.535 0 0 0 .581-.898c-1.817-1.177-2.708-2.01-3.036-2.557-.157-.263-.137-.38-.128-.408.009-.03.05-.114.251-.216.424-.214 1.208-.327 2.205-.336zM57.401 29.075a.535.535 0 0 1 .734.184c4.57 7.617 15.483 17.053 27.545 21.177a.535.535 0 1 1-.346 1.013c-12.29-4.202-23.41-13.795-28.116-21.64a.535.535 0 0 1 .183-.734zM73.075 57.058c.89.39 1.923.734 2.861.992a.535.535 0 1 1-.285 1.032 22.973 22.973 0 0 1-3.006-1.043c-.924-.405-1.806-.9-2.285-1.468-.246-.294-.441-.674-.39-1.112.053-.44.335-.792.714-1.066.736-.534 2.106-.977 4.245-1.344a.535.535 0 0 1 .18 1.055c-2.133.366-3.28.78-3.797 1.156-.248.18-.275.295-.278.324-.004.031 0 .124.146.297.305.364.98.777 1.895 1.177z"
                fill="#000"/>
            <defs>
                <linearGradient id="rp0v627x3b" x1="178.013" y1="3.608" x2="157.914" y2="69.056"
                    gradientUnits="userSpaceOnUse">
                    <stop offset=".032" stopColor="#53B7CE"/>
                    <stop offset="1" stopColor="#E9FBFF" stopOpacity="0"/>
                </linearGradient>
                <linearGradient id="n21yml2hcc" x1="57.676" y1="3.846" x2="78.549" y2="71.816"
                    gradientUnits="userSpaceOnUse">
                    <stop offset=".032" stopColor="#53B7CE"/>
                    <stop offset="1" stopColor="#E9FBFF" stopOpacity="0"/>
                </linearGradient>
                <radialGradient id="yunqd6fk2a" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(-1.09057 -3.89102 19.26993 -5.40093 120.732 34.689)">
                    <stop offset=".247" stopColor="#F69833"/>
                    <stop offset=".452" stopColor="#FFDB56"/>
                    <stop offset=".488" stopColor="#fff" stopOpacity=".9"/>
                    <stop offset=".647" stopColor="#FFD848"/>
                    <stop offset=".852" stopColor="#FFB15D"/>
                </radialGradient>
            </defs>
        </svg>
    );
}
