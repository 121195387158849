import { useState } from "react";

import { H3 } from "../../../../library/Heading/Heading";
import { IconRemove } from "../../../../library/icons/IconRemove";
import { Tooltip } from "../../../../library/Tooltip/Tooltip";
import { AnalysisSubject } from "../../../../models/AnalysisSubject";
import { getBreakdownTypeLabel, BreakdownType } from "../../../../models/BreakdownType";
import { WidgetButton } from "../widgetButton/WidgetButton";
import { WidgetDropdown } from "../widgetDropdown/WidgetDropdown";

import classes from "./BreakdownWidget.module.css";

interface Props {
    analysisSubject?: AnalysisSubject;
    breakdownType?: BreakdownType;
    disabled?: boolean;
    onBreakdownChange(type: BreakdownType | undefined): void;
}

function getBreakdownOption(breakdownType: BreakdownType): { value: BreakdownType; label: string; } {
    return {
        value: breakdownType,
        label: getBreakdownTypeLabel(breakdownType),
    };
}

export function BreakdownWidget({
    analysisSubject,
    breakdownType,
    disabled = false,
    onBreakdownChange,
}: Props) {
    const [shouldSelectBreakdown, setShouldSelectBreakdown] = useState(false);

    function handleSelectClick() {
        setShouldSelectBreakdown(true);
    }

    function handleBreakdownCancel() {
        setShouldSelectBreakdown(false);
    }

    function handleBreakdownChange(newBreakdownType: BreakdownType) {
        setShouldSelectBreakdown(false);
        onBreakdownChange(newBreakdownType);
    }

    function handleRemoveClick() {
        setShouldSelectBreakdown(false);
        onBreakdownChange(undefined);
    }

    function renderBreakdownValue() {
        let breakdownOptions: BreakdownType[] = [];
        if (analysisSubject === AnalysisSubject.Components) {
            breakdownOptions = [BreakdownType.ProjectUsedIn];
        } else if (analysisSubject === AnalysisSubject.Projects) {
            breakdownOptions = [BreakdownType.ProjectDefined, BreakdownType.Tag];
        } else if (analysisSubject === AnalysisSubject.Tags) {
            breakdownOptions = [BreakdownType.ProjectDefined, BreakdownType.ProjectUsedIn];
        }
        const options = breakdownOptions.map(getBreakdownOption);

        if (breakdownType) {
            return (
                <WidgetDropdown
                    className={classes.breakdownDropdown}
                    options={options}
                    value={breakdownType}
                    disabled={disabled}
                    onChange={handleBreakdownChange}/>
            );
        }

        if (shouldSelectBreakdown) {
            return (
                <WidgetDropdown
                    className={classes.breakdownDropdown}
                    options={options}
                    disabled={disabled}
                    open
                    onChange={handleBreakdownChange}
                    onCancel={handleBreakdownCancel}/>
            );
        }

        return (
            <WidgetButton onClick={handleSelectClick} disabled={disabled || analysisSubject === undefined}>
                Select
            </WidgetButton>
        );
    }

    return (
        <div className={classes.breakdownWidget}>
            <H3 className={classes.title}>Breakdown by</H3>
            <div className={classes.breakdownValue}>
                <Tooltip content={disabled ? "Ask to join workspace to edit" : undefined} followCursor>
                    {renderBreakdownValue()}
                </Tooltip>
                {!disabled && breakdownType && (
                    <button
                        type="button"
                        className={classes.removeButton}
                        onClick={handleRemoveClick}>
                        <IconRemove/>
                    </button>
                )}
            </div>
        </div>
    );
}
