import { Feature } from "./Feature";
import { type SubscriptionPeriod } from "./SubscriptionPeriod";
import { type SubscriptionPlan } from "./SubscriptionPlan";
import { type SubscriptionStatus } from "./SubscriptionStatus";

export interface Subscription {
    status: SubscriptionStatus;
    plan: SubscriptionPlan;
    amount: number;
    monthlyAmount: number;
    trial?: {
        startedAt: Date;
        endedAt: Date;
    };
    endedAt?: Date;
    nextPeriodStartedAt?: Date;
    period: SubscriptionPeriod;
    features: {
        advancedProps: boolean;
        savedCharts: boolean;
        customProperties: boolean;
        baseNumOfComponents: number;
        additionalNumOfComponents: number;
        numOfComponents: number;
        numOfSeats: number;
        analysisLimit: number;
        dataRetentionLimit: number;
        tagLimit: number;
    };
}

export function isFeatureEnabled(subscription: Subscription, feature: Feature): boolean {
    return subscription.features[feature];
}

export function getFeatures(subscription: Subscription): Feature[] {
    return Object.values(Feature).filter(feature => isFeatureEnabled(subscription, feature));
}
