export const Keyboard = Object.freeze({
    Key: {},
    Code: {
        Enter: "Enter",
        Escape: "Escape",
        ArrowDown: "ArrowDown",
        ArrowLeft: "ArrowLeft",
        ArrowRight: "ArrowRight",
        ArrowUp: "ArrowUp",
        Space: "Space",
    },
});

export enum Browser {
    Edge = "edge",
    Opera = "opera",
    Firefox = "firefox",
    Chrome = "chrome",
    Safari = "safari",
    Unknown = "unknown",
}

export enum ReservedProjectName {
    None = "<none>",
}
