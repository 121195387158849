interface Props {
    className?: string;
}

export function LogoCookie({ className }: Props) {
    return (
        <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path d="M29.874 17.26a2 2 0 0 0-2.558-2.192 2.86 2.86 0 0 1-1.066.111c-1.561-.13-2.791-1.51-2.717-3.174a2 2 0 0 0-2.068-2.088 5.157 5.157 0 0 1-.606-.014c-2.756-.229-4.886-2.641-4.787-5.508a2 2 0 0 0-2.045-2.068c-2.351.054-4.784.552-6.874 2.285a13.961 13.961 0 0 0-4.995 9.663c-.322 4.023 2.048 7.64 3.924 9.984 2.136 2.67 4.57 4.69 8.632 5.026 3.33.276 6.482-.683 9.01-2.493 2.912-2.082 5.573-5.414 6.15-9.532z" fill="#FFC738" stroke="#262B2E" strokeWidth="4" strokeLinejoin="round"/>
            <path d="M28.883 17.122a1 1 0 0 0-1.279-1.096 3.86 3.86 0 0 1-1.436.15c-2.115-.176-3.731-2.033-3.634-4.215a1 1 0 0 0-1.034-1.045c-.238.009-.48.003-.723-.017-3.306-.274-5.821-3.156-5.705-6.537a1 1 0 0 0-1.022-1.035c-2.23.052-4.408.52-6.26 2.056a12.961 12.961 0 0 0-4.636 8.972c-.289 3.61 1.848 6.956 3.707 9.28 2.041 2.55 4.248 4.349 7.935 4.654a12.515 12.515 0 0 0 8.346-2.309c2.758-1.973 5.213-5.086 5.74-8.858z" fill="#FFC738" stroke="#383E41" strokeWidth="2" strokeLinejoin="round"/>
            <path d="M27.739 17.815c-.985 2.273-2.73 4.188-4.672 5.55a11.866 11.866 0 0 1-7.823 2.115c-3.374-.28-5.393-1.86-7.378-4.294-1.724-2.114-3.501-4.878-3.59-7.784-.056.339-.098.683-.126 1.031-.255 3.195 1.649 6.272 3.492 8.576 1.945 2.43 3.924 4.008 7.236 4.282 2.838.235 5.521-.58 7.682-2.126 2.39-1.71 4.48-4.289 5.179-7.35z" fill="#F69833"/>
            <path d="M9.619 10.317c-.949.82-1.632.88-2.206.19-.575-.688-.615-1.614.333-2.435.948-.82 2.183-.927 2.757-.238.575.688.064 1.662-.884 2.483zM17.812 21.718c.53.899.62 1.833-.728 2.656-.931.569-2.115.3-2.644-.599-.53-.899.053-2.245.728-2.656.674-.412 1.732-.172 2.644.599zM25.364 17.739c.529.9.62 1.834-.728 2.657-.931.568-2.115.3-2.645-.6-.529-.898.054-2.244.728-2.656.675-.412 2.02-.08 2.645.599zM11.694 17.24c.301 1.229.196 2.314-1.203 2.67-.966.246-1.993-.552-2.295-1.78-.302-1.23.237-2.426 1.203-2.672.7-.178 1.617.535 2.295 1.781z" fill="#F69833"/>
            <path d="M23.135 1.232c.993-.532 2.42-.996 3.291.687.6 1.162.283 2.535-.709 3.067s-2.284.022-2.884-1.14c-.436-.842-.567-2.161.302-2.614zM29.485 9.424c-.505.599-1.133.893-2.01.127-.604-.528-.684-1.442-.179-2.042.505-.599 1.405-.656 2.01-.127.438.383.51 1.2.18 2.042z" fill="#262B2E"/>
            <path d="M16.379 9.964c-.88.132-2.002.973-2.091 2.087-.101 1.263.823 2.37 2.063 2.472 1.797.15 2.328-.837 2.428-2.1a2.084 2.084 0 0 0-.18-1.028 7.173 7.173 0 0 1-2.22-1.431z" fill="#F69833"/>
        </svg>
    );
}
