import { PropValueType } from "./PropValueType";

export type PropValue = {
    type: PropValueType.String | PropValueType.Identifier;
    value: string;
} | {
    type: PropValueType.Number;
    value: number;
} | {
    type: PropValueType.Bool;
    value: boolean;
} | {
    type: PropValueType.Regex;
    value: string;
    flags: string;
} | {
    type: PropValueType.Array;
    values: PropValue[];
} | {
    type: PropValueType.Spread;
    value: PropValue;
} | {
    type: PropValueType.Member;
    value: PropValue;
    property: PropValue;
} | {
    type: PropValueType.Null | PropValueType.JSXElement | PropValueType.Function | PropValueType.Object | PropValueType.This | PropValueType.Super | PropValueType.TemplateLiteral | PropValueType.Expression;
};

export function propValueToString(propValue?: PropValue): string | undefined {
    if (!propValue) {
        return undefined;
    }

    function memberToString(value: PropValue, property: PropValue): string {
        const prefix = (
            [PropValueType.Identifier, PropValueType.This, PropValueType.Super].includes(value.type)
                ? propValueToString(value)
                : `(${propValueToString(value)})`
        );
        return property.type === PropValueType.String ? `${prefix}.${property.value}` : `${prefix}[${propValueToString(property)}]`;
    }

    switch (propValue.type) {
        case PropValueType.Null:
            return "null";
        case PropValueType.String:
            return `"${propValue.value}"`;
        case PropValueType.Number:
        case PropValueType.Bool:
            return propValue.value.toString();
        case PropValueType.Regex:
            return `/${propValue.value}/${propValue.flags}`;
        case PropValueType.Identifier:
            return propValue.value;
        case PropValueType.JSXElement:
            return "ReactNode";
        case PropValueType.Function:
            return "Function";
        case PropValueType.Object:
            return "Object";
        case PropValueType.Array:
            return "Array";
        case PropValueType.Spread:
            return `...${propValueToString(propValue.value)}`;
        case PropValueType.Member:
            return memberToString(propValue.value, propValue.property);
        case PropValueType.This:
            return "this";
        case PropValueType.Super:
            return "super";
        case PropValueType.TemplateLiteral:
            return "TemplateLiteral";
        case PropValueType.Expression:
            return "Expression";
    }
}
