export enum Types {
    SET_USER = "setUser",
    SET_WORKSPACE = "setWorkspace",
    SET_TAGS = "setTags",
    SET_TAG = "setTag",
    DELETE_TAG = "deleteTag",
    SET_IS_CREATE_NEW_ANALYSIS_BUTTON_VISIBLE = "setIsCreateNewAnalysisButtonVisible",
    SET_IS_SETUP_REGULAR_SCANS_DIALOG_VISIBLE = "setIsSetupRegularScansDialogVisible",
    SET_IS_SCAN_MORE_PROJECTS_DIALOG_VISIBLE = "setIsScanMoreProjectsDialogVisible",
    SET_IS_ADD_MORE_TAGS_DIALOG_VISIBLE = "setIsAddMoreTagsDialogVisible",
    SET_IS_SUBSCRIPTION_INFO_DIALOG_VISIBLE = "setIsSubscriptionInfoDialogVisible",
    OPEN_BILLING_DIALOG = "openBillingDialog",
    CLOSE_BILLING_DIALOG = "closeBillingDialog",
    OPEN_PRICING_TABLE_DIALOG = "openPricingTableDialog",
    CLOSE_PRICING_TABLE_DIALOG = "closePricingTableDialog",
    SET_IS_SAVED_CHARTS_INFO_DIALOG_VISIBLE = "setIsSavedChartsInfoDialogVisible",
    OPEN_PAYMENT_SUCCESS_DIALOG = "openPaymentSuccessDialog",
    CLOSE_PAYMENT_SUCCESS_DIALOG = "closePaymentSuccessDialog",
    SET_ANALYTICS_URL = "setAnalyticsURL",
    SET_COMPONENTS_URL = "setComponentsURL",
    SET_DASHBOARD_URL = "setDashboardURL",
    SET_COMPONENTS_SCROLL_POSITION = "setComponentsScrollPosition",
    SET_DASHBOARD_SCROLL_POSITION = "setDashboardScrollPosition",
    SET_MEMBERS = "setMembers",
    ADD_MEMBER = "addMember",
    REMOVE_MEMBER = "removeMember",
}
