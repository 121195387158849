import { type Feature } from "./Feature";
import { type Project } from "./Project";
import * as Subscription from "./Subscription";
import { type Tag } from "./Tag";

export interface Workspace {
    id: string;
    name: string;
    slug: string;
    createdBy: string;
    projects: Project[];
    tags: Tag[];
    subscription: Subscription.Subscription;
    numOfMembers: number;
    numOfComponents: number;
    numOfRecentAnalyses: number;
    nextAvailableAnalysisDate: Date;
    hasMemberLimitReached: boolean;
    dataRetentionLimitDate?: Date;
    hasReachedDataRetentionLimit: boolean;
    hasReachedTagLimit: boolean;
    componentLimitGracePeriodEndAt?: Date;
}

export function isFeatureEnabled(workspace: Workspace | null, feature: Feature) {
    // when there is no subscription, we consider all features as enabled
    return workspace ? Subscription.isFeatureEnabled(workspace.subscription, feature) : true;
}

