interface Props {
    className?: string;
}
export function Illustration({ className }: Props) {
    return (
        <svg width="160" height="213" viewBox="0 0 160 213" fill="none" className={className}>
            <path d="M160 158.096L80.8465 204.234L0 158.096L80.8465 111.959L160 158.096Z" fill="#2CC653"/>
            <path d="M160 158.096L80.8465 204.234L0 158.096L80.8465 111.959L160 158.096Z" fill="white" fillOpacity="0.4"/>
            <path d="M80.8465 204.233L0 158.096V274.921H80.8465V204.233Z" fill="#2CC653"/>
            <path d="M159.999 158.096L80.8457 204.233V274.498H159.999V158.096Z" fill="#2CC653"/>
            <path d="M159.999 158.096L80.8457 204.233V274.498H159.999V158.096Z" fill="black" fillOpacity="0.2"/>
            <path d="M143.068 157.461L81.4806 194.71L16.7188 157.461L81.4806 120.848L143.068 157.461Z" fill="#2CC653"/>
            <path d="M80.8471 186.244L32.1699 157.461L80.8471 130.371L129.101 157.461L80.8471 186.244Z" fill="#80DD98"/>
            <path d="M79.7876 139.684L48.4648 157.461L79.7876 176.509L111.11 157.461L79.7876 139.684Z" fill="#2CC653"/>
            <g opacity="0.7" filter="url(#filter0_f_8316_10579)">
                <ellipse cx="79.5757" cy="160.002" rx="41.0581" ry="12.2751" fill="black"/>
            </g>
            <ellipse cx="80" cy="79.9999" rx="80" ry="79.9999" fill="#34393C"/>
            <ellipse cx="80" cy="80" rx="53" ry="53" fill="#262B2E"/>
            <ellipse opacity="0.2" cx="80" cy="79.9991" rx="44.1667" ry="44.1667" transform="rotate(10 80 79.9991)" fill="#6B7276"/>
            <path d="M87.6698 36.5046C82.8654 35.6574 78.284 38.8654 77.4368 43.6698L65.1657 113.263C64.3185 118.067 67.5265 122.649 72.3309 123.496C96.353 127.732 119.26 111.692 123.496 87.6697C127.732 63.6477 111.692 40.7403 87.6698 36.5046Z" fill="#F69833"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M68.539 121.849C88.9342 121.135 106.76 106.276 110.446 85.3715C114.132 64.4659 102.462 44.4045 83.5391 36.7593C80.4719 37.7044 78.0304 40.2931 77.4347 43.671L65.1636 113.264C64.5677 116.643 65.978 119.912 68.539 121.849Z" fill="#FFC738"/>
            <g opacity="0.2" filter="url(#filter1_f_8316_10579)">
                <path fillRule="evenodd" clipRule="evenodd" d="M56.8405 19.7649L52.0431 16.9867L57.42 16.4538L60.4096 11.481L61.0856 17.3037L66.1217 20.1032L60.2246 20.474L57.2931 25.5596L56.8405 19.7649Z" fill="white"/>
            </g>
            <g filter="url(#filter2_f_8316_10579)">
                <path fillRule="evenodd" clipRule="evenodd" d="M66.4854 6.10563C70.8939 5.30111 75.4366 4.88086 80.0774 4.88086C121.622 4.88086 155.301 38.5597 155.301 80.1047C155.301 102.651 145.382 122.881 129.669 136.668C128.076 136.769 126.47 136.82 124.853 136.82C82.3186 136.82 47.8379 101.404 47.8379 57.7157C47.8379 37.9945 54.864 19.9589 66.4854 6.10563Z" fill="white" fillOpacity="0.1"/>
            </g>
            <g opacity="0.5" filter="url(#filter3_f_8316_10579)">
                <path d="M148.992 82.0853C148.992 46.5168 123.601 16.8788 89.9553 10.3086C89.4608 10.212 89.2703 10.9083 89.7346 11.1039C118.938 23.4024 139.44 52.2899 139.44 85.966V86.0533C139.44 89.899 139.44 91.6179 139.03 94.4406C138.859 95.6154 140.007 96.5648 141.114 96.1352C145.394 94.4735 148.713 90.7782 148.917 86.191C148.971 84.9761 148.992 83.6418 148.992 82.0853Z" fill="white"/>
            </g>
            <g opacity="0.2" filter="url(#filter4_f_8316_10579)">
                <path d="M114.655 109.673C114.572 111.782 115.776 113.865 117.815 113.319C122.09 112.173 128.08 107.016 130.99 102.69C131.339 102.172 131.567 101.583 131.695 100.972C139.266 65.0177 118.718 28.9507 84.1174 16.8877C83.2142 16.5728 82.6477 17.7673 83.4215 18.3295C107.806 36.0463 116.622 59.6764 114.655 109.673Z" fill="white"/>
            </g>
            <g opacity="0.6" filter="url(#filter5_f_8316_10579)">
                <ellipse cx="82.5696" cy="9.97001" rx="1.5579" ry="1.5579" fill="white"/>
            </g>
            <g filter="url(#filter6_f_8316_10579)">
                <path fillRule="evenodd" clipRule="evenodd" d="M29.6405 49.278L25.5488 48.4654L29.5639 47.6414L30.3627 43.6191L31.204 47.7184L35.2078 48.4654L31.1231 49.2029L30.3627 53.2781L29.6405 49.278Z" fill="white"/>
            </g>
            <g filter="url(#filter7_f_8316_10579)">
                <path fillRule="evenodd" clipRule="evenodd" d="M12.8067 89.7045L7.16992 89.2786L12.8105 88.8381L13.2371 83.1934L13.6775 88.8337L19.9447 89.2786L13.6814 89.7089L13.2371 95.9682L12.8067 89.7045Z" fill="white"/>
            </g>
            <g opacity="0.6" filter="url(#filter8_f_8316_10579)">
                <ellipse cx="140.674" cy="106.672" rx="3.8806" ry="3.88059" fill="white"/>
            </g>
            <defs>
                <filter id="filter0_f_8316_10579" x="24.2376" y="133.447" width="110.677" height="53.1108" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="7.14" result="effect1_foregroundBlur_8316_10579"/>
                </filter>
                <filter id="filter1_f_8316_10579" x="50.363" y="9.80047" width="17.4381" height="17.4401" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="0.84" result="effect1_foregroundBlur_8316_10579"/>
                </filter>
                <filter id="filter2_f_8316_10579" x="46.9979" y="4.04086" width="109.143" height="133.619" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="0.42" result="effect1_foregroundBlur_8316_10579"/>
                </filter>
                <filter id="filter3_f_8316_10579" x="88.6288" y="9.45883" width="61.2034" height="87.6214" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="0.42" result="effect1_foregroundBlur_8316_10579"/>
                </filter>
                <filter id="filter4_f_8316_10579" x="82.2166" y="15.9959" width="51.8831" height="98.2503" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="0.42" result="effect1_foregroundBlur_8316_10579"/>
                </filter>
                <filter id="filter5_f_8316_10579" x="79.3317" y="6.73211" width="6.47523" height="6.47523" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="0.84" result="effect1_foregroundBlur_8316_10579"/>
                </filter>
                <filter id="filter6_f_8316_10579" x="23.8688" y="41.9391" width="13.0182" height="13.0182" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="0.84" result="effect1_foregroundBlur_8316_10579"/>
                </filter>
                <filter id="filter7_f_8316_10579" x="5.48992" y="81.5134" width="16.1354" height="16.1354" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="0.84" result="effect1_foregroundBlur_8316_10579"/>
                </filter>
                <filter id="filter8_f_8316_10579" x="135.113" y="101.111" width="11.1217" height="11.1217" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="0.84" result="effect1_foregroundBlur_8316_10579"/>
                </filter>
            </defs>
        </svg>
    );
}
