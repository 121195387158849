import { useRef } from "react";

import {
    HeaderHighlightButton,
    HighlightButtonKind,
} from "../../../library/HeaderButton/HeaderButton";
import { type Subscription } from "../../../models/Subscription";
import { SubscriptionPlan, subscriptionPlanToString } from "../../../models/SubscriptionPlan";
import { useStore } from "../../../providers/StoreProvider/StoreProvider";
import { getDaysBetween, pluralize } from "../../../utils";

import { SubscriptionInfoDialog } from "./subscriptionInfoDialog/SubscriptionInfoDialog";


interface Props {
    subscription: Subscription;
    numOfRecentAnalyses: number;
    nextAvailableAnalysisDate: Date;
}

export function SubscriptionInfoButton({
    subscription,
    numOfRecentAnalyses,
    nextAvailableAnalysisDate,
}: Props) {
    const buttonRef = useRef<HTMLButtonElement>(null);

    const {
        actions: { setIsSubscriptionInfoDialogVisible },
        selectors: { getIsSubscriptionInfoDialogVisible },
    } = useStore();

    const isSubscriptionInfoDialogVisible = getIsSubscriptionInfoDialogVisible();

    function handleButtonClick() {
        setIsSubscriptionInfoDialogVisible(true);
    }

    function handleDialogClose() {
        setIsSubscriptionInfoDialogVisible(false);
    }

    function getDetails() {
        const analysisLimit = subscription.features.analysisLimit;
        const analysisLeft = analysisLimit - numOfRecentAnalyses;

        if (subscription.plan === SubscriptionPlan.TrialEnded) {
            return {
                label: "Trial Ended",
                buttonKind: HighlightButtonKind.Critical,
            };
        }

        if (!subscription.trial || subscription.plan === SubscriptionPlan.Free) {
            if (analysisLeft > 1) {
                return {
                    label: `${subscriptionPlanToString(subscription.plan)} plan`,
                    buttonKind: HighlightButtonKind.Default,
                };
            }

            if (analysisLeft === 1) {
                return {
                    label: `${subscriptionPlanToString(subscription.plan)} plan: 1 of ${pluralize("scan", analysisLimit)} left`,
                    buttonKind: HighlightButtonKind.Critical,
                };
            }

            return {
                label: `${subscriptionPlanToString(subscription.plan)} plan: No scans left`,
                buttonKind: HighlightButtonKind.Critical,
            };
        }

        const daysLeft = Math.max(1, getDaysBetween(new Date(), subscription.trial.endedAt));

        if (daysLeft <= 7) {
            return {
                label: `Trial: ${pluralize("day", daysLeft)} left`,
                buttonKind: HighlightButtonKind.Critical,
            };
        }

        return {
            label: "Trial",
            buttonKind: HighlightButtonKind.Default,
        };
    }

    const { label, buttonKind } = getDetails();

    return (
        <>
            <HeaderHighlightButton
                ref={buttonRef}
                active={isSubscriptionInfoDialogVisible}
                kind={buttonKind}
                label={label}
                onClick={handleButtonClick}/>
            {isSubscriptionInfoDialogVisible && (
                <SubscriptionInfoDialog
                    subscription={subscription}
                    numOfRecentAnalyses={numOfRecentAnalyses}
                    nextAvailableAnalysisDate={nextAvailableAnalysisDate}
                    buttonRect={buttonRef.current!.getBoundingClientRect()}
                    onClose={handleDialogClose}/>
            )}
        </>
    );
}
