import { SpanType, TruncateFromMiddle } from "../../../common/truncate/TruncateFromMiddle";
import { getNonCoreTagName } from "../../../models/Tag";

interface Props {
    designSystemName: string;
}

export function ImgDesignSystemUsageExample({ designSystemName }: Props) {
    return (
        <svg width="419" height="201" viewBox="0 0 419 201" fill="none">
            <rect width="11" height="11.036" rx="2" transform="rotate(-4.013 322.07 -1905.874)" fill="#8574ED"/>
            <text x="147.5" y="36.5" transform="rotate(-4.013)" fill="#6b7276" fontSize="11" fontWeight="500">
                <TruncateFromMiddle spanType={SpanType.SVG} text={designSystemName} width={100}/>
            </text>
            <path d="M271.829 35.596c.478-4.18 3.823-12.641 13.382-13.043" stroke="#BEC5C9" strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M267.524 30.602c1.053.697 2.707 5.756 2.707 8.896 0 3.14 9.024-6.803 7.67-6.803-4.331-.419-6.918-1.92-8.121-2.617" stroke="#BEC5C9" strokeWidth="1.5" strokeLinecap="round"/>
            <rect width="11" height="11.036" rx="2" transform="rotate(-6.02 298.3 -2759.18)" fill="#FF7F7F"/>
            <text x="304.5" y="55.6" transform="rotate(-6.02)" fill="#6b7276" fontSize="11" fontWeight="500">
                <TruncateFromMiddle spanType={SpanType.SVG} text={getNonCoreTagName(designSystemName)} width={100}/>
            </text>
            <path d="M114.96 36.098c1.275-4.013 5.736-12.04 13.383-12.04" stroke="#BEC5C9" strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M112.094 31.583c.408 1.569 1.163 4.912 1.377 7.356.053.614.613 1.087 1.203.913 3.308-.972 4.999-3.556 5.688-5.49.222-.625-.318-1.203-.98-1.192-1.596.029-3.74-.174-4.898-1.085" stroke="#BEC5C9" strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M42.682 185.56c-.005.004-.01.01-.015.029-.297 1.311-.058 2.685.477 3.84.543 1.173 1.337 2.011 2.027 2.337.382.18.542.629.357 1.001a.778.778 0 0 1-1.027.349c-1.119-.528-2.124-1.699-2.759-3.07-.643-1.389-.959-3.095-.576-4.781l.75.162-.75-.162c.148-.652.65-1.039 1.172-1.187a2.173 2.173 0 0 1 1.555.156c1.476.749 3.718 1.323 5.472 1.772.272.069.532.136.776.2.555.145 1.106.555 1.114 1.24.008.615-.438 1.069-.903 1.315-.752.398-1.496.905-2.067 1.48a15.3 15.3 0 0 0 2.713 2.007c2.474 1.43 5.942 2.457 11.196 2.212.425-.019.785.3.805.714a.758.758 0 0 1-.731.785c-5.51.256-9.283-.819-12.054-2.42a16.792 16.792 0 0 1-2.836-2.073.782.782 0 0 1-.895.156.741.741 0 0 1-.353-1.003c.04-.081.083-.16.129-.238a42.538 42.538 0 0 1-1.623-1.809l-.257-.297a.738.738 0 0 1 .092-1.057.782.782 0 0 1 1.083.089l.239.276a47.68 47.68 0 0 0 1.413 1.589c.66-.659 1.452-1.198 2.188-1.608l-.39-.099c-1.73-.442-4.174-1.065-5.82-1.9a.608.608 0 0 0-.416-.047.214.214 0 0 0-.086.042z" fill="#BEC5C9"/>
            <text x="59.5" y="200" transform="rotate(-2.007 0 0)" fill="#6b7276" fontSize="11" fontWeight="500">packages</text>
            <text x="0.1" y="59.5" fill="#262b2e" fontSize="8">snack-consumer-app</text>
            <path d="M410 66.009a2 2 0 0 0 2-2v-14a2 2 0 0 0-2-2H90a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h320z" fill="#FF7F7F"/>
            <path d="M393.138 60.009h1.096l2.08-5.592v-.128h-4v.864h2.664l-1.84 4.856zm5.737.072c1.472 0 2.304-1.048 2.304-2.928v-.296c0-1.648-.736-2.656-2.232-2.656-1.304 0-2.12.792-2.12 1.96 0 1.128.792 1.856 1.896 1.856.68 0 1.144-.288 1.408-.72-.016 1.304-.456 1.96-1.272 1.96-.616 0-.88-.288-.984-.736h-1c.136.984.832 1.56 2 1.56zm.072-2.808c-.656 0-1.072-.456-1.072-1.144 0-.68.424-1.136 1.064-1.136.656 0 1.112.448 1.112 1.216 0 .632-.456 1.064-1.104 1.064zm4.279-.264c.784 0 1.392-.592 1.392-1.4 0-.8-.616-1.376-1.384-1.376-.768 0-1.4.592-1.4 1.392 0 .792.608 1.384 1.392 1.384zm-.608 3h.68l3.68-5.72h-.672l-3.688 5.72zm.616-3.648c-.376 0-.616-.256-.616-.744 0-.48.24-.744.616-.744s.608.28.608.744c0 .472-.232.744-.608.744zm3.128 3.728c.784 0 1.4-.592 1.4-1.4 0-.8-.624-1.376-1.392-1.376-.768 0-1.392.592-1.392 1.392 0 .792.6 1.384 1.384 1.384zm.008-.648c-.376 0-.616-.256-.616-.744 0-.48.24-.744.616-.744s.608.28.608.744c0 .472-.232.744-.608.744z" fill="#fff"/>
            <path d="M156 66.009v-18H90a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h66z" fill="#8574ED"/>
            <path d="M136.194 60.009h4.136v-.824h-2.432l1.272-1.24c.68-.656 1.12-1.304 1.12-2.048 0-1.008-.704-1.688-1.888-1.688-1.208 0-1.96.72-2.032 1.968h.976c.096-.808.456-1.136 1.024-1.136.552 0 .856.336.856.872 0 .544-.328 1.048-.872 1.624l-2.16 2.28v.192zm7.454 0h1.032v-1.16h.768v-.792h-.768v-3.768h-1.336l-2.528 4.184v.376h2.832v1.16zm-1.696-1.952 1.712-2.96v2.96h-1.712zm5.274-1.048c.784 0 1.392-.592 1.392-1.4 0-.8-.616-1.376-1.384-1.376-.768 0-1.4.592-1.4 1.392 0 .792.608 1.384 1.392 1.384zm-.608 3h.68l3.68-5.72h-.672l-3.688 5.72zm.616-3.648c-.376 0-.616-.256-.616-.744 0-.48.24-.744.616-.744s.608.28.608.744c0 .472-.232.744-.608.744zm3.128 3.728c.784 0 1.4-.592 1.4-1.4 0-.8-.624-1.376-1.392-1.376-.768 0-1.392.592-1.392 1.392 0 .792.6 1.384 1.384 1.384zm.008-.648c-.376 0-.616-.256-.616-.744 0-.48.24-.744.616-.744s.608.28.608.744c0 .472-.232.744-.608.744z" fill="#fff"/>
            <text y="87.5" fill="#262b2e" fontSize="8">snack-design-system</text>
            <path d="M410 94.009a2 2 0 0 0 2-2v-14a2 2 0 0 0-2-2H90a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h320z" fill="#FF7F7F"/>
            <path d="M394.066 88.089c1.296 0 2.112-.784 2.112-1.944 0-1.112-.784-1.864-1.88-1.864-.68 0-1.152.296-1.416.736.032-1.304.536-1.984 1.304-1.984.552 0 .776.256.88.688h.992c-.136-1-.824-1.512-1.864-1.512-1.368 0-2.368 1.08-2.368 3.064v.136c0 1.512.576 2.68 2.24 2.68zm-.016-.784c-.648 0-1.104-.44-1.104-1.2 0-.632.456-1.088 1.104-1.088.656 0 1.072.464 1.072 1.152 0 .68-.432 1.136-1.072 1.136zm5.598.704h1.032v-1.16h.768v-.792h-.768v-3.768h-1.336l-2.528 4.184v.376h2.832v1.16zm-1.696-1.952 1.712-2.96v2.96h-1.712zm5.274-1.048c.784 0 1.392-.592 1.392-1.4 0-.8-.616-1.376-1.384-1.376-.768 0-1.4.592-1.4 1.392 0 .792.608 1.384 1.392 1.384zm-.608 3h.68l3.68-5.72h-.672l-3.688 5.72zm.616-3.648c-.376 0-.616-.256-.616-.744 0-.48.24-.744.616-.744s.608.28.608.744c0 .472-.232.744-.608.744zm3.128 3.728c.784 0 1.4-.592 1.4-1.4 0-.8-.624-1.376-1.392-1.376-.768 0-1.392.592-1.392 1.392 0 .792.6 1.384 1.384 1.384zm.008-.648c-.376 0-.616-.256-.616-.744 0-.48.24-.744.616-.744s.608.28.608.744c0 .472-.232.744-.608.744z" fill="#fff"/>
            <path d="M205 94.009v-18H90a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h115z" fill="#8574ED"/>
            <path d="M186.795 88.089c1.312 0 2.144-.656 2.144-1.712 0-.76-.44-1.232-1.104-1.384.552-.208.928-.6.928-1.248 0-.848-.696-1.536-1.888-1.536-1.224 0-1.968.76-2.024 1.752h.944c.064-.576.456-.944 1.064-.944.592 0 .888.344.888.808 0 .536-.296.84-.952.84h-.408v.76h.44c.696 0 1.032.312 1.032.936 0 .568-.344.928-1.088.928-.768 0-1.104-.448-1.16-1.048h-.968c.048 1.096.792 1.848 2.152 1.848zm5.273 0c1.296 0 2.112-.784 2.112-1.944 0-1.112-.784-1.864-1.88-1.864-.68 0-1.152.296-1.416.736.032-1.304.536-1.984 1.304-1.984.552 0 .776.256.88.688h.992c-.136-1-.824-1.512-1.864-1.512-1.368 0-2.368 1.08-2.368 3.064v.136c0 1.512.576 2.68 2.24 2.68zm-.016-.784c-.648 0-1.104-.44-1.104-1.2 0-.632.456-1.088 1.104-1.088.656 0 1.072.464 1.072 1.152 0 .68-.432 1.136-1.072 1.136zm4.174-2.296c.784 0 1.392-.592 1.392-1.4 0-.8-.616-1.376-1.384-1.376-.768 0-1.4.592-1.4 1.392 0 .792.608 1.384 1.392 1.384zm-.608 3h.68l3.68-5.72h-.672l-3.688 5.72zm.616-3.648c-.376 0-.616-.256-.616-.744 0-.48.24-.744.616-.744s.608.28.608.744c0 .472-.232.744-.608.744zm3.128 3.728c.784 0 1.4-.592 1.4-1.4 0-.8-.624-1.376-1.392-1.376-.768 0-1.392.592-1.392 1.392 0 .792.6 1.384 1.384 1.384zm.008-.648c-.376 0-.616-.256-.616-.744 0-.48.24-.744.616-.744s.608.28.608.744c0 .472-.232.744-.608.744z" fill="#fff"/>
            <text x="16.1" y="115.5" fill="#262b2e" fontSize="8">snack-driver-app</text>
            <path d="M410 122.009a2 2 0 0 0 2-2v-14a2 2 0 0 0-2-2H90a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h320z" fill="#FF7F7F"/>
            <path d="M395.629 116.089c1.296 0 2.112-.784 2.112-1.944 0-1.112-.784-1.864-1.88-1.864-.68 0-1.152.296-1.416.736.032-1.304.536-1.984 1.304-1.984.552 0 .776.256.88.688h.992c-.136-1-.824-1.512-1.864-1.512-1.368 0-2.368 1.08-2.368 3.064v.136c0 1.512.576 2.68 2.24 2.68zm-.016-.784c-.648 0-1.104-.44-1.104-1.2 0-.632.456-1.088 1.104-1.088.656 0 1.072.464 1.072 1.152 0 .68-.432 1.136-1.072 1.136zm4.013.704h1.072v-5.72h-.952l-1.328.944v.936l1.208-.824v4.664zm3.6-3c.784 0 1.392-.592 1.392-1.4 0-.8-.616-1.376-1.384-1.376-.768 0-1.4.592-1.4 1.392 0 .792.608 1.384 1.392 1.384zm-.608 3h.68l3.68-5.72h-.672l-3.688 5.72zm.616-3.648c-.376 0-.616-.256-.616-.744 0-.48.24-.744.616-.744s.608.28.608.744c0 .472-.232.744-.608.744zm3.128 3.728c.784 0 1.4-.592 1.4-1.4 0-.8-.624-1.376-1.392-1.376-.768 0-1.392.592-1.392 1.392 0 .792.6 1.384 1.384 1.384zm.008-.648c-.376 0-.616-.256-.616-.744 0-.48.24-.744.616-.744s.608.28.608.744c0 .472-.232.744-.608.744z" fill="#fff"/>
            <path d="M208 122.009v-18H90a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h118z" fill="#8574ED"/>
            <path d="M189.827 116.089c1.312 0 2.144-.656 2.144-1.712 0-.76-.44-1.232-1.104-1.384.552-.208.928-.6.928-1.248 0-.848-.696-1.536-1.888-1.536-1.224 0-1.968.76-2.024 1.752h.944c.064-.576.456-.944 1.064-.944.592 0 .888.344.888.808 0 .536-.296.84-.952.84h-.408v.76h.44c.696 0 1.032.312 1.032.936 0 .568-.344.928-1.088.928-.768 0-1.104-.448-1.16-1.048h-.968c.048 1.096.792 1.848 2.152 1.848zm5.048-.008c1.472 0 2.304-1.048 2.304-2.928v-.296c0-1.648-.736-2.656-2.232-2.656-1.304 0-2.12.792-2.12 1.96 0 1.128.792 1.856 1.896 1.856.68 0 1.144-.288 1.408-.72-.016 1.304-.456 1.96-1.272 1.96-.616 0-.88-.288-.984-.736h-1c.136.984.832 1.56 2 1.56zm.072-2.808c-.656 0-1.072-.456-1.072-1.144 0-.68.424-1.136 1.064-1.136.656 0 1.112.448 1.112 1.216 0 .632-.456 1.064-1.104 1.064zm4.279-.264c.784 0 1.392-.592 1.392-1.4 0-.8-.616-1.376-1.384-1.376-.768 0-1.4.592-1.4 1.392 0 .792.608 1.384 1.392 1.384zm-.608 3h.68l3.68-5.72h-.672l-3.688 5.72zm.616-3.648c-.376 0-.616-.256-.616-.744 0-.48.24-.744.616-.744s.608.28.608.744c0 .472-.232.744-.608.744zm3.128 3.728c.784 0 1.4-.592 1.4-1.4 0-.8-.624-1.376-1.392-1.376-.768 0-1.392.592-1.392 1.392 0 .792.6 1.384 1.384 1.384zm.008-.648c-.376 0-.616-.256-.616-.744 0-.48.24-.744.616-.744s.608.28.608.744c0 .472-.232.744-.608.744z" fill="#fff"/>
            <text x="1.6" y="143.5" fill="#262b2e" fontSize="8">snack-merchant-app</text>
            <path d="M410 150.009a2 2 0 0 0 2-2v-14a2 2 0 0 0-2-2H90a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h320z" fill="#FF7F7F"/>
            <path d="M394.49 144.009h1.032v-1.16h.768v-.792h-.768v-3.768h-1.336l-2.528 4.184v.376h2.832v1.16zm-1.696-1.952 1.712-2.96v2.96h-1.712zm6.253 2.032c1.312 0 2.144-.656 2.144-1.712 0-.76-.44-1.232-1.104-1.384.552-.208.928-.6.928-1.248 0-.848-.696-1.536-1.888-1.536-1.224 0-1.968.76-2.024 1.752h.944c.064-.576.456-.944 1.064-.944.592 0 .888.344.888.808 0 .536-.296.84-.952.84h-.408v.76h.44c.696 0 1.032.312 1.032.936 0 .568-.344.928-1.088.928-.768 0-1.104-.448-1.16-1.048h-.968c.048 1.096.792 1.848 2.152 1.848zm4.179-3.08c.784 0 1.392-.592 1.392-1.4 0-.8-.616-1.376-1.384-1.376-.768 0-1.4.592-1.4 1.392 0 .792.608 1.384 1.392 1.384zm-.608 3h.68l3.68-5.72h-.672l-3.688 5.72zm.616-3.648c-.376 0-.616-.256-.616-.744 0-.48.24-.744.616-.744s.608.28.608.744c0 .472-.232.744-.608.744zm3.128 3.728c.784 0 1.4-.592 1.4-1.4 0-.8-.624-1.376-1.392-1.376-.768 0-1.392.592-1.392 1.392 0 .792.6 1.384 1.384 1.384zm.008-.648c-.376 0-.616-.256-.616-.744 0-.48.24-.744.616-.744s.608.28.608.744c0 .472-.232.744-.608.744z" fill="#fff"/>
            <path d="M254 150.009v-18H90a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h164z" fill="#8574ED"/>
            <path d="M236.856 144.089c1.232 0 2.104-.76 2.104-1.968 0-1.2-.728-1.92-1.76-1.92-.608 0-1.008.224-1.232.48l.008-1.552h2.656v-.84h-3.472l-.048 3.184h.544c.256-.296.608-.496 1.192-.496.632 0 1.032.44 1.032 1.128 0 .704-.376 1.16-1.032 1.16-.736 0-.952-.368-1.04-.896h-.992c.064.984.696 1.72 2.04 1.72zm3.378-.08h1.096l2.08-5.592v-.128h-4v.864h2.664l-1.84 4.856zm4.992-3c.784 0 1.392-.592 1.392-1.4 0-.8-.616-1.376-1.384-1.376-.768 0-1.4.592-1.4 1.392 0 .792.608 1.384 1.392 1.384zm-.608 3h.68l3.68-5.72h-.672l-3.688 5.72zm.616-3.648c-.376 0-.616-.256-.616-.744 0-.48.24-.744.616-.744s.608.28.608.744c0 .472-.232.744-.608.744zm3.128 3.728c.784 0 1.4-.592 1.4-1.4 0-.8-.624-1.376-1.392-1.376-.768 0-1.392.592-1.392 1.392 0 .792.6 1.384 1.384 1.384zm.008-.648c-.376 0-.616-.256-.616-.744 0-.48.24-.744.616-.744s.608.28.608.744c0 .472-.232.744-.608.744z" fill="#fff"/>
            <text x="26.1" y="171.5" fill="#262b2e" fontSize="8">snack-website</text>
            <path d="M410 178.009a2 2 0 0 0 2-2v-14a2 2 0 0 0-2-2H90a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h320z" fill="#FF7F7F"/>
            <path d="M395.21 172.009h1.072v-5.72h-.952l-1.328.944v.936l1.208-.824v4.664zm3.024 0h1.096l2.08-5.592v-.128h-4v.864h2.664l-1.84 4.856zm4.992-3c.784 0 1.392-.592 1.392-1.4 0-.8-.616-1.376-1.384-1.376-.768 0-1.4.592-1.4 1.392 0 .792.608 1.384 1.392 1.384zm-.608 3h.68l3.68-5.72h-.672l-3.688 5.72zm.616-3.648c-.376 0-.616-.256-.616-.744 0-.48.24-.744.616-.744s.608.28.608.744c0 .472-.232.744-.608.744zm3.128 3.728c.784 0 1.4-.592 1.4-1.4 0-.8-.624-1.376-1.392-1.376-.768 0-1.392.592-1.392 1.392 0 .792.6 1.384 1.384 1.384zm.008-.648c-.376 0-.616-.256-.616-.744 0-.48.24-.744.616-.744s.608.28.608.744c0 .472-.232.744-.608.744z" fill="#fff"/>
            <path d="M356 178.009v-18H90a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h266z" fill="#8574ED"/>
            <path d="M338.04 172.089c1.152 0 2.128-.6 2.128-1.728 0-.696-.392-1.192-1.064-1.432.512-.224.848-.616.848-1.2 0-.944-.888-1.52-1.912-1.52-.992 0-1.92.568-1.92 1.536 0 .568.32.984.848 1.192-.64.224-1.072.728-1.072 1.456 0 1.12 1.008 1.696 2.144 1.696zm.016-3.464c-.496 0-.92-.304-.92-.824 0-.512.4-.824.904-.824.512 0 .904.328.904.816 0 .52-.368.832-.888.832zm-.016 2.664c-.576 0-1.072-.344-1.072-.984s.496-.984 1.08-.984c.592 0 1.056.344 1.056.976 0 .64-.456.992-1.064.992zm5.007.8c1.312 0 2.144-.656 2.144-1.712 0-.76-.44-1.232-1.104-1.384.552-.208.928-.6.928-1.248 0-.848-.696-1.536-1.888-1.536-1.224 0-1.968.76-2.024 1.752h.944c.064-.576.456-.944 1.064-.944.592 0 .888.344.888.808 0 .536-.296.84-.952.84h-.408v.76h.44c.696 0 1.032.312 1.032.936 0 .568-.344.928-1.088.928-.768 0-1.104-.448-1.16-1.048h-.968c.048 1.096.792 1.848 2.152 1.848zm4.179-3.08c.784 0 1.392-.592 1.392-1.4 0-.8-.616-1.376-1.384-1.376-.768 0-1.4.592-1.4 1.392 0 .792.608 1.384 1.392 1.384zm-.608 3h.68l3.68-5.72h-.672l-3.688 5.72zm.616-3.648c-.376 0-.616-.256-.616-.744 0-.48.24-.744.616-.744s.608.28.608.744c0 .472-.232.744-.608.744zm3.128 3.728c.784 0 1.4-.592 1.4-1.4 0-.8-.624-1.376-1.392-1.376-.768 0-1.392.592-1.392 1.392 0 .792.6 1.384 1.384 1.384zm.008-.648c-.376 0-.616-.256-.616-.744 0-.48.24-.744.616-.744s.608.28.608.744c0 .472-.232.744-.608.744z" fill="#fff"/>
        </svg>
    );
}
