import { useState } from "react";

import { getRandomInteger, getRandomItem } from "../../../../../utils";

import classes from "./Sparkle.module.css";


const SPARKLE_COLORS = [
    "var(--brand-color-yellow)",
    "var(--brand-color-green)",
    "var(--brand-color-dark-knight)",
    "var(--brand-color-gandalfs-beard)",
];

function getRandomColor() {
    return getRandomItem(SPARKLE_COLORS);
}

// get random size between 8 and 24
function getRandomSize() {
    return getRandomInteger(24, 8);
}

interface Props {
    appearDuration: number;
    fadeDuration: number;
    delay: number;
    top: number;
    left: number;
}

export function Sparkle({ appearDuration, fadeDuration, top, left, delay }: Props) {
    const [color] = useState(getRandomColor());
    const [size] = useState(getRandomSize());
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 20 20"
            fill="none"
            className={classes.sparkle}
            style={{
                "--appear-duration": `${appearDuration}ms`,
                "--fade-duration": `${fadeDuration}ms`,
                "--delay": `${delay}ms`,
                top,
                left,
            }}>
            <path d="M8.472 11.715 0 10.032l8.314-1.706L9.968 0l1.741 8.485L20 10.032l-8.457 1.527L9.968 20l-1.496-8.285z" fill={color}/>
        </svg>
    );
}
