export enum PropValueType {
    String = "String",
    Number = "Number",
    Identifier = "Identifier",
    Bool = "Bool",
    Regex = "Regex",
    Null = "Null",
    JSXElement = "JSXElement",
    Function = "Function",
    Array = "Array",
    Object = "Object",
    Spread = "Spread",
    Member = "Member",
    This = "This",
    Super = "Super",
    TemplateLiteral = "TemplateLiteral",
    Expression = "Expression",
}
