import { Link, generatePath } from "react-router-dom";

import { config } from "../../../../config/frontend";
import saveChartURL from "../../../assets/img/imgSaveChart.png";
import saveChart2xURL from "../../../assets/img/imgSaveChart@2x.png";
import saveChart3xURL from "../../../assets/img/imgSaveChart@3x.png";
import starterEmptyAnimationURL from "../../../assets/img/imgStarterEmpty.gif";
import { H2 } from "../../../library/Heading/Heading";
import { IconBookmark } from "../../../library/icons/IconBookmark";
import { IconLock } from "../../../library/icons/IconLock";
import { RoutePath } from "../../../RoutePath";
import { trackContactUsLinkClick } from "../../../tracking";

import classes from "./EmptyState.module.css";

function EmptyStateWithFeatureGateDisabled() {
    function handleContactUsClick() {
        trackContactUsLinkClick({ source: "saved_charts" });
    }

    return (
        <div className={classes.emptyState}>
            <div className={classes.content}>
                <div className={classes.header}>
                    <IconLock/>
                    <H2 className={classes.h2}>Build your custom dashboard with the Advanced Plan</H2>
                    <p>Save your frequently queried charts to your Saved Dashboard and quickly share them with your team.</p>
                </div>
                <img
                    src={starterEmptyAnimationURL}
                    srcSet={`${starterEmptyAnimationURL} 2x`}
                    alt="Save chart from a new analysis"/>
                <p>
                    <a href={`${config.LANDING_PAGE_BASE_URL}/contact/`} target="_blank" onClick={handleContactUsClick}>
                        Contact us
                    </a>{" "}
                    anytime to learn about the Advanced plan and upgrade.
                </p>
            </div>
        </div>
    );
}

interface AdvancedEmptyStateProps {
    workspaceSlug: string;
}

function EmptyStateWithFeatureGateEnabled({ workspaceSlug }: AdvancedEmptyStateProps) {
    return (
        <div className={classes.emptyState}>
            <div className={classes.content}>
                <div className={classes.header}>
                    <IconBookmark/>
                    <H2 className={classes.h2}>No saved charts yet.</H2>
                    <p>
                        To add a chart to your Saved Dashboard,{" "}
                        <Link to={generatePath(RoutePath.NewAnalytics, { workspaceSlug })}>create a new analysis</Link>{" "}
                        and save it when you’re ready.
                    </p>
                </div>
                <img
                    src={saveChartURL}
                    srcSet={`${saveChart2xURL} 2x, ${saveChart3xURL} 3x`}
                    alt="Save chart from a new analysis"/>
            </div>
        </div>
    );
}

export const EmptyState = {
    FeatureGateDisabled: EmptyStateWithFeatureGateDisabled,
    FeatureGateEnabled: EmptyStateWithFeatureGateEnabled,
};
