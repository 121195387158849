
export function SnitchIllustration() {
    return (
        <svg width="240" height="96" viewBox="0 0 240 96" fill="none">
            <path
                d="M55.2 52.9C30.291 50.465 14.022 32.618 9 24c14.563 15.21 31.637 16.731 53.23 16.224 17.274-.405 37.662 10.647 37.16 14.196-7.532-4.416-21.593-12.027-44.19-1.52z"
                fill="url(#83mfsorrva)"/>
            <path
                d="M50.178 38.196c23.702 0 38.667 4.563 51.221 13.69 1.506 1.52 0 4.33-2.51 2.534-12.053-8.619-28.122-13.182-47.707-13.182C26.074 41.238 13.017 29.746 9 24c3.85 4.732 17.476 14.196 41.178 14.196z"
                fill="#F69833"/>
            <path
                d="M180.828 39.146C204.447 31.21 216.257 10.409 219.21 1c-10.741 17.885-26.964 23.183-48.022 27.548-16.847 3.492-34.163 18.692-32.886 21.989 6.323-5.936 18.268-16.408 42.526-11.391z"
                fill="url(#ui61i11khb)"/>
            <path
                d="M182.427 23.891c-22.991 5.326-36.491 13.073-46.638 24.662-1.123 1.8.964 4.16 3 1.87 9.772-10.988 24.344-18.983 43.341-23.383C206.485 21.398 216.592 7.423 219.21 1c-2.682 5.411-13.792 17.566-36.783 22.891z"
                fill="#F69833"/>
            <circle cx="120" cy="62" r="18" fill="#262B2E"/>
            <circle opacity=".2" cx="120" cy="62" r="15" transform="rotate(10 120 62)"
                fill="#6B7276"/>
            <path
                d="M122.605 47.228a3 3 0 0 0-3.476 2.433l-4.167 23.636a3 3 0 0 0 2.433 3.475c8.159 1.439 15.939-4.009 17.377-12.167 1.439-8.159-4.009-15.939-12.167-17.377z"
                fill="#F69833"/>
            <path fillRule="evenodd" clipRule="evenodd"
                d="M116.108 76.212c6.927-.242 12.98-5.289 14.232-12.388 1.252-7.1-2.711-13.913-9.138-16.51a3 3 0 0 0-2.073 2.347l-4.167 23.636a2.998 2.998 0 0 0 1.146 2.915z"
                fill="#FFC738"/>
            <defs>
                <linearGradient id="83mfsorrva" x1="62.23" y1="37.689" x2="54.074" y2="54.359"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFC738"/>
                    <stop offset="1" stopColor="#FFC738" stopOpacity="0"/>
                </linearGradient>
                <linearGradient id="ui61i11khb" x1="170.624" y1="26.112" x2="182.136" y2="40.382"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFC738"/>
                    <stop offset="1" stopColor="#FFC738" stopOpacity="0"/>
                </linearGradient>
            </defs>
        </svg>
    );
}
