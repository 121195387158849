import { useState } from "react";

import { IconAddMetadata } from "../../../library/icons/IconAddMetadata";
import { type Subscription } from "../../../models/Subscription";
import { CustomPropertiesDialog } from "../customPropertiesDialog/CustomPropertiesDialog";

import classes from "./AddCustomPropertyButton.module.css";

interface Props {
    subscription: Subscription;
}

export function AddCustomPropertyButton({ subscription }: Props) {
    const [isDisalogVisible, setIsDialogVisible] = useState(false);

    return (
        <>
            <button
                className={classes.addCustomPropertyButton}
                type="button"
                onClick={() => setIsDialogVisible(true)}>
                <IconAddMetadata/> Add custom property
            </button>
            {isDisalogVisible && (
                <CustomPropertiesDialog
                    subscription={subscription}
                    onClose={() => setIsDialogVisible(false)}/>
            )}
        </>
    );
}
