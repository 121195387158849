interface Props {
    type?: "light" | "dark";
}

export function LogoGithub({ type = "light" }: Props) {
    const color = type === "light" ? "#fff" : "#000";

    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path fill={color} d="M18.659 4.98a9.957 9.957 0 0 0-3.64-3.639C13.487.447 11.814 0 10 0 8.187 0 6.513.447 4.98 1.341a9.956 9.956 0 0 0-3.639 3.64C.447 6.513 0 8.186 0 10c0 2.179.636 4.138 1.908 5.879 1.271 1.74 2.914 2.945 4.928 3.613.234.044.408.013.52-.091a.51.51 0 0 0 .17-.39l-.007-.704c-.004-.442-.006-.829-.006-1.158l-.3.051c-.19.035-.432.05-.723.046a5.504 5.504 0 0 1-.904-.091 2.023 2.023 0 0 1-.873-.39 1.652 1.652 0 0 1-.573-.801l-.13-.3a3.253 3.253 0 0 0-.41-.664c-.187-.243-.375-.408-.566-.495l-.091-.065a.957.957 0 0 1-.17-.156.715.715 0 0 1-.117-.183c-.026-.06-.004-.11.065-.15.07-.039.196-.058.378-.058l.26.04c.174.034.389.138.645.312.256.173.466.399.631.676.2.356.44.628.723.814.282.187.566.28.853.28.286 0 .533-.021.742-.065a2.59 2.59 0 0 0 .586-.195c.078-.582.29-1.029.638-1.341a8.92 8.92 0 0 1-1.335-.235 5.316 5.316 0 0 1-1.224-.508A3.505 3.505 0 0 1 4.57 12.8c-.278-.347-.506-.803-.684-1.368-.177-.564-.266-1.215-.266-1.953 0-1.05.343-1.944 1.028-2.682-.32-.79-.29-1.676.091-2.657.252-.078.625-.019 1.12.176.495.196.857.363 1.087.502.23.138.415.256.554.351a9.245 9.245 0 0 1 2.5-.338c.86 0 1.693.113 2.5.338l.495-.312c.338-.209.738-.4 1.198-.573.46-.174.811-.222 1.055-.143.39.98.425 1.866.104 2.656.685.738 1.028 1.632 1.028 2.682 0 .738-.089 1.391-.267 1.96-.178.569-.407 1.024-.69 1.367a3.641 3.641 0 0 1-1.054.866c-.421.235-.83.404-1.224.508-.395.104-.84.183-1.335.235.451.39.677 1.007.677 1.849v2.747c0 .156.054.287.163.39.108.105.28.135.514.092 2.014-.669 3.657-1.873 4.929-3.614C19.364 14.14 20 12.18 20 10c0-1.814-.448-3.487-1.341-5.02z"/>
        </svg>
    );
}
