import { AnalysisSubject } from "./AnalysisSubject";

export enum BreakdownType {
    ProjectDefined = "projectDefined",
    ProjectUsedIn = "projectUsedIn",
    Tag = "tag",
}

export function toBreakdownType(breakdown: string | null | undefined, subject?: AnalysisSubject): BreakdownType | undefined {
    if (!breakdown || !subject) {
        return undefined;
    }

    let validBreakdownTypes: string[] = [];
    if (subject === AnalysisSubject.Components) {
        validBreakdownTypes = [BreakdownType.ProjectUsedIn];
    } else if (subject === AnalysisSubject.Projects) {
        validBreakdownTypes = [BreakdownType.ProjectDefined, BreakdownType.Tag];
    } else if (subject === AnalysisSubject.Tags) {
        validBreakdownTypes = [BreakdownType.ProjectDefined, BreakdownType.ProjectUsedIn];
    }

    if (!validBreakdownTypes.includes(breakdown)) {
        return undefined;
    }

    return breakdown as BreakdownType;
}

export function getBreakdownTypeLabel(breakdownType: BreakdownType): string {
    switch (breakdownType) {
        case BreakdownType.ProjectDefined:
            return "Project it's from";
        case BreakdownType.ProjectUsedIn:
            return "Project it's used in";
        case BreakdownType.Tag:
            return "Tag";
    }
}
