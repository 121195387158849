import { AppEnvType, readAppEnv, readBooleanVar } from "./common";

declare const __APP_VERSION__: string;

const env = import.meta.env;

const APP_BASE_URL = env.VITE_APP_BASE_URL as string;
const LANDING_PAGE_BASE_URL = env.VITE_LANDING_PAGE_BASE_URL as string;
const APP_ENV = env.MODE === "development" ? AppEnvType.Local : readAppEnv(env.MODE);
const isLocal = APP_ENV === AppEnvType.Local;

interface FrontendConfig {
    APP_BASE_URL: string;
    LANDING_PAGE_BASE_URL: string;
    TELEMETRY_URL: string;
    MIXPANEL_API_TOKEN: string;
    MIXPANEL_DEBUG_ENABLED: boolean;
    APP_VERSION: string;
    IGNORE_DONT_TRACK: boolean;
    MIXPANEL_ENABLED: boolean;
    APP_ENV: AppEnvType;
    SENTRY_DSN: string;
    SENTRY_ENABLED: boolean;
    INTERCOM_APP_ID: string;
    DEMO_WORKSPACE_SLUG: string;
    ALLOW_MULTIPLE_WORKSPACE_CREATION: boolean;
}

export const config: FrontendConfig = {
    APP_BASE_URL,
    LANDING_PAGE_BASE_URL,
    TELEMETRY_URL: `${APP_BASE_URL}/tm`,
    MIXPANEL_API_TOKEN: (env.VITE_MIXPANEL_API_TOKEN ?? "") as string,
    MIXPANEL_DEBUG_ENABLED: readBooleanVar(env.VITE_MIXPANEL_DEBUG_ENABLED, false),
    APP_VERSION: __APP_VERSION__,
    IGNORE_DONT_TRACK: isLocal,
    MIXPANEL_ENABLED: readBooleanVar(env.VITE_MIXPANEL_ENABLED, false),
    APP_ENV,
    SENTRY_DSN: (env.VITE_SENTRY_DSN_FRONTEND ?? "") as string,
    SENTRY_ENABLED: !isLocal,
    INTERCOM_APP_ID: env.VITE_INTERCOM_APP_ID as string,
    DEMO_WORKSPACE_SLUG: "proton",
    ALLOW_MULTIPLE_WORKSPACE_CREATION: readBooleanVar(env.VITE_ALLOW_MULTIPLE_WORKSPACE_CREATION, false),
};
