import { useEffect } from "react";

import { generatePath, useNavigate, useParams } from "react-router-dom";

import { APIError, APIErrorCode, getMe, getWorkspace as getWorkspaceBySlug } from "../../api/api";
import { Header } from "../../containers/header/Header";
import { MainHeaderButton } from "../../containers/header/mainHeaderButton/MainHeaderButton";
import { Illustration, LogoCard } from "../../library/LogoCard/LogoCard";
import { logError } from "../../logger";
import { useStore } from "../../providers/StoreProvider/StoreProvider";
import { RoutePath } from "../../RoutePath";

import classes from "./MemberLimitExceeded.module.css";

export function MemberLimitExceeded() {
    const { workspaceSlug } = useParams();
    const navigate = useNavigate();
    const {
        actions: { setUser },
        selectors: { getUser },
    } = useStore();

    const user = getUser();

    async function fetchUser() {
        try {
            const user = await getMe();

            setUser(user);
        } catch (error) {
            logError(error);
        }
    }

    async function fetchWorkspace() {
        if (!workspaceSlug) {
            return;
        }
        try {
            await getWorkspaceBySlug(workspaceSlug);

            navigate(generatePath(RoutePath.RepoHome, { workspaceSlug }), { state: { fromApp: true } });
        } catch (error) {
            if (!(error instanceof APIError) || error.code !== APIErrorCode.MEMBER_LIMIT_EXCEEDED) {
                navigate(generatePath(RoutePath.RepoHome, { workspaceSlug }), { state: { fromApp: true } });
            }
        }

    }

    useEffect(() => {
        fetchUser();
    }, []);

    useEffect(() => {
        fetchWorkspace();
    }, [workspaceSlug]);

    return (
        <>
            <Header
                leftContent={user && <MainHeaderButton/>}
                hideRightContent/>
            <main className={classes.main}>
                <div className={classes.column}>
                    <LogoCard title="Workspace over member limit" illustration={Illustration.Clouds}>
                        <p className={classes.text}>
                            Your workspace is over the member limit. Contact the owner to upgrade.
                        </p>
                    </LogoCard>
                </div>
            </main>
        </>
    );
}
