export enum AnalysisSubject {
    Components = "components",
    Projects = "projects",
    Tags = "tags",
}

export function toAnalysisSubject(subject: string | null | undefined): AnalysisSubject | undefined {
    const validAnalysisSubjects: string[] = Object.values(AnalysisSubject);

    if (!subject || !validAnalysisSubjects.includes(subject)) {
        return undefined;
    }

    return subject as AnalysisSubject;
}

export function getAnalysisSubjectLabel(type: AnalysisSubject) {
    switch (type) {
        case AnalysisSubject.Components:
            return "Components";
        case AnalysisSubject.Projects:
            return "Projects";
        case AnalysisSubject.Tags:
            return "Tags";
    }
}
