import { type ChangeEvent } from "react";
import { useRef, useState } from "react";

import { Button, ButtonKind } from "../../../../library/Button/Button";
import { IconCheck } from "../../../../library/icons/IconCheck";
import { IconChevronDown } from "../../../../library/icons/IconChevronDown";
import { Popover, PopoverDirection } from "../../../../library/Popover/Popover";
import { Tooltip } from "../../../../library/Tooltip/Tooltip";
import { type Price } from "../../../../models/Price";
import { formatCurrency, formatNumber } from "../../../../utils";

import classes from "./Dropdown.module.css";

interface Props {
    disabled: boolean;
    basePrice: Price;
    addonPrice: Price;
    numOfAddons: number;
    onChange: (numOfAddons: number) => void;
}

export function Dropdown({
    disabled,
    basePrice,
    addonPrice,
    numOfAddons,
    onChange,
}: Props) {
    const dropdownRef = useRef<HTMLButtonElement>(null);
    const [isOpen, setIsOpen] = useState(false);
    const baseNumOfComponents = basePrice.product.numOfComponents;
    const additionalNumOfComponents = addonPrice.product.numOfComponents;

    function handleDropdownClick() {
        setIsOpen(true);
    }

    function handleDropdownClose() {
        setIsOpen(false);
    }

    function handleOptionChange(e: ChangeEvent<HTMLInputElement>) {
        setIsOpen(false);
        onChange(Number(e.target.value));
    }

    const button = (
        <Button
            kind={ButtonKind.Secondary}
            className={classes.dropdown}
            onClick={handleDropdownClick}
            ref={dropdownRef}
            active={isOpen}
            disabled={disabled}>
            {formatNumber(baseNumOfComponents + (numOfAddons * additionalNumOfComponents))}
            <IconChevronDown color={disabled ? "var(--label-secondary-disabled-color)" : "var(--label-secondary-color)"}/>
        </Button>
    );

    return (
        <>
            {numOfAddons > 0 && <input type="hidden" name="quantity_for_thousand_components_addon" value={numOfAddons}/>}
            {disabled
                ? (
                    <Tooltip content="Contact us below to change your number of components" followCursor className={classes.tooltip}>
                        {button}
                    </Tooltip>
                )
                : button
            }
            {dropdownRef.current && isOpen &&
                <Popover
                    anchor={dropdownRef.current}
                    direction={PopoverDirection.Vertical}
                    onClose={handleDropdownClose}
                    onCancel={handleDropdownClose}
                    className={classes.dropdownMenu}>
                    {new Array(10).fill(null).map((_, index) => {
                        const value = baseNumOfComponents + (index * additionalNumOfComponents);
                        return (
                            <label key={value} className={classes.dropdownItem}>
                                <div className={classes.inner}>
                                    <input
                                        type="radio"
                                        value={index}
                                        onChange={handleOptionChange}
                                        checked={numOfAddons === index}/>
                                    <IconCheck />
                                    <span>
                                        {formatNumber(value)}
                                        <span className={classes.price}>{" "}— {formatCurrency((basePrice.monthlyAmount) + (addonPrice.monthlyAmount * index))}/mo</span>
                                    </span>
                                </div>
                            </label>
                        );
                    })}
                </Popover>
            }
        </>
    );
}
