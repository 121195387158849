import mixpanel, { type Dict } from "mixpanel-browser";

import { config } from "../config/frontend";

import { type ViewOption } from "./containers/SharePopover/SharePopover";
import { type AnalysisSubject } from "./models/AnalysisSubject";
import { type AnalysisType } from "./models/AnalysisType";
import { type BreakdownType } from "./models/BreakdownType";
import { type Filter } from "./models/Filter";
import { type FilterType } from "./models/FilterType";
import { type FolderFilter } from "./models/FolderFilter";
import { type SortOrder } from "./models/SortOrder";
import { type SortType } from "./models/SortType";
import { type SubscriptionPeriod } from "./models/SubscriptionPeriod";
import { type SubscriptionPlan } from "./models/SubscriptionPlan";
import { type SubscriptionStatus } from "./models/SubscriptionStatus";
import { type DependencyType } from "./pages/componentDetail/treeView/dependencyList/DependencyList";
import { type PredefinedChartType } from "./pages/popularCharts/constants";

export function init(trackingEnabled: boolean) {
    mixpanel.init(config.MIXPANEL_API_TOKEN, {
        api_host: config.TELEMETRY_URL,
        ignore_dnt: config.IGNORE_DONT_TRACK,
        debug: config.MIXPANEL_DEBUG_ENABLED,
    });

    mixpanel.register({
        "App Version": config.APP_VERSION,
    });

    if (!trackingEnabled) {
        mixpanel.disable();
    }
}

interface DefaultProps {
    workspaceId?: string;
    workspaceSlug?: string;
    subscriptionPlan?: SubscriptionPlan;
    subscriptionStatus?: SubscriptionStatus;
}

function trackEvent(name: string, props?: Dict) {
    mixpanel.track(name, props);
}

export function getTrackingId(): string | undefined {
    try {
        return mixpanel.get_distinct_id() as string;
    } catch {
        // nop
    }
}

export function identifyUser(userId: string, email: string) {
    mixpanel.identify(userId);

    mixpanel.register({
        email,
        userId,
    });
}

export function setDefaultProps(props: DefaultProps = {}) {
    mixpanel.register(props);
}

// General
export function trackVisit(props: { path: string; page: string; route: string; fromShare: boolean; }) {
    trackEvent("Visited page", props);
}

export function trackInviteUser() {
    trackEvent("Invited user");
}

export function trackRemoveInvite() {
    trackEvent("Removed invite from workspace");
}

export function trackRemoveUser() {
    trackEvent("Removed user from workspace");
}

export function trackInviteClick() {
    trackEvent("Clicked invite button");
}

export function trackCopyInviteLink() {
    trackEvent("Copied workspace invite link");
}

export function trackResetWorkspaceInviteLink() {
    trackEvent("Reset workspace invite link");
}

export function trackLearnClick() {
    trackEvent("Clicked learn button");
}

export function trackDocsLinkClick() {
    trackEvent("Clicked the docs link in the header");
}

// Components
interface FilterComponentsProps {
    sortBy: SortType;
    sortOrder: SortOrder;
    searchTerm?: string;
    folders: FolderFilter;
    filters: Filter[];
    changedProperty: string;
}

export function trackFilterComponents(props: FilterComponentsProps) {
    trackEvent("Filtered components", props);
}

interface CreateTagProps {
    name: string;
    searchTerm?: string;
    folders: FolderFilter;
    filters: Filter[];
}

export function trackCreateTag(props: CreateTagProps) {
    trackEvent("Created a tag", props);
}

interface UpdateTagProps {
    slug: string;
    name?: string;
    searchTerm?: string;
    folders?: FolderFilter;
    filters?: Filter[];
}

export function trackUpdateTag(props: UpdateTagProps) {
    trackEvent("Updated a tag", props);
}

interface DeleteTagProps {
    slug: string;
}

export function trackDeleteTag(props: DeleteTagProps) {
    trackEvent("Deleted a tag", props);
}

export function trackClickComponentList() {
    trackEvent("Visited a component from the components list");
}

export function trackNavigateBackToDashboard() {
    trackEvent("Navigated back to dashboard");
}

// Analytics
interface DownloadCSVProps {
    analysisType: AnalysisType;
    analysisSubject: AnalysisSubject;
}

export function trackDownloadAnalyticsDataAsCSV(props: DownloadCSVProps) {
    trackEvent("Downloaded analytics data as CSV", props);
}

interface CopyLinkProps {
    analysisType: AnalysisType;
    analysisSubject: AnalysisSubject;
}

export function trackCopyAnalyticsChartLink(props: CopyLinkProps) {
    trackEvent("Copied chart link", props);
}

interface ChangeAnalysisTypeProps {
    analysisType: AnalysisType;
}

export function trackChangeAnalysisType(props: ChangeAnalysisTypeProps) {
    trackEvent("Changed analysis type", props);
}

interface ChangeAnalysisSubjectProps {
    analysisSubject: AnalysisSubject;
}

export function trackChangeAnalysisSubject(props: ChangeAnalysisSubjectProps) {
    trackEvent("Changed analysis subject", props);
}

interface AddAnalyticsFilterProps {
    filterType: FilterType;
}

export function trackAddAnalyticsFilter(props: AddAnalyticsFilterProps) {
    trackEvent("Added filter to the analysis query", props);
}

export function trackRemoveAnalyticsFilter() {
    trackEvent("Removed filter from the analysis query");
}

interface AddBreakdownProps {
    breakdownType: BreakdownType;
}

export function trackAddAnalyticsBreakdown(props: AddBreakdownProps) {
    trackEvent("Added breakdown option to the analysis query", props);
}

export function trackRemoveAnalyticsBreakdown() {
    trackEvent("Removed breakdown option from the analysis query");
}

// Onboarding
export function trackCoreTagRename() {
    trackEvent("Renamed Core tag");
}

export function trackUserDefinedTagAddInOnboarding() {
    trackEvent("Created user defined tags in onboarding");
}

// Popular Charts Dashboard
export function trackPopularDashboardChartTitleClick(props: { chartType: PredefinedChartType; }) {
    trackEvent("Clicked a popular dashboard chart title", props);
}

export function trackDashboardTagChange(props: { tag: string; }) {
    trackEvent("Changed the tag in the dashboard", props);
}

export function trackManageTagClickInDropdown() {
    trackEvent("Clicked the manage tag link in the dropdown");
}

export function trackDashboardPlayButton(props: { section: string; videoId: string; }) {
    trackEvent("Clicked the dashboard play button", props);
}

export function trackDashboardTablePageChange(props: { page: number; pageSize: number; }) {
    trackEvent("Changed a dashboard table page", props);
}

// Saved Charts
export function trackSavedDashboardChartTitleClick(props: { title: string; description: string; slug: string; }) {
    trackEvent("Clicked a saved dashboard chart title", props);
}

export function trackSaveChartButtonClick() {
    trackEvent("Clicked save chart button");
}

interface CreateSavedChartProps {
    slug: string;
    name: string;
    description: string;
    analysisType: AnalysisType;
    analysisSubject: AnalysisSubject;
    breakdownType?: BreakdownType;
}

export function trackCreateSavedChart(props: CreateSavedChartProps) {
    trackEvent("Created a saved chart", props);
}

type UpdateSavedChartProps = Partial<Omit<CreateSavedChartProps, "breakdownType">> & {
    breakdownType?: BreakdownType | null;
};

export function trackUpdateSavedChart(props: UpdateSavedChartProps) {
    trackEvent("Updated a saved chart", props);
}

export function trackDeleteSavedChart(props: { slug: string; }) {
    trackEvent("Deleted a saved chart", props);
}

// Component details
// Dependency tree
export function trackVisitNodeInTreeView(props: { nodeType: "parent" | "child"; }) {
    trackEvent("Visited a component from the tree view", props);
}

export function trackNavigateBackToComponentList() {
    trackEvent("Navigated back to the components list");
}

export function trackDependencyListTypeChange(props: { dependencyType: DependencyType; }) {
    trackEvent("Changed dependency type on dependecy list", props);
}

export function trackDependencyListSearch(props: { term: string; }) {
    trackEvent("Searched components on dependency list", props);
}

export function trackDependencyListProjectFilterChange(props: { project: string; }) {
    trackEvent("Changed project filter on dependency list", props);
}

export function trackDependencyListTagsFilterChange(props: { tags: string[]; }) {
    trackEvent("Changed tags filter on dependency list", props);
}

export function trackDependencyListLevelFilterChange(props: { level: number; }) {
    trackEvent("Changed level filter on dependency list", props);
}

export function trackDependencyListComponentClick(props: { component: string; }) {
    trackEvent("Clicked component on dependency list", props);
}

// Props
export function trackPropsTabClick() {
    trackEvent("Clicked the props tab");
}

export function trackPropRowExpand() {
    trackEvent("Expanded a prop row");
}

interface PropUsageClickProps {
    propValue?: string;
}

export function trackPropUsageClick(props?: PropUsageClickProps) {
    trackEvent("Clicked a prop usage", props);
}

export function trackCopyShareLink(props: { viewOption: ViewOption; }) {
    trackEvent("Copied share link", props);
}

export function trackChangePageViewOption(props: { viewOption: ViewOption; }) {
    trackEvent("Changed page view option", props);
}

export function trackAskToJoinWorkspace() {
    trackEvent("Asked to join workspace");
}

export function trackAcceptWorkspaceJoinRequest() {
    trackEvent("Accepted workspace join request");
}

export function trackDenyWorkspaceJoinRequest() {
    trackEvent("Denied workspace join request");
}

export function trackDataIssueButtonClick() {
    trackEvent("Clicked the data issue button");
}

export function trackPricingTableDialogOpen(props: { source: string; }) {
    trackEvent("Opened the pricing table dialog", props);
}

export function trackPricingTableNumOfComponentsChange(props: { numOfComponents: number; }) {
    trackEvent("Changed the number of components in the pricing table", props);
}

export function trackSubscriptionDowngradeClick() {
    trackEvent("Clicked the subscription downgrade button");
}

export function trackSubscriptionUpgradeClick(props: { numOfComponents: number; period: SubscriptionPeriod; newPlan: SubscriptionPlan; }) {
    trackEvent("Clicked the subscription upgrade button", props);
}

export function trackPaymentSuccessDialogOpen() {
    trackEvent("Opened the payment success dialog");
}

export function trackContactUsLinkClick(props: { source: string; }) {
    trackEvent("Clicked the contact us link", props);
}
