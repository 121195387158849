import * as intercom from "../../../intercom";
import {
    identifyUser,
    setDefaultProps,
    trackPaymentSuccessDialogOpen,
    trackPricingTableDialogOpen,
} from "../../../tracking";
import { type OpenPricingTableDialog, type All, type SetUser, type SetWorkspace } from "../payloads";
import { type State } from "../State";
import { Types } from "../types";

const reducers = {
    setUser(state: State, { user }: SetUser) {
        identifyUser(user.id, user.email);
        intercom.setUser(user);
        return state;
    },
    setWorkspace(state: State, { workspace }: SetWorkspace) {
        setDefaultProps({
            workspaceId: workspace.id,
            workspaceSlug: workspace.slug,
            subscriptionPlan: workspace.subscription.plan,
            subscriptionStatus: workspace.subscription.status,
        });
        return state;
    },
    openPricingTableDialog(state: State, { source }: OpenPricingTableDialog): State {
        trackPricingTableDialogOpen({ source });
        return state;
    },
    openPaymentSuccessDialog(state: State): State {
        trackPaymentSuccessDialogOpen();
        return state;
    },
};

export function reducer(state: State, action: All) {
    switch (action.type) {
        case Types.SET_USER:
            return reducers.setUser(state, action);
        case Types.SET_WORKSPACE:
            return reducers.setWorkspace(state, action);
        case Types.OPEN_PRICING_TABLE_DIALOG:
            return reducers.openPricingTableDialog(state, action);
        case Types.OPEN_PAYMENT_SUCCESS_DIALOG:
            return reducers.openPaymentSuccessDialog(state);
        default:
            return state;
    }
}
