export enum RoutePath {
    Login = "/login",
    LoginWithEmail = "/login/email",
    CliLoginSuccess = "/login/cli-success",
    LoginSuccess = "/login/success",
    InviteLink = "/invite/:workspaceSlug/:code",
    InvalidInvite = "/invalid-invite",
    SelectProfession = "/select-profession",
    CreateWorkspace = "/create-workspace",
    Tutorial = "/tutorial",
    RepoHome = "/:workspaceSlug",
    AllScans = "/:workspaceSlug/all-scans",
    Dashboard = "/:workspaceSlug/analytics",
    SavedCharts = "/:workspaceSlug/analytics/saved-charts",
    NewAnalytics = "/:workspaceSlug/analytics/view",
    SavedChart = "/:workspaceSlug/analytics/saved-charts/:savedChartSlug",
    Components = "/:workspaceSlug/components",
    ComponentDetail = "/:workspaceSlug/components/:componentSlug",
    CoreSelection = "/:workspaceSlug/manage-tags/core",
    Onboarding = "/:workspaceSlug/onboarding",
    QuickStart = "/:workspaceSlug/quick-start",
    DesignerQuickStart = "/:workspaceSlug/quick-start/designer",
    DeveloperQuickStart = "/:workspaceSlug/quick-start/developer",
    MemberLimitExceeded = "/:workspaceSlug/member-limit-exceeded",
    TrialEnd = "/:workspaceSlug/trial-end",
    DataRetentionLimitReached = "/:workspaceSlug/data-retention-limit-reached",
    ComponentLimitExceeded = "/:workspaceSlug/component-limit-exceeded",
    Pricing = "/pricing",
    WorkspacePricing = "/:workspaceSlug/pricing",
    Unknown = "*",
}

export const PAID_PAGES = [
    RoutePath.AllScans,
    RoutePath.NewAnalytics,
    RoutePath.SavedChart,
    RoutePath.ComponentDetail,
];
