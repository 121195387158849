import { type MouseEvent, useEffect } from "react";

import { createPortal } from "react-dom";

import { config } from "../../../../../config/frontend";
import { Keyboard } from "../../../../enums";
import { Button, ButtonAnchor, ButtonKind } from "../../../../library/Button/Button";
import { EggType, Egg } from "../../../../library/Egg/Egg";
import { type Subscription } from "../../../../models/Subscription";
import { SubscriptionPlan, subscriptionPlanToString } from "../../../../models/SubscriptionPlan";
import { useStore } from "../../../../providers/StoreProvider/StoreProvider";
import { trackContactUsLinkClick } from "../../../../tracking";
import { formatDate, getDaysBetween, isToday, pluralize } from "../../../../utils";

import classes from "./SubscriptionInfoDialog.module.css";

interface Props {
    buttonRect: DOMRect;
    subscription: Subscription;
    numOfRecentAnalyses: number;
    nextAvailableAnalysisDate: Date;
    onClose(): void;
}

function generateRandomEggCombination<T>(inputArray: T[], desiredLength: number) {
    const resultArray: T[] = [];
    const inputLength = inputArray.length;

    if (inputLength === 0) {
        return resultArray;
    }

    const shuffledArray = [...inputArray].sort(() => Math.random() - 0.5);
    if (desiredLength <= inputLength) {
        for (let i = 0; i < desiredLength; i++) {
            resultArray.push(shuffledArray[i]);
        }
    } else {
        for (let i = 0; i < desiredLength; i++) {
            const randomIndex = Math.floor(Math.random() * inputLength);
            resultArray.push(inputArray[randomIndex]);
        }
    }

    return resultArray;
}

function Eggs({ totalNumber, cracked = 0 }: { totalNumber: number; cracked?: number; }) {
    const regularEggList = [EggType.Default_1, EggType.Default_2, EggType.Default_3, EggType.Default_4, EggType.Default_5];
    const crackedEggList = [EggType.Cracked_1, EggType.Cracked_2, EggType.Cracked_3, EggType.Cracked_4, EggType.Cracked_5];

    const regularEggs = generateRandomEggCombination(regularEggList, totalNumber - cracked).map((eggIdx, itemIdx) => (
        <Egg key={`regular_${itemIdx}`} type={eggIdx}/>
    ));
    const crackedEggs = generateRandomEggCombination(crackedEggList, cracked).map((eggIdx, itemIdx) => (
        <Egg key={`cracked_${itemIdx}`} type={eggIdx}/>
    ));

    return <>{[...regularEggs, ...crackedEggs]}</>;
}



export function SubscriptionInfoDialog({
    buttonRect,
    subscription,
    numOfRecentAnalyses,
    nextAvailableAnalysisDate,
    onClose,
}: Props) {
    const { actions: { openPricingTableDialog } } = useStore();

    function handlePricingClick() {
        openPricingTableDialog("subscription_info_dialog");
    }

    function handleOverlayClick(event: MouseEvent<HTMLDivElement>) {
        event.stopPropagation();
        onClose();
    }

    function handleDialogClick(event: MouseEvent<HTMLDivElement>) {
        event.stopPropagation();
    }

    function handleKeyDown(event: KeyboardEvent) {
        switch (event.code) {
            case Keyboard.Code.Escape:
                event.preventDefault();
                onClose();
                break;
        }
    }

    function handleContactUsClick() {
        trackContactUsLinkClick({ source: "subscription_info_dialog" });
    }

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    function getDetails() {
        if (subscription.plan === SubscriptionPlan.TrialEnded) {
            return {
                title: "Your trial has ended",
                description: <>
                    You can continue to view your analytics dashboard based on your most recent scan, but won’t be able to view details.
                    <br/><br/>
                    Contact us to learn more about our plans and upgrade.
                </>,
                eggs: <Eggs totalNumber={1} cracked={1}/>,
            };
        }
        if (!subscription.trial || subscription.plan === SubscriptionPlan.Free) {
            const isScanAvailableToday = isToday(new Date(nextAvailableAnalysisDate));
            const readableDate = formatDate(nextAvailableAnalysisDate, { month: "long", day: "numeric" });
            return {
                title: `${subscriptionPlanToString(subscription.plan)} plan`,
                description: (
                    <>
                        You're currently on the
                        {" "}
                        {subscriptionPlanToString(subscription.plan)}
                        {" "}
                        plan where you can scan up to
                        {" "}
                        {pluralize("time", subscription.features.analysisLimit)}
                        {" "}
                        within 30 days with {subscription.features.dataRetentionLimit}-day scan retention and have up to
                        {" "}
                        {pluralize("component", subscription.features.baseNumOfComponents + subscription.features.additionalNumOfComponents)}
                        .
                        {numOfRecentAnalyses === subscription.features.analysisLimit && (
                            <span>
                                <br/><br/>
                                Looks like you used up all of your scans in the last 30 days.
                                Your next scan will be available
                                {" "}
                                {isScanAvailableToday ? "later today" : `on ${readableDate}`}.
                            </span>
                        )}
                    </>
                ),
            };
        }

        const daysLeft = Math.max(1, getDaysBetween(new Date(), subscription.trial.endedAt));

        const formattedDate = formatDate(subscription.trial.endedAt, { month: "long", day: "numeric" });
        if (daysLeft === 1) {
            return {
                title: "Last day of your trial",
                description: <>
                    Your trial will end on <strong>{formattedDate}</strong>. Contact us to learn more about our plans and upgrade.
                </>,
                eggs: <Eggs totalNumber={7} cracked={6}/>,
            };
        }

        if (daysLeft <= 7) {
            return {
                title: `${daysLeft} days left in your trial`,
                description: <>
                    Your trial will end on <strong>{formattedDate}</strong>. Contact us to learn more about our plans and upgrade.
                </>,
                eggs: <Eggs totalNumber={7} cracked={7 - daysLeft}/>,
            };
        }

        return {
            title: `${daysLeft} days left in your trial`,
            description: <>Contact us anytime to learn more about our plans and upgrade.</>,
        };
    }

    const { title, description, eggs } = getDetails();


    return createPortal(
        <div className={classes.subscriptionInfoDialogOverlay} onClick={handleOverlayClick}>
            <div
                className={classes.subscriptionInfoDialog}
                style={{ top: buttonRect.bottom, left: buttonRect.left }}
                onClick={handleDialogClick}>
                {(eggs && <div className={classes.eggs}>
                    {eggs}
                </div>)}
                <h1>{title}</h1>
                <p>{description}</p>
                <div className={classes.row}>
                    <Button kind={ButtonKind.Primary} onClick={handlePricingClick}>
                        See plans
                    </Button>
                    <ButtonAnchor
                        kind={ButtonKind.Secondary}
                        href={`${config.LANDING_PAGE_BASE_URL}/contact/`}
                        rel="nofollow external noopener noreferrer"
                        target="_blank"
                        onClick={handleContactUsClick}>
                        Contact us
                    </ButtonAnchor>
                </div>
            </div>
        </div>,
        document.body
    );
}
