import { type ReactNode, useEffect, useMemo, useState, useRef } from "react";

import { getDataAnalysis } from "../../api/api";
import { getDataCacheKey } from "../../api/urlBuilder";
import { AreaChart } from "../../common/chart/areaChart/AreaChart";
import { SingleDataAreaChart } from "../../common/chart/areaChart/SingleDataAreaChart";
import { BarChart } from "../../common/chart/barChart/BarChart";
import { ChartMode } from "../../common/chart/barChart/ChartMode";
import { LineChart } from "../../common/chart/lineChart/LineChart";
import { SingleDataLineChart } from "../../common/chart/lineChart/SingleDataLineChart";
import { getLegendItems } from "../../common/chart/utils";
import { usePrevious } from "../../hooks/usePrevious";
import { Button, ButtonKind } from "../../library/Button/Button";
import { IconLink } from "../../library/icons/IconLink";
import { Loading } from "../../library/Loading/Loading";
import { AccessLevel } from "../../models/AccessLevel";
import { AnalysisSubject } from "../../models/AnalysisSubject";
import { AnalysisType } from "../../models/AnalysisType";
import { BreakdownType } from "../../models/BreakdownType";
import { type Filter } from "../../models/Filter";
import { RESERVED_TAGS } from "../../models/Tag";
import { type PredefinedChartType } from "../../pages/popularCharts/constants";
import { ChartInsight } from "../../pages/popularCharts/insight/ChartInsight";
import { useDataCacheStore } from "../../providers/DataCacheProvider/DataCacheProvider";
import { useStore } from "../../providers/StoreProvider/StoreProvider";
import { compareString, compareTag } from "../../sortUtils";
import { trackDownloadAnalyticsDataAsCSV } from "../../tracking";
import { arrayGroup, triggerDownload } from "../../utils";
import { PageType, SharePopover } from "../SharePopover/SharePopover";

import { insertDuplicateData, transform } from "./dataTransformer";
import { EmptyState } from "./emptyState/EmptyState";
import { Filters } from "./filters/Filters";

import classes from "./Analytics.module.css";

interface Props {
    workspaceSlug: string;
    analysisType: AnalysisType;
    analysisSubject?: AnalysisSubject;
    filters: Filter[];
    breakdownType?: BreakdownType;
    titleText: string;
    title: ReactNode;
    description: ReactNode;
    chartActions: ReactNode;
    equivalentPredefinedChartType?: PredefinedChartType;
    onAnalysisTypeChange(analysisType: AnalysisType): void;
    onAnalysisSubjectChange(analysisSubject: AnalysisSubject): void;
    onFiltersChange(filters: Filter[]): void;
    onBreakdownTypeChange(breakdownType: BreakdownType | undefined): void;
}

export function Analytics({
    workspaceSlug,
    analysisType,
    analysisSubject,
    filters,
    breakdownType,
    titleText,
    title,
    description,
    chartActions,
    equivalentPredefinedChartType,
    onAnalysisTypeChange,
    onAnalysisSubjectChange,
    onFiltersChange,
    onBreakdownTypeChange,
}: Props) {
    const loadingTimeoutRef = useRef<number>();
    const [isLoading, setIsLoading] = useState(false);

    const shareButtonRef = useRef<HTMLButtonElement>(null);
    const [sharePopoverOpen, setSharePopoverOpen] = useState(false);

    const {
        selectors: {
            getTags,
            getWorkspace,
            getDashboardURL,
            getAccessLevel,
        },
    } = useStore();

    const tags = getTags();
    const allTags = [...tags, RESERVED_TAGS.UNTAGGED];
    const tagMap = useMemo(() => Object.fromEntries(allTags.map(t => [t.slug, t])), [tags]);
    const projects = getWorkspace()?.projects ?? [];
    const projectMap = Object.fromEntries(projects.map(p => [p.packageName, p]));
    const accessLevel = getAccessLevel();

    const {
        selectors: { getAnalysisData },
        actions: { cacheAnalysisData },
    } = useDataCacheStore();

    const dataCacheKey = getDataCacheKey({ workspaceSlug, analysisType, analysisSubject, filters });
    const previousDataCacheKey = usePrevious(dataCacheKey);
    const analysisData = getAnalysisData(dataCacheKey);

    const chartData = useMemo(() => {
        if (analysisSubject === undefined || analysisData === null) {
            return null;
        }

        return transform(analysisData, projectMap, allTags, analysisType, analysisSubject, breakdownType, workspaceSlug);
    }, [analysisData, breakdownType]);

    async function handleDownloadCSV() {
        if (analysisSubject === undefined || analysisData === null) {
            return;
        }

        trackDownloadAnalyticsDataAsCSV({
            analysisType,
            analysisSubject,
        });

        const csvHeader = ["Analysis date", "Component name", "Project it's from", "Project it's used in", "Tags", "Number of usages"];
        const date = new Date().toISOString().split("T")[0];
        const fileName = analysisType === AnalysisType.DataOverTime
            ? `omlet_analysis_over_time_${date}.csv`
            : `omlet_analysis_latest_data_${date}.csv`;

        const analysisByDate = arrayGroup(analysisData, item => item.key.analysisDate);
        const analysisByDateAndComponent = Object.fromEntries(
            Object.entries(analysisByDate)
                .map(([key, value]) => [key, arrayGroup(value, item => item.key.childDefinitionId)])
        );

        const csvLines = Object.entries(analysisByDateAndComponent)
            .sort(([d1], [d2]) => compareString(d1, d2))
            .flatMap(([analysisDate, componentEntries]) =>
                Object.entries(componentEntries)
                    .sort(([c1], [c2]) => compareString(c1, c2))
                    .flatMap(([, analyses]) => {
                        const tags = [
                            ...new Set(
                                analyses
                                    .sort((a, b) => compareTag(tagMap[a.key.childTag], tagMap[b.key.childTag]))
                                    .map(({ key: { childTag } }) => tagMap[childTag]?.name ?? childTag)
                            )]
                            .join(",");

                        return analyses.map(({ key: { parentPackageName, childPackageName, childName }, sumOfUsages }) =>
                            `"${analysisDate}","${childName}","${childPackageName}","${parentPackageName ?? ""}","${tags}","${sumOfUsages}"`
                        );
                    })
            )
            .join("\n");

        triggerDownload({
            data: URL.createObjectURL(new Blob([`${csvHeader}\n${csvLines}`], { type: "text/csv" })),
            name: fileName,
        });
    }

    useEffect(() => {
        if (analysisSubject === undefined || dataCacheKey === previousDataCacheKey) {
            return;
        }

        async function fetchDataAnalysis() {
            const analysisData = await getDataAnalysis(workspaceSlug, analysisType, analysisSubject!, filters);

            window.clearTimeout(loadingTimeoutRef.current);
            setIsLoading(false);

            cacheAnalysisData(dataCacheKey, analysisData);
        }

        window.clearTimeout(loadingTimeoutRef.current);
        loadingTimeoutRef.current = window.setTimeout(() => setIsLoading(true), 500);
        fetchDataAnalysis();
    }, [analysisType, analysisSubject, filters]);

    function renderChartActions() {
        if (accessLevel === AccessLevel.Page) {
            return null;
        }

        return (
            <>
                <button className={classes.chartAction} onClick={handleDownloadCSV} disabled={analysisData === null || analysisData.length === 0}>Download CSV</button>
                <Button
                    ref={shareButtonRef}
                    kind={ButtonKind.Secondary}
                    icon={<IconLink/>}
                    disabled={analysisSubject === undefined}
                    onClick={() => setSharePopoverOpen(true)}>
                    Share chart
                </Button>
                {chartActions}
            </>
        );
    }

    function renderChartInsight() {
        if (!equivalentPredefinedChartType) {
            return null;
        }

        return (
            <ChartInsight
                className={classes.chartInsight}
                data={chartData}
                chartType={equivalentPredefinedChartType}
                analysisType={analysisType}/>
        );
    }

    function renderChart() {
        if (analysisSubject === undefined) {
            return (
                <EmptyState
                    analysisSubject={analysisSubject}
                    filters={filters}
                    onRemoveAllFilters={() => onFiltersChange([])}/>
            );
        }

        if (isLoading) {
            return <Loading className={classes.analyticsLoading}/>;
        }

        if (analysisData === null || chartData === null) {
            return null;
        }

        if (chartData.length === 0) {
            return (
                <EmptyState
                    analysisSubject={analysisSubject}
                    filters={filters}
                    onRemoveAllFilters={() => onFiltersChange([])}/>
            );
        }

        const legendItems = getLegendItems(chartData, projectMap, allTags, analysisType, analysisSubject, breakdownType);

        if (analysisType === AnalysisType.DataOverTime) {
            if (analysisSubject === AnalysisSubject.Tags && legendItems.length > 1) {
                if (chartData.length === 1) {
                    const onlyData = chartData[0];

                    if (onlyData.values.length === 1) {
                        return (
                            <SingleDataLineChart
                                key={dataCacheKey}
                                data={onlyData}
                                tagMap={tagMap}
                                margin={{ top: 5, right: 48, bottom: 48, left: 100 }}
                                displayLegend/>
                        );
                    } else if (onlyData.values.length === 2) {
                        return (
                            <SingleDataAreaChart
                                key={dataCacheKey}
                                margin={{ top: 5, right: 48, bottom: 48, left: 112 }}
                                data={onlyData}
                                displayLegend/>
                        );
                    }
                }

                return (
                    <AreaChart
                        key={dataCacheKey}
                        data={insertDuplicateData(chartData)}
                        legendItems={legendItems}
                        displayLegend margin={{ top: 5, right: 48, bottom: 48, left: 112 }}/>
                );
            }

            if (chartData.length === 1) {
                return (
                    <SingleDataLineChart
                        key={dataCacheKey}
                        margin={{ top: 5, right: 48, bottom: 48, left: 100 }}
                        className={classes.dashboardLineChart}
                        tagMap={tagMap}
                        data={chartData[0]}/>
                );
            }


            return (
                <LineChart
                    key={dataCacheKey}
                    data={chartData}
                    tagMap={tagMap}
                    axisBottomItemCountHint={10}
                    margin={{ top: 5, right: 48, bottom: 48, left: 100 }}
                    displayLegend/>
            );
        }

        let chartMode;
        if (!breakdownType) {
            chartMode = ChartMode.Absolute;
        } else if (analysisSubject === AnalysisSubject.Components && breakdownType === BreakdownType.ProjectUsedIn) {
            chartMode = ChartMode.Unique;
        } else {
            chartMode = ChartMode.Percentage;
        }

        return (
            <BarChart
                key={dataCacheKey}
                mode={chartMode}
                data={chartData}
                tagMap={tagMap}
                legendItems={legendItems}
                hasBreakdown={breakdownType !== undefined}
                displayLegend={breakdownType !== undefined || analysisSubject === AnalysisSubject.Components}
                displayGrid={chartMode === ChartMode.Percentage}
                linksDisabled={accessLevel === AccessLevel.Page}
                displayTooltip/>
        );
    }

    return (
        <main className={classes.analytics}>
            <Filters
                backURL={getDashboardURL()}
                analysisType={analysisType}
                analysisSubject={analysisSubject}
                filters={filters}
                breakdownType={breakdownType}
                disabled={accessLevel === AccessLevel.Page}
                onAnalysisTypeChange={onAnalysisTypeChange}
                onAnalysisSubjectChange={onAnalysisSubjectChange}
                onFiltersChange={onFiltersChange}
                onBreakdownTypeChange={onBreakdownTypeChange}/>
            <div className={classes.result}>
                <div className={classes.chart}>
                    <div className={classes.chartHeader}>
                        <div className={classes.chartInfoAndActions}>
                            <div className={classes.chartInfo}>
                                <div className={classes.chartTitle}>{title}</div>
                                <div className={classes.chartDescription}>{description}</div>
                            </div>
                            <div className={classes.chartActions}>
                                {renderChartActions()}
                            </div>
                        </div>
                        {renderChartInsight()}
                    </div>
                    <div className={classes.chartContent}>
                        {renderChart()}
                    </div>
                </div>
            </div>
            {sharePopoverOpen && (
                <SharePopover
                    anchor={shareButtonRef.current!}
                    name={titleText}
                    pageType={PageType.Analysis}
                    onClose={() => setSharePopoverOpen(false)}/>
            )}
        </main>
    );
}
