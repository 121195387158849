import { type ReactNode } from "react";

import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";

import classes from "./Tabs.module.css";

interface Props {
    items: {
        key: string;
        label: ReactNode;
        content: ReactNode;
    }[];
    onClick?: (key: string) => void;
}
export function Tabs({ items, onClick }: Props) {
    const { hash, search } = useLocation();
    const activeKey = hash.slice(1);
    const activeItem = items.find(({ key }) => key === activeKey) ?? items[0];
    return (
        <div className={classes.tabs}>
            <nav className={classes.nav}>
                {items.map(({ key, label }) => (
                    <Link
                        key={key}
                        to={{ search, hash: `#${key}` }}
                        className={classNames(classes.link, { [classes.active]: key === activeItem.key })}
                        onClick={() => onClick?.(key)}>
                        {label}
                    </Link>
                ))}
                {items.map(({ key }) => <div key={key} className={classNames(classes.bar, { [classes.active]: key === activeItem.key })} />)}
                <div className={classes.mainBar} />
            </nav>
            {activeItem.content}
        </div>
    );
}
