import { type ReactElement } from "react";

export enum EggType {
    Default_1,
    Default_2,
    Default_3,
    Default_4,
    Default_5,
    Cracked_1,
    Cracked_2,
    Cracked_3,
    Cracked_4,
    Cracked_5,
    Easter_1,
    Easter_2,
    Easter_3,
    Easter_4,
    Easter_5,
    Easter_6,
    Easter_7,
    Easter_8,
    Easter_9,

}

interface Props {
    type: EggType;
}

const EGGS: Record<EggType, ReactElement> = {
    [EggType.Default_1]: (
        <svg width="18" height="24" viewBox="0 0 18 24" fill="none">
            <path d="M18 14.004c0 5.66-3.556 9.994-9 9.994-5.445 0-9-4.335-9-9.994C0 7.677 3.511 0 9 0c5.488 0 9 7.677 9 14.004z" fill="#F0C9A3"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M3.277 21.077c5.962 1.204 10.749-2.746 11.915-8.93.49-2.592.477-5.501-.047-8.19C16.967 6.744 18 10.573 18 14.005c0 5.66-3.556 9.994-9 9.994-2.883 0-5.236-1.215-6.812-3.199.351.11.714.202 1.09.278z" fill="#B37752" fillOpacity=".1"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M5.825 2.603c-.865.664.216 1.188 1.114.567a7.778 7.778 0 0 1 2.046-1.023c.413-.133.447-.686.013-.686-1.17 0-2.236.422-3.173 1.142z" fill="#fff" fillOpacity=".3"/>
        </svg>
    ),
    [EggType.Default_2]: (
        <svg width="18" height="24" viewBox="0 0 18 24" fill="none">
            <path d="M18 14.004c0 5.66-3.556 9.994-9 9.994-5.445 0-9-4.335-9-9.994C0 7.677 3.511 0 9 0c5.488 0 9 7.677 9 14.004z" fill="#F0C9A3"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M4.968 23.075c5.161.215 9.171-3.549 10.225-9.136.671-3.556.398-7.709-.843-11.068C16.663 5.682 18 10.101 18 14.004c0 5.66-3.556 9.994-9 9.994-1.499 0-2.854-.328-4.032-.923z" fill="#B37752" fillOpacity=".1"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M4.486 6.266c.814-1.492.073-2.626-.814-1.175-1.461 2.392-2.287 5.605-2.287 8.493 0 .507.579.486.66-.015.421-2.572 1.256-5.132 2.44-7.303z" fill="#fff" fillOpacity=".3"/>
        </svg>
    ),
    [EggType.Default_3]: (
        <svg width="18" height="24" viewBox="0 0 18 24" fill="none">
            <path d="M17.999 14.005c0 5.66-3.556 9.994-9 9.993-5.444 0-8.999-4.335-8.998-9.994C0 7.677 3.513 0 9 .001c5.488 0 8.998 7.677 8.998 14.005z" fill="#F0C9A3"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M4.124 21.72c5.962 1.205 10.748-2.744 11.915-8.928.413-2.19.469-4.608.16-6.926 1.16 2.491 1.8 5.438 1.8 8.14 0 5.659-3.556 9.993-9 9.993-2.628 0-4.817-1.011-6.377-2.694.477.17.978.31 1.502.415z" fill="#B37752" fillOpacity=".1"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M9.053 1.643c.207-.095.173-.395-.055-.395-4.644 0-7.615 6.646-7.615 12.124 0 .207.266.219.306.016C2.695 8.276 5.317 3.366 9.053 1.643z" fill="#fff" fillOpacity=".3"/>
        </svg>
    ),
    [EggType.Default_4]: (
        <svg width="18" height="24" viewBox="0 0 18 24" fill="none">
            <path d="M17.999 14.004c0 5.66-3.554 9.994-8.998 9.995-5.444 0-9-4.334-9-9.993C.001 7.678 3.511.001 9 .001c5.487 0 9 7.676 9 14.003z" fill="#F0C9A3"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M7.45 23.875c3.685-1.043 6.406-4.34 7.247-8.797.889-4.717.118-10.481-2.37-14.054C15.868 3.322 18 9.072 18 14.004c0 5.658-3.555 9.993-8.998 9.994a9.642 9.642 0 0 1-1.553-.123z" fill="#B37752" fillOpacity=".1"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M3.42 8.153c.284-1.085-.565-1.591-.953-.54a17.65 17.65 0 0 0-1.081 5.971c0 .586.043 1.156.127 1.704.168 1.102 1.17.653 1.126-.46a16.313 16.313 0 0 1-.013-.65c0-1.962.275-4.032.795-6.025z" fill="#fff" fillOpacity=".3"/>
        </svg>
    ),
    [EggType.Default_5]: (
        <svg width="18" height="24" viewBox="0 0 18 24" fill="none">
            <path d="M18 14.004c0 5.66-3.556 9.994-9 9.994-5.445 0-9-4.335-9-9.994C0 7.677 3.511 0 9 0c5.488 0 9 7.677 9 14.004z" fill="#F0C9A3"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M4.967 23.075c5.162.215 9.172-3.549 10.226-9.136.67-3.556.398-7.708-.843-11.068C16.663 5.683 18 10.101 18 14.004c0 5.66-3.556 9.994-9 9.994-1.5 0-2.855-.329-4.033-.923z" fill="#B37752" fillOpacity=".1"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M4.486 6.264c.814-1.491.073-2.624-.812-1.174-1.463 2.392-2.289 5.605-2.289 8.494 0 .506.578.484.66-.015.42-2.573 1.256-5.134 2.441-7.305z" fill="#fff" fillOpacity=".3"/>
        </svg>
    ),
    [EggType.Cracked_1]: (
        <svg width="18" height="24" viewBox="0 0 18 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="m.967 8 1.449 1.5 3.383-1 1.932 1 4.35-1.5 2.899 2.5 2.452-1.088A19.61 19.61 0 0 1 18 14.004c0 5.66-3.556 9.994-9 9.994-5.445 0-9-4.335-9-9.994C0 12.043.337 9.952.967 8z" fill="#F0C9A3"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M15.453 10.29c-.06.632-.146 1.254-.26 1.858-1.167 6.183-5.954 10.133-11.915 8.929a11.085 11.085 0 0 1-1.09-.278c1.576 1.984 3.93 3.199 6.812 3.199 5.444 0 9-4.335 9-9.994a19.61 19.61 0 0 0-.568-4.592l-1.98.878z" fill="#B37752" fillOpacity=".1"/>
        </svg>
    ),
    [EggType.Cracked_2]: (
        <svg width="18" height="24" viewBox="0 0 18 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M2.827 4H4.55l1.45 3 1.933-1.5 1.45 4 1.932-2 2.416 3 3.07-3.176c.777 2.139 1.198 4.49 1.198 6.68 0 5.66-3.556 9.994-9 9.994-5.445 0-9-4.335-9-9.994C0 10.59 1.023 6.782 2.827 4z" fill="#F0C9A3"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M4.486 6.266c.814-1.492.073-2.626-.814-1.175-1.461 2.392-2.287 5.605-2.287 8.493 0 .507.579.485.66-.015.421-2.572 1.256-5.132 2.44-7.303z" fill="#fff" fillOpacity=".3"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M15.51 8.66a21.859 21.859 0 0 1-.317 5.279c-1.054 5.587-5.064 9.35-10.225 9.136 1.178.594 2.533.923 4.032.923 5.444 0 9-4.335 9-9.994 0-2.19-.42-4.541-1.198-6.68L15.51 8.66z" fill="#B37752" fillOpacity=".1"/>
        </svg>
    ),
    [EggType.Cracked_3]: (
        <svg width="18" height="24" viewBox="0 0 18 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M.347 10.434 4.267 8l2.899 3 2.9-1.5 2.898 3 1.45-4.5 3.293 2.726c.193 1.102.293 2.21.293 3.28 0 5.659-3.556 9.993-9 9.992-5.445 0-9-4.335-9-9.994 0-1.163.119-2.372.347-3.57z" fill="#F0C9A3"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M16.384 9.63a20.992 20.992 0 0 1-.344 3.162c-1.167 6.184-5.954 10.133-11.915 8.928a10.924 10.924 0 0 1-1.502-.415C4.183 22.988 6.37 23.998 9 24c5.445 0 9-4.334 9-9.993 0-1.07-.1-2.177-.293-3.28L16.384 9.63z" fill="#B37752" fillOpacity=".1"/>
        </svg>
    ),
    [EggType.Cracked_4]: (
        <svg width="18" height="24" viewBox="0 0 18 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M3.433 3.144 4.467 4l.966 2H7.85l.483 4.5L11.715 8l2.416 2.5.966-4 1.728.894C17.587 9.514 18 11.84 18 14.005 17.999 19.665 14.444 24 9 24c-5.444 0-9-4.333-9-9.992 0-3.781 1.253-8.044 3.433-10.863z" fill="#F0C9A3"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M14.797 7.742c.373 2.442.337 5.016-.1 7.337-.84 4.457-3.562 7.753-7.248 8.796A9.632 9.632 0 0 0 9 23.998c5.445 0 9-4.335 8.999-9.994 0-2.165-.412-4.49-1.173-6.609l-1.73-.895-.3 1.242z" fill="#B37752" fillOpacity=".1"/>
        </svg>
    ),
    [EggType.Cracked_5]: (
        <svg width="18" height="24" viewBox="0 0 18 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M.002 13.757 2.735 12.5l2.9 2 3.382-4 2.416 2.5 2.416-5 2.416 2.5 1.087-1.406a19.7 19.7 0 0 1 .648 4.91c0 5.66-3.556 9.994-9 9.994-5.445 0-9-4.335-9-9.994 0-.082 0-.164.002-.247z" fill="#F0C9A3"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M15.547 9.758a21.46 21.46 0 0 1-.355 4.181c-1.054 5.587-5.064 9.35-10.225 9.136 1.177.595 2.533.923 4.032.923 5.444 0 9-4.335 9-9.994 0-1.598-.224-3.283-.648-4.909L16.265 10.5l-.718-.742z" fill="#B37752" fillOpacity=".1"/>
        </svg>
    ),
    [EggType.Easter_1]: (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none">
            <rect width="28" height="28" rx="2" fill="#fff"/>
            <rect width="28" height="28" rx="2" fill="#FFF0A2"/>
            <path d="M22 15.671C22 20.388 18.84 24 14 24s-8-3.612-8-8.329C6 10.398 9.121 4 14 4s8 6.398 8 11.671z" fill="#8574ED"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M8.913 21.566c5.3 1.003 9.554-2.288 10.591-7.442.435-2.16.424-4.586-.041-6.826C21.08 9.62 22 12.811 22 15.671 22 20.387 18.84 24 14 24c-2.563 0-4.654-1.013-6.055-2.666.312.091.635.169.968.232z" fill="#B37752" fillOpacity=".1"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M21.32 11.222c.2.657.357 1.33.47 2.001h-.001a4 4 0 0 1-3.578 0l-1.317-.658a2 2 0 0 0-1.788 0l-1.317.659a4 4 0 0 1-3.578 0l-1.317-.659a2 2 0 0 0-1.788 0l-.855.428c.154-.842.378-1.681.665-2.49a4 4 0 0 1 2.873.273l1.316.659a2 2 0 0 0 1.79 0l1.316-.659a4 4 0 0 1 3.578 0l1.316.659a2 2 0 0 0 1.79 0l.425-.213zm-.089 8.23c.345-.777.58-1.627.693-2.532l-1.03.515a2 2 0 0 1-1.788 0l-1.317-.659a4 4 0 0 0-3.578 0l-1.317.659a2 2 0 0 1-1.789 0l-1.316-.659a4 4 0 0 0-3.578 0l-.143.072c.082.704.238 1.375.462 2.005l.576-.288a2 2 0 0 1 1.788 0l1.317.659a4 4 0 0 0 3.578 0l1.316-.659a2 2 0 0 1 1.79 0l1.316.659a4 4 0 0 0 3.02.228z" fill="#FF7F7F"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M10.854 6.17c-.72.553.18.99.929.472a6.482 6.482 0 0 1 1.705-.853c.344-.11.373-.572.011-.572-.976 0-1.863.352-2.645.952z" fill="#fff" fillOpacity=".3"/>
        </svg>
    ),
    [EggType.Easter_2]: (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none">
            <rect width="28" height="28" rx="2" fill="#fff"/>
            <rect width="28" height="28" rx="2" fill="#F0DCFF"/>
            <path d="M22 15.671C22 20.388 18.84 24 14 24s-8-3.612-8-8.329C6 10.398 9.121 4 14 4s8 6.398 8 11.671z" fill="#EE6363"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M8.913 21.566c5.3 1.003 9.554-2.288 10.591-7.442.435-2.16.424-4.586-.041-6.826C21.08 9.62 22 12.811 22 15.671 22 20.387 18.84 24 14 24c-2.563 0-4.654-1.013-6.055-2.666.312.091.635.169.968.232z" fill="#B37752" fillOpacity=".1"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M21.32 11.222c.2.657.357 1.33.47 2.001h-.001a4 4 0 0 1-3.578 0l-1.317-.658a2 2 0 0 0-1.788 0l-1.317.659a4 4 0 0 1-3.578 0l-1.317-.659a2 2 0 0 0-1.788 0l-.855.428c.154-.842.378-1.681.665-2.49a4 4 0 0 1 2.873.273l1.316.659a2 2 0 0 0 1.79 0l1.316-.659a4 4 0 0 1 3.578 0l1.316.659a2 2 0 0 0 1.79 0l.425-.213zm-.089 8.23c.345-.777.58-1.627.693-2.532l-1.03.515a2 2 0 0 1-1.788 0l-1.317-.659a4 4 0 0 0-3.578 0l-1.317.659a2 2 0 0 1-1.789 0l-1.316-.659a4 4 0 0 0-3.578 0l-.143.072c.082.704.238 1.375.462 2.005l.576-.288a2 2 0 0 1 1.788 0l1.317.659a4 4 0 0 0 3.578 0l1.316-.659a2 2 0 0 1 1.79 0l1.316.659a4 4 0 0 0 3.02.228z" fill="#FFC738"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M10.854 6.17c-.72.553.18.99.929.472a6.482 6.482 0 0 1 1.705-.853c.344-.11.373-.572.011-.572-.976 0-1.863.352-2.645.952z" fill="#fff" fillOpacity=".3"/>
        </svg>
    ),
    [EggType.Easter_3]: (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none">
            <rect width="28" height="28" rx="2" fill="#fff"/>
            <rect width="28" height="28" rx="2" fill="#E7FFB4"/>
            <path d="M22 15.671C22 20.388 18.84 24 14 24s-8-3.612-8-8.329C6 10.398 9.121 4 14 4s8 6.398 8 11.671z" fill="#9F765E"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M8.913 21.566c5.3 1.003 9.554-2.288 10.591-7.442.435-2.16.424-4.586-.041-6.826C21.08 9.62 22 12.811 22 15.671 22 20.387 18.84 24 14 24c-2.563 0-4.654-1.013-6.055-2.666.312.091.635.169.968.232z" fill="#B37752" fillOpacity=".1"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M21.32 11.222c.2.657.357 1.33.47 2.001h-.001a4 4 0 0 1-3.578 0l-1.317-.658a2 2 0 0 0-1.788 0l-1.317.659a4 4 0 0 1-3.578 0l-1.317-.659a2 2 0 0 0-1.788 0l-.855.428c.154-.842.378-1.681.665-2.49a4 4 0 0 1 2.873.273l1.316.659a2 2 0 0 0 1.79 0l1.316-.659a4 4 0 0 1 3.578 0l1.316.659a2 2 0 0 0 1.79 0l.425-.213zm-.089 8.23c.345-.777.58-1.627.693-2.532l-1.03.515a2 2 0 0 1-1.788 0l-1.317-.659a4 4 0 0 0-3.578 0l-1.317.659a2 2 0 0 1-1.789 0l-1.316-.659a4 4 0 0 0-3.578 0l-.143.072c.082.704.238 1.375.462 2.005l.576-.288a2 2 0 0 1 1.788 0l1.317.659a4 4 0 0 0 3.578 0l1.316-.659a2 2 0 0 1 1.79 0l1.316.659a4 4 0 0 0 3.02.228z" fill="#27BEB6"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M10.854 6.17c-.72.553.18.99.929.472a6.482 6.482 0 0 1 1.705-.853c.344-.11.373-.572.011-.572-.976 0-1.863.352-2.645.952z" fill="#fff" fillOpacity=".3"/>
        </svg>
    ),
    [EggType.Easter_4]: (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none">
            <rect width="28" height="28" rx="2" fill="#fff"/>
            <rect width="28" height="28" rx="2" fill="#FFF0A1"/>
            <path d="M22 15.671C22 20.388 18.84 24 14 24s-8-3.612-8-8.329C6 10.398 9.121 4 14 4s8 6.398 8 11.671z" fill="#8574ED"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M8.913 21.566c5.3 1.003 9.554-2.288 10.591-7.442.435-2.16.424-4.586-.041-6.826C21.08 9.62 22 12.811 22 15.671 22 20.387 18.84 24 14 24c-2.563 0-4.654-1.013-6.055-2.666.312.091.635.169.968.232z" fill="#B37752" fillOpacity=".1"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M10.854 6.17c-.72.553.18.99.929.472a6.482 6.482 0 0 1 1.705-.853c.344-.11.373-.572.011-.572-.976 0-1.863.352-2.645.952z" fill="#fff" fillOpacity=".3"/>
            <circle cx="10.5" cy="10.5" r="1.5" fill="#fff"/>
            <circle cx="15.5" cy="7.5" r="1.5" fill="#fff"/>
            <circle cx="8.5" cy="16.5" r="1.5" fill="#fff"/>
            <circle cx="15.5" cy="13.5" r="1.5" fill="#fff"/>
            <circle cx="14.5" cy="19.5" r="1.5" fill="#fff"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M21.257 11.02c.304.956.52 1.947.637 2.928A1.503 1.503 0 0 1 20 12.5a1.5 1.5 0 0 1 1.257-1.48zM17 23.477c.828-.31 1.57-.75 2.212-1.298A1.5 1.5 0 0 0 17 23.478zm-6.277-.11a1.5 1.5 0 1 0-2.474-1.696 7.35 7.35 0 0 0 2.474 1.696z" fill="#fff"/>
            <circle cx="19.5" cy="17.5" r="1.5" fill="#fff"/>
        </svg>
    ),
    [EggType.Easter_5]: (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none">
            <rect width="28" height="28" rx="2" fill="#fff"/>
            <rect width="28" height="28" rx="2" fill="#F0DCFF"/>
            <path d="M22 15.671C22 20.388 18.84 24 14 24s-8-3.612-8-8.329C6 10.398 9.121 4 14 4s8 6.398 8 11.671z" fill="#EE6363"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M8.913 21.566c5.3 1.003 9.554-2.288 10.591-7.442.435-2.16.424-4.586-.041-6.826C21.08 9.62 22 12.811 22 15.671 22 20.387 18.84 24 14 24c-2.563 0-4.654-1.013-6.055-2.666.312.091.635.169.968.232z" fill="#B37752" fillOpacity=".1"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M10.854 6.17c-.72.553.18.99.929.472a6.482 6.482 0 0 1 1.705-.853c.344-.11.373-.572.011-.572-.976 0-1.863.352-2.645.952z" fill="#fff" fillOpacity=".3"/>
            <circle cx="10.5" cy="10.5" r="1.5" fill="#fff"/>
            <circle cx="15.5" cy="7.5" r="1.5" fill="#fff"/>
            <circle cx="8.5" cy="16.5" r="1.5" fill="#fff"/>
            <circle cx="15.5" cy="13.5" r="1.5" fill="#fff"/>
            <circle cx="14.5" cy="19.5" r="1.5" fill="#fff"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M21.257 11.02c.304.956.52 1.947.637 2.928A1.503 1.503 0 0 1 20 12.5a1.5 1.5 0 0 1 1.257-1.48zM17 23.477c.828-.31 1.57-.75 2.212-1.298A1.5 1.5 0 0 0 17 23.478zm-6.277-.11a1.5 1.5 0 1 0-2.474-1.696 7.35 7.35 0 0 0 2.474 1.696z" fill="#fff"/>
            <circle cx="19.5" cy="17.5" r="1.5" fill="#fff"/>
        </svg>
    ),
    [EggType.Easter_6]: (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none">
            <rect width="28" height="28" rx="2" fill="#fff"/>
            <rect width="28" height="28" rx="2" fill="#E7FFB4"/>
            <path d="M22 15.671C22 20.388 18.84 24 14 24s-8-3.612-8-8.329C6 10.398 9.121 4 14 4s8 6.398 8 11.671z" fill="#9F765E"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M8.913 21.566c5.3 1.003 9.554-2.288 10.591-7.442.435-2.16.424-4.586-.041-6.826C21.08 9.62 22 12.811 22 15.671 22 20.387 18.84 24 14 24c-2.563 0-4.654-1.013-6.055-2.666.312.091.635.169.968.232z" fill="#B37752" fillOpacity=".1"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M10.854 6.17c-.72.553.18.99.929.472a6.482 6.482 0 0 1 1.705-.853c.344-.11.373-.572.011-.572-.976 0-1.863.352-2.645.952z" fill="#fff" fillOpacity=".3"/>
            <circle cx="10.5" cy="10.5" r="1.5" fill="#fff"/>
            <circle cx="15.5" cy="7.5" r="1.5" fill="#fff"/>
            <circle cx="8.5" cy="16.5" r="1.5" fill="#fff"/>
            <circle cx="15.5" cy="13.5" r="1.5" fill="#fff"/>
            <circle cx="14.5" cy="19.5" r="1.5" fill="#fff"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M21.257 11.02c.304.956.52 1.947.637 2.928A1.503 1.503 0 0 1 20 12.5a1.5 1.5 0 0 1 1.257-1.48zM17 23.477c.828-.31 1.57-.75 2.212-1.298A1.5 1.5 0 0 0 17 23.478zm-6.277-.11a1.5 1.5 0 1 0-2.474-1.696 7.35 7.35 0 0 0 2.474 1.696z" fill="#fff"/>
            <circle cx="19.5" cy="17.5" r="1.5" fill="#fff"/>
        </svg>
    ),
    [EggType.Easter_7]: (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none">
            <rect width="28" height="28" rx="2" fill="#fff"/>
            <rect width="28" height="28" rx="2" fill="#FFF0A1"/>
            <path d="M22 15.671C22 20.388 18.84 24 14 24s-8-3.612-8-8.329C6 10.398 9.121 4 14 4s8 6.398 8 11.671z" fill="#8574ED"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M8.913 21.566c5.3 1.003 9.554-2.288 10.591-7.442.435-2.16.424-4.586-.041-6.826C21.08 9.62 22 12.811 22 15.671 22 20.387 18.84 24 14 24c-2.563 0-4.654-1.013-6.055-2.666.312.091.635.169.968.232z" fill="#B37752" fillOpacity=".1"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M10.854 6.17c-.72.553.18.99.929.472a6.482 6.482 0 0 1 1.705-.853c.344-.11.373-.572.011-.572-.976 0-1.863.352-2.645.952z" fill="#fff" fillOpacity=".3"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M21.759 13.048c.085.471.148.944.188 1.412a2 2 0 1 1-.188-1.412zM6.053 14.459c.04-.468.103-.94.187-1.41a2 2 0 1 1-.187 1.41zM14 16a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" fill="#FF7F7F"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M19.915 8c.374.625.701 1.298.98 2H7.104c.279-.702.606-1.375.98-2h11.83zm1.047 12a8.486 8.486 0 0 0 .764-2H6.274c.176.714.432 1.384.764 2h13.924z" fill="#FDBD39"/>
        </svg>
    ),
    [EggType.Easter_8]: (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none">
            <rect width="28" height="28" rx="2" fill="#fff"/>
            <rect width="28" height="28" rx="2" fill="#E8CBFF" fillOpacity=".67"/>
            <path d="M22 15.671C22 20.388 18.84 24 14 24s-8-3.612-8-8.329C6 10.398 9.121 4 14 4s8 6.398 8 11.671z" fill="#EE6363"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M8.913 21.566c5.3 1.003 9.554-2.288 10.591-7.442.435-2.16.424-4.586-.041-6.826C21.08 9.62 22 12.811 22 15.671 22 20.387 18.84 24 14 24c-2.563 0-4.654-1.013-6.055-2.666.312.091.635.169.968.232z" fill="#B37752" fillOpacity=".1"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M10.854 6.17c-.72.553.18.99.929.472a6.482 6.482 0 0 1 1.705-.853c.344-.11.373-.572.011-.572-.976 0-1.863.352-2.645.952z" fill="#fff" fillOpacity=".3"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M21.759 13.048c.085.471.148.944.188 1.412a2 2 0 1 1-.188-1.412zM6.053 14.459c.04-.468.103-.94.187-1.41a2 2 0 1 1-.187 1.41zM14 16a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" fill="#fff"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M19.915 8c.374.625.701 1.298.98 2H7.104c.279-.702.606-1.375.98-2h11.83zm1.047 12a8.486 8.486 0 0 0 .764-2H6.274c.176.714.432 1.384.764 2h13.924z" fill="#4CCF6D"/>
        </svg>
    ),
    [EggType.Easter_9]: (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none">
            <rect width="28" height="28" rx="2" fill="#fff"/>
            <rect width="28" height="28" rx="2" fill="#E7FFB4"/>
            <path d="M22 15.671C22 20.388 18.84 24 14 24s-8-3.612-8-8.329C6 10.398 9.121 4 14 4s8 6.398 8 11.671z" fill="#9F765E"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M8.913 21.566c5.3 1.003 9.554-2.288 10.591-7.442.435-2.16.424-4.586-.041-6.826C21.08 9.62 22 12.811 22 15.671 22 20.387 18.84 24 14 24c-2.563 0-4.654-1.013-6.055-2.666.312.091.635.169.968.232z" fill="#B37752" fillOpacity=".1"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M10.854 6.17c-.72.553.18.99.929.472a6.482 6.482 0 0 1 1.705-.853c.344-.11.373-.572.011-.572-.976 0-1.863.352-2.645.952z" fill="#fff" fillOpacity=".3"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M21.759 13.048c.085.471.148.944.188 1.412a2 2 0 1 1-.188-1.412zM6.053 14.459c.04-.468.103-.94.187-1.41a2 2 0 1 1-.187 1.41zM14 16a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" fill="#27BEB6"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M19.915 8c.374.625.701 1.298.98 2H7.104c.279-.702.606-1.375.98-2h11.83zm1.047 12a8.486 8.486 0 0 0 .764-2H6.274c.176.714.432 1.384.764 2h13.924z" fill="#FF7F7F"/>
        </svg>
    ),
};

export function Egg({ type }: Props) {
    return EGGS[type];
}

