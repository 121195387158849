import { useMemo, useState } from "react";

import { config } from "../../../../config/frontend";
import { Button, ButtonAnchor, ButtonKind } from "../../../library/Button/Button";
import { Callout } from "../../../library/Callout/Callout";
import { H2, H3 } from "../../../library/Heading/Heading";
import { IconChevronRight } from "../../../library/icons/IconChevronRight";
import { IconComponents } from "../../../library/icons/IconComponents";
import { IconProfile } from "../../../library/icons/IconProfile";
import { IconTag } from "../../../library/icons/IconTag";
import { Pill } from "../../../library/Pill/Pill";
import { Switch } from "../../../library/Switch/Switch";
import { type Price } from "../../../models/Price";
import { PriceKey } from "../../../models/PriceKey";
import { type Product } from "../../../models/Product";
import { type Subscription } from "../../../models/Subscription";
import { SubscriptionPeriod } from "../../../models/SubscriptionPeriod";
import { SubscriptionPlan, subscriptionPlanToString } from "../../../models/SubscriptionPlan";
import { SubscriptionStatus } from "../../../models/SubscriptionStatus";
import { RESERVED_TAGS } from "../../../models/Tag";
import { type Workspace } from "../../../models/Workspace";
import { useStore } from "../../../providers/StoreProvider/StoreProvider";
import {
    trackContactUsLinkClick,
    trackPricingTableNumOfComponentsChange,
    trackSubscriptionDowngradeClick,
    trackSubscriptionUpgradeClick,
} from "../../../tracking";
import { formatCurrency, formatNumber, getDaysBetween, pluralize } from "../../../utils";

import { Dropdown } from "./Dropdown/Dropdown";

import classes from "./PricingTable.module.css";

function calculateAmount(numOfAddons: number, basePrice: Price, addonPrice: Price) {
    return basePrice.monthlyAmount + numOfAddons * addonPrice.monthlyAmount;
}

function calculateNumOfComponents(numOfAddons: number, basePrice: Price, addonPrice: Price) {
    return basePrice.product.numOfComponents + numOfAddons * addonPrice.product.numOfComponents;
}

interface CurrentPlanPillProps {
    isTrial: boolean;
}

function CurrentPlanPill({ isTrial }: CurrentPlanPillProps) {
    return (
        <Pill>
            {isTrial ? "Current trial" : "Current plan"}
        </Pill>
    );
}

interface DescriptionProps {
    subscription: Subscription;
}

function Description({ subscription }: DescriptionProps) {
    if (subscription.status === SubscriptionStatus.Trial && subscription.trial) {
        const trialDuration = getDaysBetween(subscription.trial.startedAt, subscription.trial.endedAt);
        return (
            <p>
                Your workspace is currently on a
                {" "}
                <strong>{trialDuration}-day trial of the {subscriptionPlanToString(subscription.plan)} Plan</strong>
                {" "}
                — see our plans below and upgrade when you’re ready.
            </p>
        );
    }

    return (
        <p>
            Your workspace is currently on the
            {" "}
            <strong>{subscriptionPlanToString(subscription.plan)} Plan</strong>
            {" "}
            — see our plans below and upgrade when you’re ready.
        </p>
    );
}

interface FreePlanCTAProps {
    subscriptionStatus: SubscriptionStatus;
    plan: SubscriptionPlan;
}

function FreePlanCTA({ plan, subscriptionStatus }: FreePlanCTAProps) {
    if (subscriptionStatus === SubscriptionStatus.Trial) {
        return <Button type="submit" className={classes.cta} kind={ButtonKind.Secondary} disabled>On trial</Button>;
    }

    if (plan === SubscriptionPlan.Free) {
        return <Button type="submit" className={classes.cta} kind={ButtonKind.Secondary} disabled>Current plan</Button>;
    }

    return <Button type="submit" className={classes.cta} kind={ButtonKind.Secondary} onClick={trackSubscriptionDowngradeClick}>Downgrade</Button>;
}

interface IntroPlanPeriodRowProps {
    plan: SubscriptionPlan;
    isAnnual: boolean;
    onChange: (isAnnual: boolean) => void;
}

function IntroPlanPeriodRow({ plan, isAnnual, onChange }: IntroPlanPeriodRowProps) {
    if (plan !== SubscriptionPlan.Intro) {
        return (
            <label className={classes.subscriptionPeriodRow}>
                {!isAnnual && <input type="hidden" name="price_key" value="omlet_intro_monthly"/>}
                <Switch name="price_key" value="omlet_intro_annual" checked={isAnnual} onChange={onChange}/>
                <span>Billed yearly</span>
            </label>
        );
    }

    if (isAnnual) {
        return (
            <p className={classes.subscriptionPeriodRow}>
                Billed yearly
            </p>
        );
    }

    return (
        <p className={classes.subscriptionPeriodRow}>
            Billed monthly
        </p>
    );
}

function pluralizeFeatureGate(feature: string, value: number) {
    if (value === Number.MAX_SAFE_INTEGER) {
        return `Unlimited ${feature}`;
    }
    return pluralize(feature, value);
}

interface FeatureGatesProps {
    product: Product;
}

function FeatureGates({ product }: FeatureGatesProps) {
    return (
        <ul>
            <li>{pluralizeFeatureGate("user", product.numOfSeats)}</li>
            {product.analysisLimit === Number.MAX_SAFE_INTEGER
                ? <li>Unlimited scans</li>
                : <li>{pluralize("scan", product.analysisLimit ?? 0)} every 30 days</li>
            }
            <li>{pluralizeFeatureGate("tag", product.tagLimit ?? 0)}</li>
            {product.dataRetentionLimit === Number.MAX_SAFE_INTEGER
                ? <li>Unlimited scan retention</li>
                : <li>{product.dataRetentionLimit}-day scan retention</li>
            }
            {product.advancedProps
                ? <li>Advanced props and component usage</li>
                : <li>Basic props and component usage</li>
            }
            {product.customProperties && <li>Custom properties</li>}
            {product.savedCharts && <li>Save charts to dashboard</li>}
        </ul>
    );
}

interface OverLimitTextProps {
    usage: number;
    limit: number;
}

function OverLimitText({ usage, limit }: OverLimitTextProps) {
    if (usage <= limit) {
        return null;
    }
    return (
        <span className={classes.overLimit}>
            {" "}
            — over your plan’s limit
        </span>
    );
}


interface Props {
    workspace: Workspace;
    priceMap: Record<PriceKey, Price>;
}

export function PricingTable({
    workspace: {
        numOfMembers,
        tags,
        subscription,
    },
    workspace,
    priceMap: {
        [PriceKey.Free]: freePrice,
        [PriceKey.AdvancedAnnual]: advancedAnnualPrice,
        [PriceKey.IntroMonthly]: introMonthlyPrice,
        [PriceKey.IntroAnnual]: introAnnualPrice,
        [PriceKey.ThousandComponentsAddonAnnual]: thousandComponentsAddonAnnualPrice,
        [PriceKey.ThousandComponentsAddonMonthly]: thousandComponentsAddonMonthlyPrice,
    },
}: Props) {
    const { actions: { openBillingDialog } } = useStore();
    const [isAnnual, setIsAnnual] = useState(subscription.plan === SubscriptionPlan.Intro ? subscription.period === SubscriptionPeriod.Annual : true);
    const [numOfAddons, setNumOfAddons] = useState(subscription.plan === SubscriptionPlan.Intro ? subscription.features.additionalNumOfComponents / thousandComponentsAddonAnnualPrice.product.numOfComponents : 0);
    const numOfTags = useMemo(() => tags.filter(tag => tag.slug !== RESERVED_TAGS.EXTERNAL.slug).length, [tags]);

    function handleNumOfAddonsChange(newNumOfAddons: number) {
        setNumOfAddons(newNumOfAddons);
        trackPricingTableNumOfComponentsChange({
            numOfComponents: calculateNumOfComponents(
                newNumOfAddons,
                isAnnual ? introAnnualPrice : introMonthlyPrice,
                isAnnual ? thousandComponentsAddonAnnualPrice : thousandComponentsAddonMonthlyPrice
            ),
        });
    }

    function handleUpgradeClick() {
        trackSubscriptionUpgradeClick({
            newPlan: SubscriptionPlan.Intro,
            period: isAnnual ? SubscriptionPeriod.Annual : SubscriptionPeriod.Monthly,
            numOfComponents: calculateNumOfComponents(numOfAddons, introMonthlyPrice, thousandComponentsAddonMonthlyPrice),
        });
    }

    function handleContactUsClickInMoreComponentsCallout() {
        trackContactUsLinkClick({ source: "more_components_callout_in_pricing_table" });
    }

    function handleContactUsClickInAdvancedPlanCard() {
        trackContactUsLinkClick({ source: "advanced_plan_card_in_pricing_table" });
    }

    return (
        <div className={classes.pricingTable}>
            <nav className={classes.breadcrumbs}>
                <button className={classes.breadcrumb} type="button" onClick={openBillingDialog}>Billing</button>
                <IconChevronRight/>
                <H2 className={classes.h2}>Upgrade plan</H2>
            </nav>
            <Description subscription={subscription}/>
            <div className={classes.planCards}>
                <form className={classes.planCard} action="/billing" target="_blank">
                    <input type="hidden" name="workspace_slug" value={workspace.slug}/>
                    <div className={classes.planCardHeader}>
                        <H2>{subscriptionPlanToString(SubscriptionPlan.Free)}</H2>
                        {subscription.plan === SubscriptionPlan.Free && <CurrentPlanPill
                            isTrial={subscription.status === SubscriptionStatus.Trial}/>}
                    </div>
                    <p className={classes.description}>For individuals looking to get visibility
                        into component usage</p>
                    <p className={classes.componentCountRow}>Up to {formatNumber(freePrice.product.numOfComponents)} components</p>
                    <div className={classes.priceRow}>
                        <p>
                            <span className={classes.price}>{formatCurrency(0)}</span>
                            {" "}
                            per month
                        </p>
                    </div>
                    <div/>
                    <FreePlanCTA plan={subscription.plan} subscriptionStatus={subscription.status}/>
                    <FeatureGates product={freePrice.product}/>
                </form>
                <form className={classes.planCard} action="/billing/change-plan">
                    <input type="hidden" name="workspace_slug" value={workspace.slug}/>
                    <div className={classes.planCardHeader}>
                        <H2>{subscriptionPlanToString(SubscriptionPlan.Intro)}</H2>
                        {subscription.plan === SubscriptionPlan.Intro && <CurrentPlanPill isTrial={subscription.status === SubscriptionStatus.Trial}/>}
                    </div>
                    <p className={classes.description}>For teams looking to improve their design system</p>
                    <p className={classes.componentCountRow}>
                        <span>
                            Up to
                        </span>
                        <Dropdown
                            disabled={subscription.plan === SubscriptionPlan.Intro}
                            basePrice={isAnnual ? introAnnualPrice : introMonthlyPrice}
                            addonPrice={isAnnual ? thousandComponentsAddonAnnualPrice : thousandComponentsAddonMonthlyPrice}
                            numOfAddons={numOfAddons}
                            onChange={handleNumOfAddonsChange}/>
                        <span>
                            components
                        </span>
                    </p>
                    <div className={classes.priceRow}>
                        <p>
                            <span className={classes.price}>
                                {formatCurrency(subscription.plan === SubscriptionPlan.Intro
                                    ? subscription.monthlyAmount
                                    : calculateAmount(
                                        numOfAddons,
                                        isAnnual ? introAnnualPrice : introMonthlyPrice,
                                        isAnnual ? thousandComponentsAddonAnnualPrice : thousandComponentsAddonMonthlyPrice
                                    )
                                )}
                            </span>
                            {" "}
                            per month
                        </p>
                    </div>
                    <IntroPlanPeriodRow plan={subscription.plan} isAnnual={isAnnual} onChange={setIsAnnual} />
                    {subscription.plan === SubscriptionPlan.Intro
                        ? <Button type="submit" className={classes.cta} kind={ButtonKind.Secondary} disabled>Current plan</Button>
                        : <Button type="submit" className={classes.cta} onClick={handleUpgradeClick}>Upgrade</Button>
                    }
                    <div className={classes.featureGates}>
                        <FeatureGates product={introMonthlyPrice.product}/>
                        {subscription.plan === SubscriptionPlan.Intro && (
                            <Callout emoji="🌱">
                                Need more components on the Intro plan?
                                {" "}
                                <a
                                    href={`${config.LANDING_PAGE_BASE_URL}/contact/`}
                                    rel="nofollow external noopener noreferrer"
                                    target="_blank"
                                    onClick={handleContactUsClickInMoreComponentsCallout}>
                                    Contact us.
                                </a>
                            </Callout>
                        )}
                    </div>
                </form>
                <div className={classes.planCard}>
                    <div className={classes.planCardHeader}>
                        <H2>{subscriptionPlanToString(SubscriptionPlan.Advanced)}</H2>
                        {subscription.plan === SubscriptionPlan.Advanced && <CurrentPlanPill
                            isTrial={subscription.status === SubscriptionStatus.Trial}/>}
                    </div>
                    <p className={classes.description}>For mature design system organizations needing support, security, and control</p>
                    <p className={classes.componentCountRow}>Starting from {formatNumber(advancedAnnualPrice.product.numOfComponents)} components</p>
                    <div className={classes.priceRow}>
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd"
                                d="M0.666016 13.5C0.666016 6.3203 6.48631 0.5 13.666 0.5C20.8457 0.5 26.666 6.3203 26.666 13.5C26.666 20.6797 20.8457 26.5 13.666 26.5H3.91602C2.12109 26.5 0.666016 25.0449 0.666016 23.25V13.5ZM24.4393 13.4997C24.4393 19.4486 19.6168 24.2711 13.6679 24.2711C13.5343 24.2711 13.4013 24.2687 13.2688 24.2639V24.272H4.89633C3.79176 24.272 2.89633 23.3766 2.89633 22.272V13.1016H2.9037C3.11312 7.33716 7.85229 2.72828 13.6679 2.72828C19.6168 2.72828 24.4393 7.55081 24.4393 13.4997ZM20.3529 11.2708C20.3529 10.6554 19.854 10.1566 19.2386 10.1566H8.46718C7.85177 10.1566 7.35289 10.6554 7.35289 11.2708C7.35289 11.8863 7.85177 12.3851 8.46718 12.3851H19.2386C19.854 12.3851 20.3529 11.8863 20.3529 11.2708ZM14.9039 15.3566C15.5193 15.3566 16.0182 15.8554 16.0182 16.4708C16.0182 17.0863 15.5193 17.5851 14.9039 17.5851H8.46582C7.85042 17.5851 7.35154 17.0863 7.35154 16.4708C7.35154 15.8554 7.85042 15.3566 8.46582 15.3566H14.9039Z"
                                fill="currentColor"/>
                        </svg>
                    </div>
                    <p className={classes.subscriptionPeriodRow}>Starting from {formatCurrency(advancedAnnualPrice.amount / 1000)}K per year</p>
                    <ButtonAnchor
                        className={classes.cta}
                        kind={subscription.plan === SubscriptionPlan.Intro ? ButtonKind.Primary : ButtonKind.Secondary}
                        href={`${config.LANDING_PAGE_BASE_URL}/contact/`}
                        rel="nofollow external noopener noreferrer"
                        target="_blank"
                        onClick={handleContactUsClickInAdvancedPlanCard}>
                        {subscription.plan === SubscriptionPlan.Intro ? "Contact us to upgrade" : "Contact us"}
                    </ButtonAnchor>
                    <FeatureGates product={advancedAnnualPrice.product}/>
                </div>
            </div>
            <section className={classes.usageInfo}>
                <H3>Your current Omlet usage</H3>
                <p className={classes.row}>
                    <IconComponents/>
                    <span>
                        {pluralize("component", workspace.numOfComponents)} scanned
                        {subscription.status !== SubscriptionStatus.Trial && <OverLimitText usage={workspace.numOfComponents} limit={subscription.features.numOfComponents} />}
                    </span>
                </p>
                <p className={classes.row}>
                    <IconTag/>
                    <span>
                        {pluralize("tag", numOfTags)}
                        <OverLimitText usage={numOfTags} limit={subscription.features.tagLimit} />
                    </span>
                </p>
                <p className={classes.row}>
                    <IconProfile/>
                    <span>
                        {pluralize("user", numOfMembers)}
                        <OverLimitText usage={numOfMembers} limit={subscription.features.numOfSeats} />
                    </span>
                </p>
            </section>
        </div>
    );
}
