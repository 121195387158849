import "./index.css";
import "unfonts.css";

import React from "react";

import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";

import { config } from "../config/frontend";

import { App } from "./App";
import { init as initTracking } from "./tracking";

function init() {
    Sentry.init({
        dsn: config.SENTRY_DSN,
        environment: config.APP_ENV,
        enabled: config.SENTRY_ENABLED,
    });

    initTracking(config.MIXPANEL_ENABLED);

    /* eslint-disable */
    // @ts-ignore
    window.intercomSettings = {
        api_base: "https://api-iam.intercom.io",
        app_id: config.INTERCOM_APP_ID
    };
    // @ts-ignore
    (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;
    s.src=`https://widget.intercom.io/widget/${config.INTERCOM_APP_ID}`;
    // @ts-ignore
    var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
    /* eslint-enable */

}

init();

createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);
