interface Props {
    className?: string;
}

export function IconProfile({ className }: Props) {
    return (
        <svg className={className} width="12" height="13" viewBox="0 0 12 13" fill="none">
            <g>
                <path fillRule="evenodd" clipRule="evenodd"
                    d="M1.5 4A1.5 1.5 0 0 0 3 5.5V7h6V5.5a1.5 1.5 0 1 0-1.053-2.568A2.49 2.49 0 0 0 6 2a2.49 2.49 0 0 0-1.947.932A1.507 1.507 0 0 0 3 2.5 1.5 1.5 0 0 0 1.5 4zm9 3v-.401a3 3 0 0 0-2.355-5.475A3.984 3.984 0 0 0 6 .5c-.79 0-1.526.23-2.145.624A3 3 0 0 0 1.5 6.599V8.5c0 2.454 2.282 4 4.5 4s4.5-1.546 4.5-4V7zM9 8.5H3c0 .563.223 1.082.6 1.5.547.607 1.419 1 2.4 1 .981 0 1.853-.393 2.4-1 .377-.418.6-.937.6-1.5z"
                    fill="currentColor"/>
            </g>
        </svg>
    );
}
