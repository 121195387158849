enum Method {
    Get = "GET",
    Patch = "PATCH",
    Post = "POST",
    Put = "PUT",
    Delete = "DELETE",
}

const CACHE_LIFETIME = 5 * 60 * 1000; // 5 minutes
const CACHE_LIFETIMES: Record<string, number | undefined> = {};

const headers = new Headers();
headers.set("Content-Type", "application/json");

export const http = {
    get(url: string, options: RequestInit & { useCache?: boolean; } = {}): Promise<Response> {
        const { useCache, ...init } = options;

        if (useCache) {
            const now = Date.now();
            const cacheLifetime = CACHE_LIFETIMES[url];

            if (cacheLifetime && cacheLifetime > now) {
                init.cache = "force-cache";
            } else {
                CACHE_LIFETIMES[url] = now + CACHE_LIFETIME;
            }
        }

        return fetch(url, { headers, ...init });
    },

    patch(url: string, body?: unknown): Promise<Response> {
        const init = {
            method: Method.Patch,
            headers,
        };

        if (body) {
            Object.assign(init, {
                body: JSON.stringify(body),
            });
        }

        return fetch(url, init);
    },

    post(url: string, body?: unknown) {
        const init = {
            method: Method.Post,
            headers,
        };

        if (body) {
            Object.assign(init, {
                body: JSON.stringify(body),
            });
        }

        return fetch(url, init);
    },

    put(url: string, body?: unknown): Promise<Response> {
        const init = {
            method: Method.Put,
            headers,
        };

        if (body) {
            Object.assign(init, {
                body: JSON.stringify(body),
            });
        }

        return fetch(url, init);
    },

    delete(url: string, body?: unknown): Promise<Response> {
        const init = {
            method: Method.Delete,
            headers,
        };

        if (body) {
            Object.assign(init, {
                body: JSON.stringify(body),
            });
        }

        return fetch(url, init);
    },
};
