import { useCallback, useEffect, useState } from "react";

import classNames from "classnames";
import {
    type unstable_BlockerFunction as BlockerFunction,
    unstable_useBlocker as useBlocker,
    generatePath,
    matchPath,
    Outlet,
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";

import { APIError, APIErrorCode, getMe, getWorkspace as getWorkspaceBySlug } from "../../api/api";
import { Header } from "../../containers/header/Header";
import { MainHeaderButton } from "../../containers/header/mainHeaderButton/MainHeaderButton";
import { SubscriptionInfoButton } from "../../containers/header/subscriptionInfoButton/SubscriptionInfoButton";
import { Tabs } from "../../containers/header/tabs/Tabs";
import { PricingTableDialog } from "../../containers/pricingTableDialog/PricingTableDialog";
import { ViewOnlyBanner } from "../../containers/ViewOnlyBanner/ViewOnlyBanner";
import { HeaderButton } from "../../library/HeaderButton/HeaderButton";
import { IconBack } from "../../library/icons/IconBack";
import { logError } from "../../logger";
import { AccessLevel } from "../../models/AccessLevel";
import { SubscriptionPlan } from "../../models/SubscriptionPlan";
import { SubscriptionStatus } from "../../models/SubscriptionStatus";
import { hasCoreTag } from "../../models/Tag";
import { usePreferencesStore } from "../../providers/PreferencesStoreProvider/PreferencesStoreProvider";
import { userPreferences } from "../../providers/PreferencesStoreProvider/storage";
import { useStore } from "../../providers/StoreProvider/StoreProvider";
import { PAID_PAGES, RoutePath } from "../../RoutePath";

import { AddMoreTagsDialog } from "./addMoreTagsDialog/AddMoreTagsDialog";
import { BillingDialog } from "./billingDialog/BillingDialog";
import { PaymentSuccessDialog } from "./PaymentSuccessDialog/PaymentSuccessDialog";
import { SavedChartsInfoDialog } from "./savedChartsInfoDialog/SavedChartsInfoDialog";
import { ScanMoreProjectsDialog } from "./scanMoreProjectsDialog/ScanMoreProjectsDialog";
import { SetupRegularScansDialog } from "./setupRegularScansDialog/SetupRegularScansDialog";

import classes from "./AppShell.module.css";
import headerClasses from "../../containers/header/Header.module.css";

interface LocationState {
    fromApp?: boolean;
    displaySubscriptionInfoDialog?: boolean;
}

export function AppShell() {
    const {
        actions: {
            setUser,
            setWorkspace,
            setIsSubscriptionInfoDialogVisible,
            setAnalyticsURL,
            setComponentsURL,
            setDashboardURL,
            openPricingTableDialog,
        },
        selectors: {
            getWorkspace,
            getAccessLevel,
            getIsSetupRegularScansDialogVisible,
            getIsScanMoreProjectsDialogVisible,
            getIsAddMoreTagsDialogVisible,
            getIsBillingDialogVisible,
            getIsSavedChartsInfoDialogVisible,
            getIsPaymentSuccessDialogVisible,
        },
    } = useStore();
    const { actions: { initUserPreferences } } = usePreferencesStore();

    const { workspaceSlug } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const [title, setTitle] = useState<string | null>(null);
    const workspace = getWorkspace();
    const accessLevel = getAccessLevel();
    const locationState = location.state as (null | LocationState);

    function handleBackClick() {
        if (locationState?.fromApp) {
            return navigate(-1);
        }

        navigate(generatePath(RoutePath.Dashboard, { workspaceSlug: workspaceSlug! }));
    }

    const shouldBlock = useCallback<BlockerFunction>(({ nextLocation }) => {
        if (workspace?.subscription.plan !== SubscriptionPlan.TrialEnded) {
            // if the trial is active, don't block navigation
            return false;
        }

        if (PAID_PAGES.some(path => matchPath(path, nextLocation.pathname) !== null)) {
            // if the next page is one of the paid pages, block navigation
            return true;
        }

        if (matchPath(RoutePath.Components, nextLocation.pathname) !== null && nextLocation.search !== "") {
            // if the next page is components, and it has some query parameters, block navigation
            return true;
        }

        return false;
    }, [workspace]);
    const blocker = useBlocker(shouldBlock);

    useEffect(() => {
        if (blocker.state === "blocked") {
            setIsSubscriptionInfoDialogVisible(true);
            blocker.reset();
        }
    }, [blocker.state]);

    useEffect(() => {
        if (locationState?.displaySubscriptionInfoDialog) {
            setIsSubscriptionInfoDialogVisible(true);
        }
    }, [locationState?.displaySubscriptionInfoDialog]);

    useEffect(() => {
        async function fetchData() {
            if (!workspaceSlug) {
                return;
            }

            try {
                const { workspace, accessLevel } = await getWorkspaceBySlug(workspaceSlug);

                setWorkspace(workspace, accessLevel);

                if (workspace.projects.length === 0) {
                    navigate(generatePath(RoutePath.QuickStart, { workspaceSlug: workspace.slug }), { replace: true });
                    return;
                }

                if (workspace.hasReachedDataRetentionLimit) {
                    // We should open pricing table dialog if user tries to access pricing page
                    if (matchPath(RoutePath.WorkspacePricing, location.pathname) !== null) {
                        openPricingTableDialog("pricing_url");
                    }

                    navigate(generatePath(RoutePath.DataRetentionLimitReached, { workspaceSlug: workspace.slug }), { replace: true });
                    return;
                }

                if (workspace.componentLimitGracePeriodEndAt && workspace.componentLimitGracePeriodEndAt < new Date()) {
                    // We should open pricing table dialog if user tries to access pricing page
                    if (matchPath(RoutePath.WorkspacePricing, location.pathname) !== null) {
                        openPricingTableDialog("pricing_url");
                    }

                    // Users can access to all scans page to delete scans
                    if (matchPath(RoutePath.AllScans, location.pathname) === null) {
                        navigate(generatePath(RoutePath.ComponentLimitExceeded, { workspaceSlug: workspaceSlug }), { replace: true });
                    }
                    return;
                }

                if (!hasCoreTag(workspace.tags)) {
                    navigate(generatePath(RoutePath.Onboarding, { workspaceSlug: workspaceSlug }), { replace: true });
                    return;
                }

            } catch (error) {
                if (error instanceof APIError && error.code === APIErrorCode.UNAUTHORIZED) {
                    const redirectURL = `${location.pathname}${location.search}${location.hash}`;
                    navigate(`${RoutePath.Login}?redirect=${encodeURIComponent(redirectURL)}`, { replace: true });
                    return;
                }

                if (error instanceof APIError && error.code === APIErrorCode.MEMBER_LIMIT_EXCEEDED) {
                    navigate(generatePath(RoutePath.MemberLimitExceeded, { workspaceSlug: workspaceSlug }), { replace: true });
                    return;
                }

                navigate("/", { replace: true });
                logError(error);
            }
        }

        fetchData();

        const dashboardURL = generatePath(RoutePath.Dashboard, { workspaceSlug: workspaceSlug! });
        setAnalyticsURL(dashboardURL);
        setComponentsURL(generatePath(RoutePath.Components, { workspaceSlug: workspaceSlug! }));

        if ([RoutePath.SavedChart, RoutePath.SavedCharts].some(path => matchPath(path, location.pathname) !== null)) {
            setDashboardURL(generatePath(RoutePath.SavedCharts, { workspaceSlug: workspaceSlug! }));
        } else {
            setDashboardURL(dashboardURL);
        }
    }, [workspaceSlug]);

    useEffect(() => {
        async function fetchData() {
            try {
                const user = await getMe();

                setUser(user);

                const preferences = await userPreferences.getAll(user.id);
                initUserPreferences(preferences);
            } catch (error) {
                logError(error);
            }
        }

        fetchData();
    }, []);

    function renderMainHeaderButton() {
        if (title) {
            return (
                <HeaderButton
                    icon={<IconBack/>}
                    label="Back"
                    onClick={handleBackClick}/>
            );
        }

        return (
            <MainHeaderButton
                showAllScans={accessLevel !== AccessLevel.Page}
                showBilling={accessLevel === AccessLevel.Full}/>
        );
    }

    function renderSecondaryHeaderButton() {
        if (accessLevel === AccessLevel.ReadOnly) {
            return <div className={classes.demoIndicator}>Demo</div>;
        }

        if (workspace?.subscription &&
            (
                workspace.subscription.status === SubscriptionStatus.Trial ||
                workspace.subscription.plan === SubscriptionPlan.Free ||
                workspace.subscription.plan === SubscriptionPlan.TrialEnded)
        ) {
            return (
                <SubscriptionInfoButton subscription={workspace?.subscription} numOfRecentAnalyses={workspace.numOfRecentAnalyses} nextAvailableAnalysisDate={workspace.nextAvailableAnalysisDate}/>
            );
        }
    }

    function renderCenterContent() {
        if (accessLevel === AccessLevel.Page) {
            return null;
        }

        if (title) {
            return <h1 className={headerClasses.title}>{title}</h1>;
        }

        return <Tabs/>;
    }

    if (!workspace) {
        return null;
    }

    const isSetupRegularScansDialogVisible = getIsSetupRegularScansDialogVisible();
    const isScanMoreProjectsDialogVisible = getIsScanMoreProjectsDialogVisible();
    const isAddMoreTagsDialogVisible = getIsAddMoreTagsDialogVisible();
    const isBillingDialogVisible = getIsBillingDialogVisible();
    const isSavedChartsInfoDialogVisible = getIsSavedChartsInfoDialogVisible();
    const isPaymentSuccessDialogVisible = getIsPaymentSuccessDialogVisible();

    return (
        <>
            <Header
                leftContent={
                    <>
                        {renderMainHeaderButton()}
                        {renderSecondaryHeaderButton()}
                    </>
                }
                centerContent={renderCenterContent()}/>
            <div className={classNames({ [classes.withTallHeader]: accessLevel === AccessLevel.ReadOnly })}>
                <Outlet context={setTitle}/>
            </div>
            {isSetupRegularScansDialogVisible && <SetupRegularScansDialog/>}
            {isScanMoreProjectsDialogVisible && <ScanMoreProjectsDialog/>}
            {isAddMoreTagsDialogVisible && <AddMoreTagsDialog/>}
            {isBillingDialogVisible && <BillingDialog/>}
            <PricingTableDialog/>
            {isSavedChartsInfoDialogVisible && <SavedChartsInfoDialog/>}
            {accessLevel === AccessLevel.Page && <ViewOnlyBanner/>}
            {isPaymentSuccessDialogVisible && <PaymentSuccessDialog/>}
        </>
    );
}
