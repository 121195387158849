
export function DefaultIllustration() {
    return (
        <svg width="240" height="96" viewBox="0 0 240 96" fill="none">
            <circle cx="120" cy="62" r="18" fill="#262B2E"/>
            <circle opacity=".2" cx="120" cy="62" r="15" transform="rotate(10 120 62)"
                fill="#6B7276"/>
            <path
                d="M122.605 47.228a3 3 0 0 0-3.476 2.433l-4.167 23.636a3 3 0 0 0 2.433 3.475c8.159 1.439 15.939-4.009 17.377-12.167 1.439-8.159-4.009-15.939-12.167-17.377z"
                fill="#F69833"/>
            <path fillRule="evenodd" clipRule="evenodd"
                d="M116.108 76.212c6.927-.242 12.98-5.289 14.232-12.388 1.252-7.1-2.711-13.913-9.138-16.51a3 3 0 0 0-2.073 2.347l-4.167 23.636a2.998 2.998 0 0 0 1.146 2.915z"
                fill="#FFC738"/>
        </svg>
    );
}
