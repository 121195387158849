import { type MouseEvent } from "react";

import { createPortal } from "react-dom";

import { showNewMessage } from "../../../intercom";
import { EggType, Egg } from "../../../library/Egg/Egg";

import classes from "./ShareFeedbackDialog.module.css";

interface Props {
    buttonRect: DOMRect;
    onOverlayClick(): void;
}

export function ShareFeedbackDialog({
    buttonRect,
    onOverlayClick,
}: Props) {
    function handleOverlayClick(event: MouseEvent<HTMLDivElement>) {
        event.stopPropagation();
        onOverlayClick();
    }

    function handleDialogClick(event: MouseEvent<HTMLDivElement>) {
        event.stopPropagation();
    }

    function handleSendMessageButtonClick() {
        showNewMessage("Omlet Feedback: ");
    }

    return createPortal(
        <div className={classes.shareFeedbackDialogOverlay} onClick={handleOverlayClick}>
            <div
                className={classes.shareFeedbackDialog}
                style={{ top: buttonRect.bottom, left: buttonRect.right - 12 - 288 }}
                onClick={handleDialogClick}>
                <Egg type={EggType.Cracked_2}/>
                <p>We’re just getting started, any feedback would be super helpful!</p>
                <a href="https://discord.gg/XYHgmjfvxa" rel="nofollow external noopener noreferrer" target="_blank">
                    Join Discord community
                </a>
                <p className={classes.sendMessage}>
                    You can also{" "}
                    <button onClick={handleSendMessageButtonClick}>
                        send us a message
                    </button>.
                </p>
            </div>
        </div>,
        document.body
    );
}
