import { ButtonKind, ButtonLink } from "../../../library/Button/Button";
import { IconBack } from "../../../library/icons/IconBack";
import { type AnalysisSubject } from "../../../models/AnalysisSubject";
import { AnalysisType } from "../../../models/AnalysisType";
import { type BreakdownType } from "../../../models/BreakdownType";
import { type Filter } from "../../../models/Filter";
import { trackAddAnalyticsBreakdown, trackAddAnalyticsFilter, trackChangeAnalysisSubject, trackChangeAnalysisType, trackNavigateBackToDashboard, trackRemoveAnalyticsBreakdown, trackRemoveAnalyticsFilter } from "../../../tracking";
import { AnalyzeWidget } from "../widgets/analyzeWidget/AnalyzeWidget";
import { BreakdownWidget } from "../widgets/breakdownWidget/BreakdownWidget";
import { FilterWidget } from "../widgets/filterWidget/FilterWidget";

import classes from "./Filters.module.css";

interface Props {
    backURL: string;
    analysisType: AnalysisType;
    analysisSubject?: AnalysisSubject;
    filters: Partial<Filter>[];
    breakdownType?: BreakdownType;
    disabled?: boolean;
    onAnalysisTypeChange(analysisType: AnalysisType): void;
    onAnalysisSubjectChange(analysisSubject: AnalysisSubject): void;
    onFiltersChange(filters: Filter[]): void;
    onBreakdownTypeChange(breakdownType: BreakdownType | undefined): void;
}

export function Filters({
    backURL,
    analysisType,
    analysisSubject,
    filters,
    breakdownType,
    disabled = false,
    onAnalysisTypeChange,
    onAnalysisSubjectChange,
    onFiltersChange,
    onBreakdownTypeChange,
}: Props) {
    function handleBackButtonClick() {
        trackNavigateBackToDashboard();
    }

    function handleTypeChange(newType: AnalysisType) {
        trackChangeAnalysisType({ analysisType: newType });

        onAnalysisTypeChange(newType);
    }

    function handleSubjectChange(newSubject: AnalysisSubject) {
        trackChangeAnalysisSubject({ analysisSubject: newSubject });

        onAnalysisSubjectChange(newSubject);
    }

    function handleFiltersChange(newFilters: Filter[]) {
        if (filters.length < newFilters.length) {
            trackAddAnalyticsFilter({ filterType: newFilters[newFilters.length - 1].type });
        } else if (filters.length > newFilters.length) {
            trackRemoveAnalyticsFilter();
        }

        onFiltersChange(newFilters);
    }

    function handleBreakdownChange(newBreakdownType: BreakdownType) {
        if (newBreakdownType) {
            trackAddAnalyticsBreakdown({ breakdownType: newBreakdownType });
        } else {
            trackRemoveAnalyticsBreakdown();
        }

        onBreakdownTypeChange(newBreakdownType);
    }

    return (
        <div className={classes.filters}>
            <nav>
                {!disabled && (
                    <ButtonLink kind={ButtonKind.Secondary} to={backURL} onClick={handleBackButtonClick} icon={<IconBack/>}>
                        Back to Dashboard
                    </ButtonLink>
                )}
            </nav>
            <div className={classes.widgets}>
                <AnalyzeWidget
                    analysisType={analysisType}
                    analysisSubject={analysisSubject}
                    disabled={disabled}
                    onTypeChange={handleTypeChange}
                    onSubjectChange={handleSubjectChange}/>
                <FilterWidget
                    filters={filters}
                    disabled={disabled}
                    onFiltersChange={handleFiltersChange}/>
                {analysisType === AnalysisType.LatestData && (
                    <BreakdownWidget
                        analysisSubject={analysisSubject}
                        breakdownType={breakdownType}
                        onBreakdownChange={handleBreakdownChange}
                        disabled={disabled}/>
                )}
            </div>
        </div>
    );
}
