import { generateSlug, isCapitalized } from "../utils";

import { type Filter, isCustomPropertyFilter } from "./Filter";
import { type FolderFilter, EMPTY_FOLDER_FILTER } from "./FolderFilter";

export interface Tag extends FolderFilter {
    name: string;
    slug: string;
    color: string;
    createdAt: Date;
    updatedAt: Date;
    searchTerm: string;
    filters: Filter[];
    isActive: boolean;
}

export const RESERVED_TAGS: Record<"CORE" | "EXTERNAL" | "UNTAGGED", Tag & { tooltip: string; }> = Object.freeze({
    CORE: {
        ...createTag({
            slug: "core",
            name: "core",
            color: "var(--tag-color-core)",
        }),
        tooltip: "Design system/library components",
        isActive: true,
    },
    EXTERNAL: {
        ...createTag({
            slug: "<external>",
            name: "external",
            color: "var(--tag-color-external)",
        }),
        tooltip: "Components from third party libraries",
        isActive: true,
    },
    UNTAGGED: {
        ...createTag({
            slug: "<untagged>",
            name: "Untagged",
            color: "var(--tag-color-untagged)",
        }),
        tooltip: "Components not tagged",
        isActive: true,
    },
});

export function hasCoreTag(tags: Tag[]): boolean {
    return tags.some(tag => tag.slug === RESERVED_TAGS.CORE.slug);
}

export function getCoreTag(tags: Tag[]): Tag {
    return tags.find(tag => tag.slug === RESERVED_TAGS.CORE.slug) ?? RESERVED_TAGS.CORE;
}

export function hasCustomPropertyFilter(tag: Tag): boolean {
    return tag.filters.some(filter => isCustomPropertyFilter(filter));
}

export const NON_CORE_TAG_SLUG = "<non-core>";

export function getNonCoreTagName(coreTagName: string): string {
    const non = isCapitalized(coreTagName) ? "Non" : "non";

    return `${non}–${coreTagName}`;
}

export function createTag(data: Pick<Tag, "name"> & Partial<Tag>) {
    return {
        color: "",
        createdAt: new Date(),
        updatedAt: new Date(),
        searchTerm: "",
        ...EMPTY_FOLDER_FILTER,
        filters: [],
        slug: generateSlug(data.name),
        hasFilters: false,
        isActive: true,
        ...data,
    };
}

export function getNonCoreTag(coreTag: Tag): Tag {
    return createTag({
        slug: NON_CORE_TAG_SLUG,
        name: getNonCoreTagName(coreTag.name),
        color: "var(--tag-color-non-core)",
    });
}
