
import { config } from "../../../../config/frontend";
import customPropertiesURL from "../../../assets/img/imgCustomProperties.png";
import customProperties2xURL from "../../../assets/img/imgCustomProperties@2x.png";
import customProperties3xURL from "../../../assets/img/imgCustomProperties@3x.png";
import { Button, ButtonAnchor, ButtonKind } from "../../../library/Button/Button";
import { Dialog } from "../../../library/Dialog/Dialog";
import { H2 } from "../../../library/Heading/Heading";
import { IconLock } from "../../../library/icons/IconLock";
import { IconMetadata } from "../../../library/icons/IconMetadata";
import { Feature } from "../../../models/Feature";
import { type Subscription, isFeatureEnabled } from "../../../models/Subscription";
import { subscriptionPlanToString } from "../../../models/SubscriptionPlan";
import { useStore } from "../../../providers/StoreProvider/StoreProvider";
import { trackContactUsLinkClick } from "../../../tracking";

import classes from "./CustomPropertiesDialog.module.css";

interface Props {
    subscription: Subscription;
    onClose(): void;
}

export function CustomPropertiesDialog({
    subscription,
    onClose,
}: Props) {
    const { actions: { openPricingTableDialog } } = useStore();
    const isCustomPropertiesEnabled = isFeatureEnabled(subscription, Feature.CustomProperties);

    const icon = isCustomPropertiesEnabled ? <IconMetadata/> : <IconLock/>;
    const suggestion = isCustomPropertiesEnabled
        ? "See our docs to learn more about CLI hooks and how to add custom properties."
        : `You’re currently on the ${subscriptionPlanToString(subscription.plan)} plan where Custom Properties are not available. If you’d like to upgrade, check our plans below and let us know if you have any questions.`;

    function handlePricingClick() {
        onClose();
        openPricingTableDialog("custom_properties_dialog");
    }

    function handleContactUsClick() {
        trackContactUsLinkClick({ source: "custom_properties_dialog" });
    }

    return (
        <Dialog
            className={classes.customPropertiesDialog}
            onClose={onClose}>
            <div className={classes.content}>
                <div className={classes.header}>
                    {icon}
                    <H2 className={classes.h2}>
                        Custom properties
                    </H2>
                    <p className={classes.p}>
                        In addition to Omlet’s built-in properties, you can assign custom properties to your components
                        using Omlet’s CLI hooks. These custom properties can then be used to filter and tag components,
                        giving you more control over how to analyze your design system.
                    </p>
                </div>
                <img
                    src={customPropertiesURL}
                    srcSet={`${customProperties2xURL} 2x, ${customProperties3xURL} 3x`}
                    alt="Filter components by custom properties"/>
                <div className={classes.footer}>
                    <p className={classes.p}>{suggestion}</p>
                    <div className={classes.buttons}>
                        {isCustomPropertiesEnabled
                            ? <ButtonAnchor href="/l/docs/cli/hooks" target="_blank">See docs</ButtonAnchor>
                            : <Button onClick={handlePricingClick}>See plans</Button>
                        }
                        {!isCustomPropertiesEnabled && (
                            <ButtonAnchor
                                kind={ButtonKind.Secondary}
                                href={`${config.LANDING_PAGE_BASE_URL}/contact/`}
                                target="_blank"
                                onClick={handleContactUsClick}>
                                Contact us
                            </ButtonAnchor>
                        )}
                    </div>
                </div>
            </div>
        </Dialog>
    );
}
