import { useEffect } from "react";

import { generatePath, useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { APIError, APIErrorCode, getDefaultWorkspace } from "../../api/api";
import { logError } from "../../logger";
import { LoginType } from "../../models/LoginType";
import { SubscriptionPlan } from "../../models/SubscriptionPlan";
import { SubscriptionStatus } from "../../models/SubscriptionStatus";
import { useStore } from "../../providers/StoreProvider/StoreProvider";
import { RoutePath } from "../../RoutePath";

export function Pricing() {
    const {
        actions: { openPricingTableDialog, openBillingDialog },
        selectors: { getWorkspace },
    } = useStore();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const workspace = getWorkspace();

    const isNewUser = searchParams.get("type") === LoginType.NewUser;


    useEffect(() => {
        if (workspace) {
            if (workspace.subscription.status === SubscriptionStatus.Paid && workspace.subscription.plan === SubscriptionPlan.Advanced) {
                openBillingDialog();
            } else {
                openPricingTableDialog("pricing_url");
            }
            navigate("../", { replace: true });
            return;
        }

        if (isNewUser) {
            navigate(RoutePath.SelectProfession, { replace: true });
            return;
        }

        async function fetchData() {
            try {
                const workspace = await getDefaultWorkspace();

                if (workspace) {
                    navigate(generatePath(RoutePath.WorkspacePricing, { workspaceSlug: workspace.slug }), { replace: true });
                } else {
                    navigate(RoutePath.CreateWorkspace, { replace: true });
                }
            } catch (error) {
                if (error instanceof APIError && error.code === APIErrorCode.UNAUTHORIZED) {
                    const redirectURL = `${location.pathname}${location.search}${location.hash}`;
                    navigate(`${RoutePath.Login}?redirect=${encodeURIComponent(redirectURL)}`, { replace: true });
                    return;
                }

                logError(error);
            }
        }

        fetchData();

    }, [workspace?.slug]);
    return null;
}
