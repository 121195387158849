import { type PropsWithChildren, type ReactNode, createContext, useContext, useState } from "react";

import classNames from "classnames";

import classes from "./Toast.module.css";

const DEFAULT_TIMEOUT = 3000;
const ANIMATION_DURATION = 150;

type ToastShowFunction = (content: ReactNode, timeout?: number) => void;
type ToastHideFunction = () => void;
type ToastFunctions = { show: ToastShowFunction; hide: ToastHideFunction; };
const ToastContext = createContext<ToastFunctions>({ show: () => {}, hide: () => {} });

export function useToast(): ToastFunctions {
    return useContext(ToastContext);
}

async function sleep(duration: number) {
    await new Promise(resolve => setTimeout(resolve, duration));
}

export function ToastProvider({ children }: PropsWithChildren<{ }>) {
    const [{ content, animating }, setState] = useState<{ content: ReactNode; animating: boolean; }>({ content: "", animating: true });

    async function show(next: ReactNode, timeout = DEFAULT_TIMEOUT) {
        setState({ content: next, animating: true });
        await sleep(1);
        setState({ content: next, animating: false });
        await sleep(timeout);
        setState({ content: next, animating: true });
        await sleep(ANIMATION_DURATION);
        setState({ content: "", animating: true });
    }

    function hide() {
        setState({ content: "", animating: false });
    }

    return (
        <ToastContext.Provider value={{ show, hide }}>
            {children}
            {content && (
                <div className={classNames(classes.toast, { [classes.animating]: animating })}>
                    {content}
                </div>
            )}
        </ToastContext.Provider>
    );
}
