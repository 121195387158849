import { type Dispatch } from "react";

import { type DataAnalysis } from "../../models/DataAnalysis";
import { type SavedChart } from "../../models/SavedChart";

import { type All } from "./payloads";
import { Types } from "./types";


export function getActions(dispatch: Dispatch<All>) {
    return {
        cacheAnalysisData(url: string, data: DataAnalysis[]) {
            dispatch({
                type: Types.CACHE_ANALYSIS_DATA,
                url,
                data,
            });
        },
        setSavedChartsData(savedCharts: SavedChart[]) {
            dispatch({
                type: Types.SET_SAVED_CHARTS_DATA,
                savedCharts,
            });
        },
        addSavedChartData(savedChart: SavedChart) {
            dispatch({
                type: Types.ADD_SAVED_CHART_DATA,
                savedChart,
            });
        },
        updateSavedChartData(slug: string, update: Partial<SavedChart>) {
            dispatch({
                type: Types.UPDATE_SAVED_CHART_DATA,
                slug,
                update,
            });
        },
        removeSavedChartData(slug: string) {
            dispatch({
                type: Types.REMOVE_SAVED_CHART_DATA,
                slug,
            });
        },
    };
}
