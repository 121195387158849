import { useMemo } from "react";

import { config } from "../../../../../config/frontend";
import { Button, ButtonAnchor, ButtonKind } from "../../../../library/Button/Button";
import { Callout, CalloutSize } from "../../../../library/Callout/Callout";
import { H2, H3 } from "../../../../library/Heading/Heading";
import { Pill } from "../../../../library/Pill/Pill";
import { featureToString } from "../../../../models/Feature";
import { getFeatures, type Subscription } from "../../../../models/Subscription";
import { SubscriptionPlan, subscriptionPlanToString } from "../../../../models/SubscriptionPlan";
import { SubscriptionStatus } from "../../../../models/SubscriptionStatus";
import { useStore } from "../../../../providers/StoreProvider/StoreProvider";
import { trackContactUsLinkClick } from "../../../../tracking";
import { formatDate, getDaysBetween, pluralize } from "../../../../utils";

import classes from "./BillingInfo.module.css";
import dialogClasses from "../../../../library/Dialog/Dialog.module.css";


interface SubscriptionPillProps {
    className?: string;
    subscription: Subscription;
}
function SubscriptionPill({ className, subscription }: SubscriptionPillProps) {
    if (subscription.status === SubscriptionStatus.Paid || !subscription.trial) {
        return null;
    }

    const daysLeft = Math.max(1, getDaysBetween(new Date(), subscription.trial.endedAt));

    return <Pill className={className}>Trial, {pluralize("day", daysLeft)} left</Pill>;
}

interface PlanDetailsDescriptionProps {
    subscription: Subscription;
}
function PlanDetailsDescription({ subscription }: PlanDetailsDescriptionProps) {
    if (subscription.plan === SubscriptionPlan.TrialEnded) {
        return (
            <p>
                Your workspace's trial ended. You can continue to view your analytics
                dashboard based on your most recent scan, but won't be able to view details.
            </p>
        );
    }
    if (subscription.status === SubscriptionStatus.Paid || !subscription.trial) {
        return (
            <p>
                Your workspace is currently on the
                {" "}
                <strong>{subscriptionPlanToString(subscription.plan)} plan</strong>
                {subscription.nextPeriodStartedAt && `, renewing on ${formatDate(subscription.nextPeriodStartedAt, { month: "long", day: "numeric", year: "numeric" })}`}
                .
            </p>
        );
    }

    const daysLeft = Math.max(1, getDaysBetween(new Date(), subscription.trial.endedAt));
    const trialDuration = getDaysBetween(subscription.trial.startedAt, subscription.trial.endedAt);
    const formattedEndDate = formatDate(subscription.trial.endedAt, { month: "long", day: "numeric", year: "numeric" });

    return (
        <p>
            Your workspace is currently on a
            {" "}
            <strong>
                {trialDuration}-day trial of the {subscriptionPlanToString(subscription.plan)} plan
            </strong>
            , ending in {pluralize("day", daysLeft)} on {formattedEndDate}.
        </p>
    );
}


interface NumOfComponentsInfoProps {
    subscription: Subscription;
    numOfComponents: number;
}
function NumOfComponentsInfo({
    subscription: {
        features: {
            numOfComponents: totalNumOfComponents,
            additionalNumOfComponents,
            baseNumOfComponents,
        },
    },
    numOfComponents,
}: NumOfComponentsInfoProps) {
    function handleContactUsClick() {
        trackContactUsLinkClick({ source: "num_of_components_info_in_billing_dialog" });
    }
    const subscriptionText = (
        additionalNumOfComponents > 0
            ? `${totalNumOfComponents} components in total; ${baseNumOfComponents} base, ${additionalNumOfComponents} additional.`
            : `${baseNumOfComponents} components`
    );
    const usedComponentsText = (
        numOfComponents > totalNumOfComponents
            ? (
                <>
                    Your workspace is currently
                    {" "}
                    <strong>over the limit</strong>
                    {" "}
                    with a total of {numOfComponents} components —
                    {" "}
                    <a
                        href={`${config.LANDING_PAGE_BASE_URL}/contact/`}
                        rel="nofollow external noopener noreferrer"
                        target="_blank"
                        onClick={handleContactUsClick}>
                        contact us
                    </a>
                    {" "}
                    to upgrade.
                </>
            ) : `Your workspace currently has ${pluralize("component", numOfComponents)}.`
    );
    return (
        <li>
            {subscriptionText}
            <ul>
                <li>{usedComponentsText}</li>
            </ul>
        </li>
    );
}

interface ScanLimitInfoProps {
    subscription: Subscription;
    numOfRecentAnalyses: number;
}

function ScanLimitInfo({ subscription: { features: { analysisLimit } }, numOfRecentAnalyses }: ScanLimitInfoProps) {
    function handleContactUsClick() {
        trackContactUsLinkClick({ source: "scan_limit_info_in_billing_dialog" });
    }
    if (analysisLimit === Number.MAX_SAFE_INTEGER) {
        return <li>Unlimited scans</li>;
    }

    const usedScanText = (
        numOfRecentAnalyses < analysisLimit
            ? `Your workspace currently used ${numOfRecentAnalyses} of ${pluralize("scan", analysisLimit)} in the last 30 days.`
            : (
                <>
                    Your workspace currently used
                    {" "}
                    <strong>
                        {numOfRecentAnalyses} of {pluralize("scan", analysisLimit)}
                    </strong>
                    {" "}
                    in the last 30 days —
                    {" "}
                    <a
                        href={`${config.LANDING_PAGE_BASE_URL}/contact/`}
                        rel="nofollow external noopener noreferrer"
                        target="_blank"
                        onClick={handleContactUsClick}>
                        contact us
                    </a>
                    {" "}
                    to upgrade.
                </>
            )
    );

    return (
        <>
            <li>{analysisLimit} scans in the last 30 days
                <ul>
                    <li>{usedScanText}</li>
                </ul>
            </li>
        </>
    );
}

interface NumericFeatureGateInfoProps {
    feature: string;
    value: number;
    pluralizedFeature?: string;
}

function NumericFeatureGateInfo({ feature, value, pluralizedFeature = `${feature}s` }: NumericFeatureGateInfoProps) {
    if (value === Number.MAX_SAFE_INTEGER) {
        return <li>Unlimited {pluralizedFeature}</li>;
    }
    if (value === 1) {
        return <li>1 {feature}</li>;
    }
    return <li>Up to {value} {pluralizedFeature}</li>;
}

interface DataRetentionLimitInfoProps {
    subscription: Subscription;
}

function DataRetentionLimitInfo({ subscription: { features: { dataRetentionLimit } } }: DataRetentionLimitInfoProps) {
    if (dataRetentionLimit === Number.MAX_SAFE_INTEGER) {
        return <li>Unlimited scan retention</li>;
    }
    return (
        <li>
            {dataRetentionLimit}-day scan retention
        </li>
    );
}

interface PlanDetailsProps {
    subscription: Subscription;
    numOfComponents: number;
    numOfRecentAnalyses: number;
}

function PlanDetails({ subscription, numOfComponents, numOfRecentAnalyses }: PlanDetailsProps) {
    if (subscription.plan === SubscriptionPlan.TrialEnded) {
        return (
            <section className={classes.planDetail}>
                <H3 className={classes.heading}>
                    Plan
                    <SubscriptionPill className={classes.pill} subscription={subscription}/>
                </H3>
                <PlanDetailsDescription subscription={subscription}/>
                <Callout size={CalloutSize.Large}>
                    <ul>
                        <li>
                            4000 base components
                            <ul>
                                <li>Your workspace currently has {numOfComponents} components.</li>
                            </ul>
                        </li>
                    </ul>
                </Callout>
            </section>
        );
    }
    return (
        <section className={classes.planDetail}>
            <H3 className={classes.heading}>
                Plan
                <SubscriptionPill className={classes.pill} subscription={subscription}/>
            </H3>
            <PlanDetailsDescription subscription={subscription}/>
            <Callout size={CalloutSize.Large}>
                <ul>
                    <NumOfComponentsInfo subscription={subscription} numOfComponents={numOfComponents}/>
                    <NumericFeatureGateInfo feature="user" value={subscription.features.numOfSeats}/>
                    <ScanLimitInfo subscription={subscription} numOfRecentAnalyses={numOfRecentAnalyses}/>
                    <NumericFeatureGateInfo feature="tag" value={subscription.features.tagLimit}/>
                    <DataRetentionLimitInfo subscription={subscription}/>
                    {getFeatures(subscription).map(feature => <li key={feature}>{featureToString(feature)}</li>)}
                </ul>
            </Callout>
        </section>
    );
}

interface CTASectionProps {
    subscriptionStatus: SubscriptionStatus;
    plan: SubscriptionPlan;
}

function CTASection({ subscriptionStatus, plan }: CTASectionProps) {
    const { actions: { openPricingTableDialog } } = useStore();

    function handleContactUsClick() {
        trackContactUsLinkClick({ source: "cta_section_in_billing_dialog" });
    }

    function handlePricingClick() {
        openPricingTableDialog("billing_dialog");
    }

    if (subscriptionStatus === SubscriptionStatus.Trial) {
        return (
            <section>
                <p>
                    If you’d like to upgrade before your trial ends, check our plans below and let us
                    know if you have any questions.
                </p>
                <div className={classes.row}>
                    <Button kind={ButtonKind.Primary} onClick={handlePricingClick}>
                        See plans
                    </Button>
                    <ButtonAnchor
                        kind={ButtonKind.Secondary}
                        href={`${config.LANDING_PAGE_BASE_URL}/contact/`}
                        rel="nofollow external noopener noreferrer"
                        target="_blank"
                        onClick={handleContactUsClick}>
                        Contact us
                    </ButtonAnchor>
                </div>
            </section>
        );
    }

    if ([SubscriptionPlan.Free, SubscriptionPlan.TrialEnded].includes(plan)) {
        return (
            <section>
                <p>
                    Check our plans below and let us know if you have any questions.
                </p>
                <div className={classes.row}>
                    <Button kind={ButtonKind.Primary} onClick={handlePricingClick}>
                        See plans
                    </Button>
                    <ButtonAnchor
                        kind={ButtonKind.Secondary}
                        href={`${config.LANDING_PAGE_BASE_URL}/contact/`}
                        rel="nofollow external noopener noreferrer"
                        target="_blank"
                        onClick={handleContactUsClick}>
                        Contact us
                    </ButtonAnchor>
                </div>
            </section>
        );
    }

    if (plan === SubscriptionPlan.Intro) {
        return (
            <section>
                <p>
                    If you’d like to switch to another plan, check out the plans below.
                </p>
                <div className={classes.row}>
                    <Button kind={ButtonKind.Secondary} onClick={handlePricingClick}>
                        See plans
                    </Button>
                </div>
            </section>
        );
    }

    return (
        <section>
            <p>
                If you’d like to switch to another plan, contact us.
            </p>
            <div className={classes.row}>
                <ButtonAnchor
                    kind={ButtonKind.Secondary}
                    href={`${config.LANDING_PAGE_BASE_URL}/contact/`}
                    rel="nofollow external noopener noreferrer"
                    target="_blank"
                    onClick={handleContactUsClick}>
                    Contact us
                </ButtonAnchor>
            </div>
        </section>
    );
}

interface BillingPortalSectionProps {
    workspaceSlug: string;
    plan: SubscriptionPlan;
}

function BillingPortalSection({ workspaceSlug, plan }: BillingPortalSectionProps) {
    const billingPortalUrl = useMemo(() => {
        const result = new URL("/billing", config.APP_BASE_URL);
        result.searchParams.append("workspace_slug", workspaceSlug);
        return result.toString();
    }, [workspaceSlug]);

    if ([SubscriptionPlan.TrialEnded, SubscriptionPlan.Free].includes(plan)) {
        return null;
    }

    return (
        <>
            <section>
                <H3 className={dialogClasses.h3}>Billing details</H3>
                <p>
                    Omlet uses Stripe to manage billing. Head to the billing portal to view your billing
                    schedule,
                    update billing information and view invoices.
                </p>
                <ButtonAnchor
                    href={billingPortalUrl}
                    rel="nofollow external noopener noreferrer"
                    target="_blank">
                    Go to billing portal
                </ButtonAnchor>
            </section>
            <section>
                <p className={classes.footnote}>
                    {plan === SubscriptionPlan.Intro
                        ? "☝️ If you want to make any changes to your current plan (e.g. purchase more component buckets, change billing period)"
                        : "If you have any questions"
                    }
                    , contact us at
                    {" "}
                    <a href="mailto:support@omlet.dev">support@omlet.dev</a>
                    {" "}
                    or use the chat bubble on the bottom right.
                </p>
            </section>
        </>
    );
}

interface Props {
    subscription: Subscription;
    numOfComponents: number;
    numOfRecentAnalyses: number;
    workspaceSlug: string;
}

export function BillingInfo({
    subscription,
    numOfComponents,
    numOfRecentAnalyses,
    workspaceSlug,
}: Props) {

    const subscriptionStatus = subscription.status;

    return (
        <div className={classes.billingInfo}>
            <H2 className={dialogClasses.h2}>Billing</H2>
            <PlanDetails subscription={subscription} numOfComponents={numOfComponents} numOfRecentAnalyses={numOfRecentAnalyses} />
            <CTASection subscriptionStatus={subscriptionStatus} plan={subscription.plan} />
            {subscriptionStatus === SubscriptionStatus.Paid && <BillingPortalSection workspaceSlug={workspaceSlug} plan={subscription.plan} />}
        </div>
    );
}
