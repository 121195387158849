export enum SubscriptionPlan {
    Starter = "omlet_starter",
    Advanced = "omlet_advanced",
    Free = "omlet_free",
    TrialEnded = "omlet_trial_ended",
    Intro = "omlet_intro",
}

const stringMap: Record<SubscriptionPlan, string> = {
    [SubscriptionPlan.Starter]: "Starter",
    [SubscriptionPlan.Advanced]: "Advanced",
    [SubscriptionPlan.Free]: "Free",
    [SubscriptionPlan.TrialEnded]: "Trial Ended",
    [SubscriptionPlan.Intro]: "Intro",
};

export function subscriptionPlanToString(subscriptionPlan: SubscriptionPlan) {
    return stringMap[subscriptionPlan];
}
