import { type ReactNode, useState, useMemo, useRef } from "react";

import classNames from "classnames";
import { Link } from "react-router-dom";

import { config } from "../../../config/frontend";
import { getSharedPage } from "../../api/api";
import { insertDuplicateData } from "../../containers/analytics/dataTransformer";
import { ContextMenu, MenuAlignment } from "../../library/ContextMenu/ContextMenu";
import { H2 } from "../../library/Heading/Heading";
import { IconArrow } from "../../library/icons/IconArrow";
import { IconMenu } from "../../library/icons/IconMenu";
import { Loading } from "../../library/Loading/Loading";
import { useToast } from "../../library/Toast/Toast";
import { AnalysisSubject } from "../../models/AnalysisSubject";
import { AnalysisType } from "../../models/AnalysisType";
import { BreakdownType } from "../../models/BreakdownType";
import { type Project } from "../../models/Project";
import { type Tag } from "../../models/Tag";
import { AreaChart } from "../chart/areaChart/AreaChart";
import { SingleDataAreaChart } from "../chart/areaChart/SingleDataAreaChart";
import { BarChart } from "../chart/barChart/BarChart";
import { ChartMode } from "../chart/barChart/ChartMode";
import { type ChartDatum } from "../chart/ChartDatum";
import { ChartType } from "../chart/ChartType";
import { LineChart } from "../chart/lineChart/LineChart";
import { SingleDataLineChart } from "../chart/lineChart/SingleDataLineChart";
import { getLegendItems } from "../chart/utils";
import { EmptyBlock } from "../EmptyBlock/EmptyBlock";

import classes from "./DashboardChart.module.css";

interface Props {
    workspaceSlug: string;
    data: ChartDatum[] | null;
    tags: Tag[];
    projectMap: Record<string, Project>;
    title: string;
    description: ReactNode;
    analysisType: AnalysisType;
    analysisSubject: AnalysisSubject;
    breakdownType?: BreakdownType;
    link: string;
    linksDisabled: boolean;
    contextMenuItems: ReactNode;
    emptyStateMessage: ReactNode;
    insight?: ReactNode;
    renderSaveChartPopover(anchor: HTMLElement | null): ReactNode;
    onHeaderClick(): void;
}

const LATEST_DATA_ITEM_LIMIT = 7;

export function DashboardChart({
    workspaceSlug,
    data,
    tags,
    projectMap,
    title,
    description,
    analysisType,
    analysisSubject,
    breakdownType,
    link,
    linksDisabled,
    contextMenuItems,
    emptyStateMessage,
    insight,
    renderSaveChartPopover,
    onHeaderClick,
}: Props) {
    const toast = useToast();

    const menuButtonRef = useRef<HTMLButtonElement>(null);
    const [contextMenuOpen, setContextMenuOpen] = useState<boolean>(false);

    const tagMap = useMemo(() => Object.fromEntries(tags.map(t => [t.slug, t])), [tags]);

    async function handleCopyMenuItemClick() {
        const chartURL = new URL(link, config.APP_BASE_URL);

        try {
            const sharedPage = await getSharedPage(workspaceSlug, chartURL.toString());

            if (sharedPage) {
                chartURL.searchParams.set("token", sharedPage.code);
            } else {
                chartURL.searchParams.delete("token");
            }
        } catch {
            chartURL.searchParams.delete("token");
        }

        window.navigator.clipboard.writeText(chartURL.toString());
        toast.show("Link copied to clipboard!");
    }

    function renderHeader() {
        const clss = classNames(classes.header, {
            [classes.menuOpen]: contextMenuOpen || savedChartPopover,
            [classes.linksDisabled]: linksDisabled,
        });

        if (linksDisabled) {
            return (
                <header className={clss}>
                    <div className={classes.content}>
                        <div className={classes.link}>
                            <div className={classes.info}>
                                <H2 className={classes.title}>{title}</H2>
                            </div>
                            <IconArrow className={classes.icon}/>
                        </div>
                        <p className={classes.description}>{description || "No description yet"}</p>
                    </div>
                </header>
            );
        }

        return (
            <header className={clss}>
                <div className={classes.content}>
                    <Link className={classes.link} to={link} onClick={onHeaderClick}>
                        <div className={classes.info}>
                            <H2 className={classes.title}>{title}</H2>
                        </div>
                        <IconArrow className={classes.icon}/>
                    </Link>
                    <p className={classes.description}>{description || "No description yet"}</p>
                </div>
                <button
                    ref={menuButtonRef}
                    className={classNames(classes.menuButton, { [classes.menuOpen]: contextMenuOpen || savedChartPopover })}
                    onClick={() => setContextMenuOpen(true)}>
                    <IconMenu/>
                </button>
            </header>
        );
    }

    function renderChart() {
        if (data === null) {
            return <Loading className={classes.loading}/>;
        }

        if (data.length === 0) {
            return <EmptyBlock message={emptyStateMessage}/>;
        }

        if (analysisType === AnalysisType.DataOverTime) {
            const legendItems = getLegendItems(data, projectMap, tags, analysisType, analysisSubject, breakdownType);

            if (analysisSubject === AnalysisSubject.Tags && legendItems.length > 1) {
                if (data.length === 1) {
                    const onlyData = data[0];

                    if (onlyData.values.length === 1) {
                        return (
                            <SingleDataLineChart
                                type={ChartType.Small}
                                margin={{ top: 5, right: 21, bottom: 32, left: 48 }}
                                className={classNames(classes.chart, classes.lineChart)}
                                tagMap={tagMap}
                                data={onlyData}/>
                        );
                    } else if (onlyData.values.length === 2) {
                        return (
                            <SingleDataAreaChart
                                className={classNames(classes.chart, classes.areaChart)}
                                margin={{ top: 5, right: 21, bottom: 48, left: 49 }}
                                data={onlyData}/>
                        );
                    }
                }

                return (
                    <AreaChart
                        className={classNames(classes.chart, classes.areaChart)}
                        data={insertDuplicateData(data)}
                        legendItems={legendItems}
                        margin={{ top: 5, right: 21, bottom: 48, left: 49 }}/>
                );
            }

            if (data.length === 1) {
                return (
                    <SingleDataLineChart
                        type={ChartType.Small}
                        margin={{ top: 5, right: 21, bottom: 32, left: 48 }}
                        className={classNames(classes.chart, classes.lineChart)}
                        tagMap={tagMap}
                        data={data[0]}/>
                );
            }

            return (
                <LineChart
                    className={classNames(classes.chart, classes.lineChart)}
                    type={ChartType.Small}
                    data={data}
                    tagMap={tagMap}
                    margin={{ top: 5, right: 21, bottom: 32, left: 48 }}
                    axisBottomTickPadding={16}
                    axisBottomTickSize={0}
                    axisBottomItemCountHint={6}/>
            );
        }

        let chartMode;
        if (!breakdownType) {
            chartMode = ChartMode.Absolute;
        } else if (analysisSubject === AnalysisSubject.Components && breakdownType === BreakdownType.ProjectUsedIn) {
            chartMode = ChartMode.Unique;
        } else {
            chartMode = ChartMode.Percentage;
        }

        const limitedData = data.slice(0, LATEST_DATA_ITEM_LIMIT);
        const legendItems = getLegendItems(limitedData, projectMap, tags, analysisType, analysisSubject, breakdownType);

        return (
            <BarChart
                className={classNames(classes.chart, classes.barChart)}
                type={ChartType.Small}
                mode={chartMode}
                data={limitedData}
                tagMap={tagMap}
                legendItems={legendItems}
                hasBreakdown={breakdownType !== undefined}
                linksDisabled={linksDisabled}
                displayTooltip/>
        );
    }

    function renderContextMenu() {
        if (!contextMenuOpen) {
            return null;
        }

        return (
            <ContextMenu
                anchorRect={menuButtonRef.current!.getBoundingClientRect()}
                alignment={MenuAlignment.Right}
                offsetY={5}
                onClose={() => setContextMenuOpen(false)}>
                <ContextMenu.Link
                    to={link}>
                    View details
                </ContextMenu.Link>
                <ContextMenu.Button
                    onClick={handleCopyMenuItemClick}>
                    Copy link
                </ContextMenu.Button>
                {contextMenuItems}
            </ContextMenu>
        );
    }

    const savedChartPopover = renderSaveChartPopover(menuButtonRef.current);

    return (
        <div className={classes.dashboardChart}>
            {renderHeader()}
            {renderChart()}
            {insight}
            {renderContextMenu()}
            {savedChartPopover}
        </div>
    );
}
