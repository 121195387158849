import { forwardRef } from "react";

import classNames from "classnames";

import { Tag } from "../../../library/Tag/Tag";
import { type Tag as TagModel } from "../../../models/Tag";
import { formatPercentage, pluralize } from "../../../utils";

import { ChartTooltipSide } from "./ChartTooltipSide";

import classes from "./ChartTooltip.module.css";

interface BaseProps {
    x: number;
    y: number;
    side?: ChartTooltipSide;
    color: string;
    title: string;
    subtitle?: string;
    value: number;
    valueSum?: number;
}

export type ChartTooltipProps = BaseProps & ({
    tags: string[];
    tagMap: Record<string, TagModel>;
} | {
    tags?: never;
    tagMap?: never;
});

const sideClasses = {
    [ChartTooltipSide.Top]: classes.top,
    [ChartTooltipSide.Right]: classes.right,
    [ChartTooltipSide.Bottom]: classes.bottom,
    [ChartTooltipSide.Left]: classes.left,
};

export const TOOLTIP_OFFSET = 8;

export const ChartTooltip = forwardRef<HTMLDivElement, ChartTooltipProps>(({
    x,
    y,
    side = ChartTooltipSide.Top,
    color,
    title,
    subtitle,
    tags,
    tagMap,
    value,
    valueSum,
}, ref) => {
    return (
        <div
            ref={ref}
            className={classNames(classes.chartTooltip, sideClasses[side])}
            style={{ left: x, top: y, borderLeftColor: color }}>
            <div className={classes.chartTooltipHeader}>
                <div className={classes.chartTooltipTitle}>{title}</div>
                {subtitle && <div className={classes.chartTooltipSubtitle}>{subtitle}</div>}
                {tags && tags.length !== 0 && tagMap && <div className={classes.tags}>{tags.map(tag => <Tag key={tag} tag={tagMap[tag] ?? { name: tag }} />)}</div>}
            </div>
            <div className={classes.chartTooltipValues}>
                {
                    valueSum === undefined ? (
                        <div className={classes.chartTooltipSingleValue}>
                            Used
                            {" "}
                            <strong>
                                {pluralize("time", value)}
                            </strong>
                        </div>
                    ) : (
                        <>
                            <div className={classes.chartTooltipValueCell}>
                                <div className={classes.chartTooltipValue}>{formatPercentage(value / valueSum)}</div>
                                <div className={classes.chartTooltipTotal}>of total</div>
                            </div>
                            <div className={classes.chartTooltipValueCell}>
                                <div className={classes.chartTooltipValue}>{value}</div>
                                <div className={classes.chartTooltipTotal}>of {valueSum}</div>
                            </div>
                        </>
                    )
                }
            </div>
        </div>
    );
});
