import { useQuery } from "@tanstack/react-query";

import { getPrice } from "../../api/api";
import { Dialog } from "../../library/Dialog/Dialog";
import { type Price } from "../../models/Price";
import { PriceKey } from "../../models/PriceKey";
import { useStore } from "../../providers/StoreProvider/StoreProvider";

import { PricingTable } from "./PricingTable/PricingTable";

import classes from "./PricingTableDialog.module.css";

export function PricingTableDialog() {
    const {
        actions: {
            closePricingTableDialog,
        },
        selectors: {
            getWorkspace,
            getIsPricingTableDialogVisible,
        },
    } = useStore();

    const isPricingTableDialogVisible = getIsPricingTableDialogVisible();

    const { data: priceMap } = useQuery({
        queryKey: ["prices"],
        queryFn: async () => {
            return Object.fromEntries(
                await Promise.all(Object.values(PriceKey).map(async (priceKey) => [priceKey, await getPrice(priceKey)] as const))
            ) as Record<PriceKey, Price>;
        },
    });

    const workspace = getWorkspace();

    if (!workspace || !isPricingTableDialogVisible || !priceMap) {
        return null;
    }

    return (
        <Dialog
            className={classes.pricingTableDialog}
            onClose={closePricingTableDialog}>
            <PricingTable workspace={workspace} priceMap={priceMap} />
        </Dialog>
    );
}
