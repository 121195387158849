import { type PropsWithChildren } from "react";

import { generatePath, Navigate, useLocation, useParams } from "react-router-dom";

import { type Feature } from "../../models/Feature";
import { SubscriptionPlan } from "../../models/SubscriptionPlan";
import { useStore } from "../../providers/StoreProvider/StoreProvider";
import { RoutePath } from "../../RoutePath";

interface Props {
    allowEmptyQueryParams?: boolean;
    requiredFeature?: Feature;
}

export function SubscriptionRequiredPage({
    children,
    allowEmptyQueryParams = false,
}: PropsWithChildren<Props>) {
    const { workspaceSlug } = useParams();
    const location = useLocation();

    const { selectors: { getWorkspace } } = useStore();
    const workspace = getWorkspace()!;

    if (workspace.subscription.plan !== SubscriptionPlan.TrialEnded || (allowEmptyQueryParams && location.search === "")) {
        return <>{children}</>;
    }

    const redirectPath = (
        location.pathname.startsWith(generatePath(RoutePath.Components, { workspaceSlug: workspaceSlug! }))
            ? RoutePath.Components
            : RoutePath.Dashboard
    );
    return <Navigate to={generatePath(redirectPath, { workspaceSlug: workspace.slug })} state={{ displaySubscriptionInfoDialog: true }} replace/>;
}
