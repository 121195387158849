import { useEffect, useReducer } from "react";

import { getRandomInteger } from "../../../../utils";

import { Sparkle } from "./Sparkle/Sparkle";

import classes from "./Sparkles.module.css";

const width = 148;
const height = 230;

type Action = {
    type: "ADD";
    payload: Omit<SparkleData, "top" | "left">;
} | {
    type: "REMOVE";
    payload: string;
} | {
    type: "CLEAR";
};

interface Position {
    top: number;
    left: number;
}
function getDistance(a: Position, b: Position) {
    return Math.sqrt(Math.pow(a.top - b.top, 2) + Math.pow(a.left - b.left, 2));
}

function getRandomPosition(sparkles: SparkleData[]) {
    let top = getRandomInteger(height);
    let left = getRandomInteger(width);
    while (sparkles.some((sparkle) => getDistance(sparkle, { top, left }) < 16)) {
        top = getRandomInteger(height);
        left = getRandomInteger(width);
    }
    return { top, left };

}

function reducer(sparkles: SparkleData[], action: Action) {
    switch (action.type) {
        case "ADD":
            return [
                ...sparkles,
                {
                    ...action.payload,
                    ...getRandomPosition(sparkles),
                },
            ];
        case "REMOVE":
            return sparkles.filter((sparkle) => sparkle.id !== action.payload);
        case "CLEAR":
            return [];
        default:
            return sparkles;
    }
}

interface SparkleData {
    id: string;
    appearDuration: number;
    fadeDuration: number;
    delay: number;
    left: number;
    top: number;
}

export function Sparkles() {
    const [sparkles, dispatch ] = useReducer(reducer, []);

    useEffect(() => {
        const timeouts = new Set<number>();

        function addSparkle() {
            const id = crypto.randomUUID();
            const appearDuration = getRandomInteger(4000, 2000);
            const fadeDuration = getRandomInteger(300, 500);
            const delay = getRandomInteger(7000, 300);

            dispatch({
                type: "ADD",
                payload: {
                    id,
                    appearDuration,
                    fadeDuration,
                    delay,
                },
            });

            const timeout = window.setTimeout(() => {
                timeouts.delete(timeout);
                dispatch({
                    type: "REMOVE",
                    payload: id,
                });
                addSparkle();
            }, delay + appearDuration + (2 * fadeDuration));
            timeouts.add(timeout);
        }

        for (let i = 0; i < 7; i++) {
            addSparkle();
        }

        return () => {
            for (const timeout of timeouts) {
                clearTimeout(timeout);
            }
            dispatch({ type: "CLEAR" });
        };
    }, []);

    return <div className={classes.sparkles} style={{ width, height }}>
        {sparkles.map((sparkle) => (
            <Sparkle key={sparkle.id} {...sparkle} />
        ))}
    </div>;
}
