import { useEffect, useMemo, useRef, useState } from "react";

import LZString from "lz-string";
import { generatePath, Link, useNavigate, useParams, useSearchParams } from "react-router-dom";

import { createSavedChart } from "../../api/api";
import { SaveChartPopover } from "../../common/SaveChartPopover/SaveChartPopover";
import { Analytics } from "../../containers/analytics/Analytics";
import { Button } from "../../library/Button/Button";
import { IconBookmark } from "../../library/icons/IconBookmark";
import { useToast } from "../../library/Toast/Toast";
import { logError } from "../../logger";
import { AccessLevel } from "../../models/AccessLevel";
import { AnalysisSubject, toAnalysisSubject } from "../../models/AnalysisSubject";
import { AnalysisType, toAnalysisType } from "../../models/AnalysisType";
import { toBreakdownType, type BreakdownType } from "../../models/BreakdownType";
import { Feature } from "../../models/Feature";
import { type Filter } from "../../models/Filter";
import { getHumanReadableSlug } from "../../models/SavedChart";
import { isFeatureEnabled } from "../../models/Workspace";
import { useDataCacheStore } from "../../providers/DataCacheProvider/DataCacheProvider";
import { useStore } from "../../providers/StoreProvider/StoreProvider";
import { RoutePath } from "../../RoutePath";
import { trackCreateSavedChart, trackSaveChartButtonClick } from "../../tracking";
import { alertError } from "../../utils";
import { findPredefinedChart } from "../popularCharts/constants";

export function NewAnalytics() {
    const { workspaceSlug } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const toast = useToast();

    const saveChartButtonRef = useRef<HTMLButtonElement>(null);
    const [saveChartPopoverOpen, setSaveChartPopoverOpen] = useState(false);

    const [analysisType, setAnalysisType] = useState<AnalysisType>(AnalysisType.LatestData);
    const [analysisSubject, setAnalysisSubject] = useState<AnalysisSubject>();
    const [filters, setFilters] = useState<Filter[]>([]);
    const [breakdownType, setBreakdownType] = useState<BreakdownType>();

    const {
        actions: { setAnalyticsURL, setIsSavedChartsInfoDialogVisible },
        selectors: { getWorkspace, getAccessLevel, getTags },
    } = useStore();

    const tags = getTags();
    const equivalentPredefinedChart = useMemo(
        () => findPredefinedChart(tags, analysisType, analysisSubject, filters, breakdownType),
        [tags, analysisType, analysisSubject, filters, breakdownType]
    );

    const workspace = getWorkspace();
    const accessLevel = getAccessLevel();
    const isSavedChartsEnabled = isFeatureEnabled(workspace, Feature.SavedCharts);

    const { actions: { addSavedChartData } } = useDataCacheStore();

    function handleAnalysisTypeChange(newAnalysisType: AnalysisType) {
        const newSearchParams = new URLSearchParams(searchParams);

        if (newAnalysisType === AnalysisType.LatestData) {
            newSearchParams.delete("type");
        } else {
            newSearchParams.set("type", newAnalysisType);
        }

        setSearchParams(newSearchParams, { replace: true });
    }

    function handleAnalysisSubjectChange(newAnalysisSubject: AnalysisSubject) {
        const newSearchParams = new URLSearchParams(searchParams);

        if (newAnalysisSubject) {
            newSearchParams.set("subject", newAnalysisSubject);
        } else {
            newSearchParams.delete("subject");
        }

        setSearchParams(newSearchParams, { replace: true });
    }

    function handleFiltersChange(newFilters: Filter[]) {
        const newSearchParams = new URLSearchParams(searchParams);

        if (newFilters.length) {
            newSearchParams.set("filters", LZString.compressToEncodedURIComponent(JSON.stringify(newFilters)));
        } else {
            newSearchParams.delete("filters");
        }

        if (analysisSubject === undefined) {
            newSearchParams.set("subject", AnalysisSubject.Components);
        }

        setSearchParams(newSearchParams, { replace: true });
    }

    function handleBreakdownTypeChange(newBreakdownType: BreakdownType | undefined) {
        const newSearchParams = new URLSearchParams(searchParams);

        if (newBreakdownType) {
            newSearchParams.set("breakdown", newBreakdownType);
        } else {
            newSearchParams.delete("breakdown");
        }

        setSearchParams(newSearchParams, { replace: true });
    }

    function handleSaveButtonClick() {
        trackSaveChartButtonClick();

        if (isSavedChartsEnabled) {
            setSaveChartPopoverOpen(true);
        } else {
            setIsSavedChartsInfoDialogVisible(true);
        }
    }

    async function handleChartSave(name: string, description: string) {
        try {
            setSaveChartPopoverOpen(false);

            const savedChart = await createSavedChart(workspaceSlug!, {
                name,
                description,
                analysisType,
                analysisSubject: analysisSubject!,
                filters,
                breakdownType,
            });

            trackCreateSavedChart({
                slug: savedChart.slug,
                name,
                description,
                analysisType,
                analysisSubject: analysisSubject!,
                breakdownType,
            });

            addSavedChartData(savedChart);

            const savedChartLink = generatePath(RoutePath.SavedChart, {
                workspaceSlug: workspaceSlug!,
                savedChartSlug: getHumanReadableSlug(savedChart.name, savedChart.slug),
            });

            navigate(savedChartLink);

            toast.show(
                <>
                    <span>Chart saved to dashboard&nbsp;•&nbsp;</span>
                    <Link
                        to={generatePath(RoutePath.SavedCharts, { workspaceSlug: workspaceSlug! })}
                        onClick={() => toast.hide()}>
                        Go to Saved Dashboard
                    </Link>
                </>,
                10000
            );
        } catch (error) {
            logError(error);
            alertError(error as Error);
        }
    }

    useEffect(() => {
        setAnalysisType(toAnalysisType(searchParams.get("type")));
        const newAnalysisSubject = toAnalysisSubject(searchParams.get("subject"));
        setAnalysisSubject(newAnalysisSubject);
        setBreakdownType(toBreakdownType(searchParams.get("breakdown"), newAnalysisSubject));

        const filtersParam = searchParams.get("filters");
        let newFilters: Filter[] = [];
        if (filtersParam) {
            try {
                newFilters = JSON.parse(LZString.decompressFromEncodedURIComponent(filtersParam)) as Filter[];
            } catch {
                newFilters = [];
            }
        }
        setFilters(newFilters);

        const pathname = generatePath(RoutePath.NewAnalytics, { workspaceSlug: workspaceSlug! });
        setAnalyticsURL(`${pathname}?${searchParams.toString()}`);
    }, [searchParams]);

    function getTitle() {
        if (equivalentPredefinedChart?.title) {
            return equivalentPredefinedChart.title;
        }

        if (analysisType === AnalysisType.LatestData) {
            switch (analysisSubject) {
                case AnalysisSubject.Components:
                    return "Components sorted by usage";
                case AnalysisSubject.Projects:
                    return "Component usage by project";
                case AnalysisSubject.Tags:
                    return "Component usage by tag";
            }
        } else {
            switch (analysisSubject) {
                case AnalysisSubject.Components:
                    return "Component usage over time";
                case AnalysisSubject.Projects:
                    return "Component usage over time by project";
                case AnalysisSubject.Tags:
                    return "Component usage over time by tag";
            }
        }

        return "";
    }

    function getDescription() {
        if (equivalentPredefinedChart?.description) {
            return equivalentPredefinedChart.description;
        }

        if (analysisType === AnalysisType.LatestData) {
            switch (analysisSubject) {
                case AnalysisSubject.Components:
                    return "Total number of usages across all projects for each component";
                case AnalysisSubject.Projects:
                    return "Total number of component usages for each project";
                case AnalysisSubject.Tags:
                    return "Total number of component usages for each tag";
            }
        } else {
            switch (analysisSubject) {
                case AnalysisSubject.Components:
                    return "Change in number of usages for each component";
                case AnalysisSubject.Projects:
                    return "Change in number of component usages for each project";
                case AnalysisSubject.Tags:
                    return "Comparison of usage change for each tag";
            }
        }

        return "";
    }

    function renderSaveButton() {
        return (
            <Button
                ref={saveChartButtonRef}
                active={saveChartPopoverOpen}
                icon={<IconBookmark/>}
                title={accessLevel === AccessLevel.ReadOnly ? "Saving charts not available in demo workspace" : undefined}
                onClick={handleSaveButtonClick}
                disabled={accessLevel === AccessLevel.ReadOnly || !analysisType || !analysisSubject}>
                Save
            </Button>
        );
    }

    return (
        <>
            <Analytics
                workspaceSlug={workspaceSlug!}
                analysisType={analysisType}
                analysisSubject={analysisSubject}
                filters={filters}
                breakdownType={breakdownType}
                titleText={getTitle()}
                title={getTitle()}
                description={getDescription()}
                chartActions={renderSaveButton()}
                equivalentPredefinedChartType={equivalentPredefinedChart?.chartType}
                onAnalysisTypeChange={handleAnalysisTypeChange}
                onAnalysisSubjectChange={handleAnalysisSubjectChange}
                onFiltersChange={handleFiltersChange}
                onBreakdownTypeChange={handleBreakdownTypeChange}/>
            {saveChartPopoverOpen && (
                <SaveChartPopover
                    anchor={saveChartButtonRef.current!}
                    chartName={getTitle()}
                    chartDescription={getDescription()}
                    onSave={handleChartSave}
                    onCancel={() => setSaveChartPopoverOpen(false)}/>
            )}
        </>
    );
}
