import { type DataAnalysis } from "../../models/DataAnalysis";
import { type SavedChart } from "../../models/SavedChart";

import { type DataCacheState } from "./DataCacheState";

export function getSelectors(state: DataCacheState) {
    return {
        getAnalysisData(cacheKey: string): DataAnalysis[] | null {
            const { analysisDataCache } = state;
            return analysisDataCache[cacheKey] ?? null;
        },
        getSavedChartsData(): SavedChart[] | null {
            const { savedCharts } = state;
            return savedCharts;
        },
    };
}
