import { useEffect, useRef, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { getAnalyses, deleteAnalysis, getWorkspace as getWorkspaceBySlug } from "../../api/api";
import { H2 } from "../../library/Heading/Heading";
import { logError } from "../../logger";
import { AccessLevel } from "../../models/AccessLevel";
import { type Analysis } from "../../models/Analysis";
import { useStore } from "../../providers/StoreProvider/StoreProvider";
import { formatDate } from "../../utils";

import { Analyses } from "./analyses/Analyses";

import classes from "./AllScans.module.css";

const LIMIT = 20;

export function AllScans() {
    const navigate = useNavigate();
    const { workspaceSlug } = useParams();
    const {
        selectors: { getAccessLevel, getWorkspace },
        actions: { setWorkspace },
    } = useStore();

    const [analyses, setAnalyses] = useState<Analysis[]>([]);
    const [isRemoving, setIsRemoving] = useState(false);
    const nextRef = useRef<string>();
    const accessLevel = getAccessLevel();
    const workspace = getWorkspace();

    async function fetchData(params?: Record<string, string>, shouldAppend = true) {
        try {
            const analysesResponse = await getAnalyses(workspaceSlug!, params);

            if (shouldAppend) {
                setAnalyses(analyses => [...analyses, ...analysesResponse.analyses]);
            } else {
                setAnalyses(analysesResponse.analyses);
            }

            nextRef.current = analysesResponse.next;
        } catch (error) {
            logError(error);
        }
    }

    function handleEnd() {
        if (!nextRef.current) {
            return;
        }

        fetchData({
            limit: String(LIMIT),
            next: nextRef.current,
        });
    }

    async function fetchWorkspace() {
        try {
            const { workspace, accessLevel } = await getWorkspaceBySlug(workspaceSlug!);

            setWorkspace(workspace, accessLevel);
        } catch (error) {
            navigate("/", { replace: true });
            logError(error);
        }
    }

    async function handleDelete(id: string) {
        const analysis = analyses.find(({ id: aid }) => aid === id)!;
        const scanDate = formatDate(analysis.createdAt, { dateStyle: "medium", timeStyle: "short" });

        try {
            if (window.confirm(`Delete scan on ${scanDate}?`)) {
                setIsRemoving(true);
                await deleteAnalysis(workspaceSlug!, id);
                setIsRemoving(false);
                setAnalyses(analyses => analyses.filter(({ id: aid }) => aid !== id));
                await fetchWorkspace();
            }
        } catch (error) {
            logError(error);
            setIsRemoving(false);
        }
    }

    useEffect(() => {
        nextRef.current = undefined;

        fetchData({ limit: String(LIMIT) }, false);
    }, [workspaceSlug]);

    if (!workspace) {
        return null;
    }

    return (
        <main className={classes.allScans}>
            <H2 className={classes.title}>All Scans</H2>
            <Analyses
                analyses={analyses}
                limit={LIMIT}
                readOnly={accessLevel !== AccessLevel.Full}
                isRemoving={isRemoving}
                subscription={workspace.subscription}
                dataRetentionDate={workspace.dataRetentionLimitDate}
                onEnd={handleEnd}
                onDelete={handleDelete}/>
        </main>
    );
}
