import { useEffect } from "react";

import { generatePath, useNavigate, useParams } from "react-router-dom";

import { getMe, getWorkspace as getWorkspaceBySlug } from "../../api/api";
import {
    ComponentLimitExceededInstructions,
} from "../../common/ComponentLimitExceededInstructions/ComponentLimitExceededInstructions";
import { Header } from "../../containers/header/Header";
import { MainHeaderButton } from "../../containers/header/mainHeaderButton/MainHeaderButton";
import { PricingTableDialog } from "../../containers/pricingTableDialog/PricingTableDialog";
import { LogoLock } from "../../library/logos/LogoLock";
import { logError } from "../../logger";
import { useStore } from "../../providers/StoreProvider/StoreProvider";
import { RoutePath } from "../../RoutePath";
import { trackContactUsLinkClick } from "../../tracking";

import classes from "./ComponentLimitExceeded.module.css";

export function ComponentLimitExceeded() {
    const { workspaceSlug } = useParams();
    const navigate = useNavigate();

    const {
        actions: { setUser, setWorkspace, openPricingTableDialog },
        selectors: { getUser, getWorkspace },
    } = useStore();

    const user = getUser();
    const workspace = getWorkspace();

    useEffect(() => {
        async function fetchData() {
            try {
                const [user, { workspace, accessLevel }] = await Promise.all([
                    getMe(),
                    getWorkspaceBySlug(workspaceSlug!),
                ]);

                setUser(user);
                setWorkspace(workspace, accessLevel);

                if (!workspace.componentLimitGracePeriodEndAt || workspace.componentLimitGracePeriodEndAt > new Date()) {
                    navigate(generatePath(RoutePath.RepoHome, { workspaceSlug: workspace.slug }), { state: { fromApp: true } });
                }
            } catch (error) {
                navigate("/", { replace: true });
                logError(error);
            }
        }

        fetchData();
    }, []);

    function handleContactUsClick() {
        trackContactUsLinkClick({ source: "component_limit_exceeded_page" });
    }

    function handlePricingClick() {
        openPricingTableDialog("component_limit_exceeded_page");
    }

    if (!workspace) {
        return null;
    }

    return (
        <>
            <Header
                leftContent={user && <MainHeaderButton showAllScans/>}
                hideRightContent/>
            <main className={classes.main}>
                <div className={classes.content}>
                    <LogoLock />
                    <ComponentLimitExceededInstructions
                        workspace={workspace}
                        onPricingClick={handlePricingClick}
                        onContactUsClick={handleContactUsClick}/>
                </div>
            </main>
            <PricingTableDialog/>
        </>
    );
}
