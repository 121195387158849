import { useEffect } from "react";

import { Outlet, generatePath, useParams } from "react-router-dom";

import { useStore } from "../../providers/StoreProvider/StoreProvider";
import { RoutePath } from "../../RoutePath";

export function AnalyticsShell() {
    const { workspaceSlug } = useParams();

    const { actions: { setComponentsURL, setComponentsScrollPosition } } = useStore();

    useEffect(() => {
        setComponentsURL(generatePath(RoutePath.Components, { workspaceSlug: workspaceSlug! }));
        setComponentsScrollPosition(0);
    }, []);

    return <Outlet/>;
}
