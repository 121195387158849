import { type DropdownOption } from "../../../../library/Dropdown/DropdownOption";
import { H3 } from "../../../../library/Heading/Heading";
import { type AnalysisSubject } from "../../../../models/AnalysisSubject";
import { getAnalysisTypeLabel, AnalysisType } from "../../../../models/AnalysisType";
import { WidgetDropdownType, WidgetDropdown } from "../widgetDropdown/WidgetDropdown";

import { AnalysisSubjectPicker } from "./analysisSubjectPicker/AnalysisSubjectPicker";

import classes from "./AnalyzeWidget.module.css";

interface Props {
    analysisType: AnalysisType;
    analysisSubject?: AnalysisSubject;
    disabled?: boolean;
    onTypeChange(type: AnalysisType): void;
    onSubjectChange(subject: AnalysisSubject): void;
}

function getAnalysisTypeOption(analysisType: AnalysisType): DropdownOption<AnalysisType> {
    return {
        value: analysisType,
        label: getAnalysisTypeLabel(analysisType),
    };
}

export function AnalyzeWidget({
    analysisType,
    analysisSubject,
    disabled = false,
    onTypeChange,
    onSubjectChange,
}: Props) {
    return (
        <div className={classes.analyzeWidget}>
            <H3 className={classes.title}>Analyze</H3>
            <WidgetDropdown
                className={classes.analysisTypePicker}
                type={WidgetDropdownType.Minimal}
                options={Object.values(AnalysisType).map(getAnalysisTypeOption)}
                optionsOffset={12}
                value={analysisType}
                disabled={disabled}
                onChange={onTypeChange}/>
            <AnalysisSubjectPicker
                subject={analysisSubject}
                disabled={disabled}
                onChange={onSubjectChange}/>
        </div>
    );
}
