import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { useStore } from "../../providers/StoreProvider/StoreProvider";

export function PaymentSuccess() {
    const { actions: { openPaymentSuccessDialog } } = useStore();
    const navigate = useNavigate();

    useEffect(() => {
        openPaymentSuccessDialog();
        navigate("../", { replace: true });
    }, []);
    return null;
}
