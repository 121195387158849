import { useRef, useState } from "react";

import { LandingPageButtonKind, LandingPageButton } from "../landingPageButton/LandingPageButton";

import { TalkToAnExpertPopup } from "./talkToAnExpertPopup/TalkToAnExpertPopup";

export function TalkToAnExpertButton() {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    function handleButtonClick() {
        setIsPopupOpen(true);
    }

    function handlePopupCancel() {
        setIsPopupOpen(false);
    }
    return (
        <>
            <LandingPageButton label="Talk to an expert" onClick={handleButtonClick} kind={LandingPageButtonKind.Secondary} ref={buttonRef}/>
            {isPopupOpen && <TalkToAnExpertPopup anchor={buttonRef.current!} onCancel={handlePopupCancel}/>}
        </>
    );
}
