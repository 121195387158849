import { type State } from "./State";

export function getSelectors(state: State) {
    return {
        getUser() {
            const { user } = state;
            return user;
        },
        getWorkspace() {
            const { workspace } = state;
            return workspace;
        },
        getMembers(){
            const { members } = state;
            return members;
        },
        getAccessLevel() {
            const { accessLevel } = state;
            return accessLevel;
        },
        getTags() {
            const { workspace } = state;
            return workspace?.tags ?? [];
        },
        getIsCreateNewAnalysisButtonVisible() {
            const { isCreateNewAnalysisButtonVisible } = state;
            return isCreateNewAnalysisButtonVisible;
        },
        getIsSetupRegularScansDialogVisible() {
            const { isSetupRegularScansDialogVisible } = state;
            return isSetupRegularScansDialogVisible;
        },
        getIsScanMoreProjectsDialogVisible() {
            const { isScanMoreProjectsDialogVisible } = state;
            return isScanMoreProjectsDialogVisible;
        },
        getIsAddMoreTagsDialogVisible() {
            const { isAddMoreTagsDialogVisible } = state;
            return isAddMoreTagsDialogVisible;
        },
        getIsSubscriptionInfoDialogVisible() {
            const { isSubscriptionInfoDialogVisible } = state;
            return isSubscriptionInfoDialogVisible;
        },
        getIsBillingDialogVisible() {
            const { isBillingDialogVisible } = state;
            return isBillingDialogVisible;
        },
        getIsPricingTableDialogVisible() {
            const { isPricingTableDialogVisible } = state;
            return isPricingTableDialogVisible;
        },
        getIsSavedChartsInfoDialogVisible() {
            const { isSavedChartsInfoDialogVisible } = state;
            return isSavedChartsInfoDialogVisible;
        },
        getIsPaymentSuccessDialogVisible() {
            const { isPaymentSuccessDialogVisible } = state;
            return isPaymentSuccessDialogVisible;
        },
        getAnalyticsURL() {
            const { analyticsURL } = state;
            return analyticsURL;
        },
        getComponentsURL() {
            const { componentsURL } = state;
            return componentsURL;
        },
        getDashboardURL() {
            const { dashboardURL } = state;
            return dashboardURL;
        },
        getComponentsScrollPosition() {
            const { componentsScrollPosition } = state;
            return componentsScrollPosition;
        },
        getDashboardScrollPosition() {
            const { dashboardScrollPosition } = state;
            return dashboardScrollPosition;
        },
    };
}
