import { useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import { generatePath, useNavigate, useParams } from "react-router-dom";

import { config } from "../../../config/frontend";
import { getMe, getWorkspace as getWorkspaceBySlug } from "../../api/api";
import { Header } from "../../containers/header/Header";
import { MainHeaderButton } from "../../containers/header/mainHeaderButton/MainHeaderButton";
import { PricingTableDialog } from "../../containers/pricingTableDialog/PricingTableDialog";
import { Button, ButtonAnchor, ButtonKind } from "../../library/Button/Button";
import { CodeSnippet } from "../../library/CodeSnippet/CodeSnippet";
import { H2, H3 } from "../../library/Heading/Heading";
import { LogoLock } from "../../library/logos/LogoLock";
import { logError } from "../../logger";
import { subscriptionPlanToString } from "../../models/SubscriptionPlan";
import { useStore } from "../../providers/StoreProvider/StoreProvider";
import { RoutePath } from "../../RoutePath";
import { trackContactUsLinkClick } from "../../tracking";
import { pluralize } from "../../utils";

import classes from "./DataRetentionLimitReached.module.css";

const SHOW_MORE_THRESHOLD = 6;

interface RepositorySnippetProps {
    repositories: string[];
}

function RepositorySnippet({ repositories }: RepositorySnippetProps) {
    const [showMoreClicked, setShowMoreClicked] = useState(false);
    const showAll = repositories.length <= SHOW_MORE_THRESHOLD || showMoreClicked;
    const visibleRepositories = showAll ? repositories : repositories.slice(0, SHOW_MORE_THRESHOLD);

    function handleShowMoreClick() {
        setShowMoreClicked(true);
    }
    return (
        <CodeSnippet className={classNames(classes.repositorySnippet, classes.codeSnippet)} code={repositories.join("\n")} disableCopy>
            <div className={classes.column}>
                {visibleRepositories.join("\n")}
                {!showAll && (
                    <button
                        className={classes.showMoreButton}
                        type="button"
                        onClick={handleShowMoreClick}>
                        Show more
                    </button>
                )}
            </div>
        </CodeSnippet>
    );
}

export function DataRetentionLimitReached() {
    const { workspaceSlug } = useParams();
    const navigate = useNavigate();

    const {
        actions: { setUser, setWorkspace, openPricingTableDialog },
        selectors: { getUser, getWorkspace },
    } = useStore();

    const user = getUser();
    const workspace = getWorkspace();

    useEffect(() => {
        async function fetchData() {
            try {
                const [user, { workspace, accessLevel }] = await Promise.all([
                    getMe(),
                    getWorkspaceBySlug(workspaceSlug!),
                ]);

                setUser(user);
                setWorkspace(workspace, accessLevel);

                if (!workspace.hasReachedDataRetentionLimit) {
                    navigate(generatePath(RoutePath.RepoHome, { workspaceSlug: workspace.slug }), { state: { fromApp: true } });
                }
            } catch (error) {
                navigate("/", { replace: true });
                logError(error);
            }
        }

        fetchData();
    }, []);


    const repositories = useMemo(() => {
        const repositories = workspace?.projects.map(({ repository }) => repository?.scope && repository?.name ? `${repository.scope}/${repository.name}` : "") ?? [];
        return [...new Set(repositories.filter(Boolean))];
    }, [workspace]);

    function handleContactUsClick() {
        trackContactUsLinkClick({ source: "data_retention_limit_reached_page" });
    }

    function handlePricingClick() {
        openPricingTableDialog("data_retention_limit_reached_page");
    }

    if (!workspace) {
        return null;
    }

    return (
        <>
            <Header
                leftContent={user && <MainHeaderButton/>}
                hideRightContent/>
            <main className={classes.main}>
                <div className={classes.content}>
                    <LogoLock />
                    <div className={classes.sections}>
                        <section>
                            <H2 className={classes.h2}>
                                No scans in the last
                                {" "}
                                {pluralize("day", workspace.subscription.features.dataRetentionLimit)}
                            </H2>
                            <p>
                                You’re currently on the
                                {" "}
                                {subscriptionPlanToString(workspace.subscription.plan)}
                                {" "}
                                plan with
                                {" "}
                                {pluralize("day", workspace.subscription.features.dataRetentionLimit)}
                                {" "}
                                of scan retention, but looks like you haven’t done any scans in the last
                                {" "}
                                {pluralize("day", workspace.subscription.features.dataRetentionLimit)}.
                                To continue using Omlet, please rescan your codebase or upgrade your plan for longer scan retention.
                            </p>
                        </section>
                        <section>
                            <H3>
                                Re-scan your codebase for fresh data
                            </H3>
                            <p>
                                Navigate to your repo and re-run Omlet CLI to collect latest component usage data.
                            </p>
                            <CodeSnippet.Tabbed
                                className={classes.codeSnippet}
                                code={{
                                    npm: "npx @omlet/cli analyze",
                                    yarn: "yarn dlx @omlet/cli analyze",
                                    pnpm: "pnpm dlx @omlet/cli analyze",
                                }}/>
                            <p>
                                Here are all the repos you previously scanned, in case you want to re-scan them all.
                            </p>
                            <RepositorySnippet repositories={repositories}/>
                        </section>
                        <strong>
                            ...or
                        </strong>
                        <section>
                            <H3>Upgrade your plan</H3>
                            <p>
                                See plans below and upgrade for longer scan retention. Once you upgrade, data from your existing scans will be unarchived and accessible.
                            </p>
                            <div className={classes.row}>
                                <Button kind={ButtonKind.Primary} onClick={handlePricingClick}>
                                    See plans
                                </Button>
                                <ButtonAnchor
                                    kind={ButtonKind.Secondary}
                                    href={`${config.LANDING_PAGE_BASE_URL}/contact/`}
                                    rel="nofollow external noopener noreferrer"
                                    target="_blank"
                                    onClick={handleContactUsClick}>
                                    Contact us
                                </ButtonAnchor>
                            </div>
                        </section>
                    </div>
                </div>
            </main>
            <PricingTableDialog/>
        </>
    );
}
