import { useEffect } from "react";

import { generatePath, useNavigate, useParams } from "react-router-dom";

import { getMe, getWorkspace as getWorkspaceBySlug } from "../../api/api";
import { Header } from "../../containers/header/Header";
import { MainHeaderButton } from "../../containers/header/mainHeaderButton/MainHeaderButton";
import { showNewMessage } from "../../intercom";
import { Button } from "../../library/Button/Button";
import { Illustration, LogoCard } from "../../library/LogoCard/LogoCard";
import { logError } from "../../logger";
import { SubscriptionPlan } from "../../models/SubscriptionPlan";
import { useStore } from "../../providers/StoreProvider/StoreProvider";
import { RoutePath } from "../../RoutePath";

import classes from "./TrialEnd.module.css";

export function TrialEnd() {
    const { workspaceSlug } = useParams();
    const navigate = useNavigate();

    const {
        actions: {
            setUser,
            setWorkspace,
        },
    } = useStore();

    async function fetchUser() {
        try {
            const user = await getMe();

            setUser(user);
        } catch (error) {
            logError(error);
        }
    }

    async function fetchWorkspace() {
        try {
            if (!workspaceSlug) {
                return;
            }
            const { workspace, accessLevel } = await getWorkspaceBySlug(workspaceSlug);

            setWorkspace(workspace, accessLevel);

            if (workspace.subscription.plan !== SubscriptionPlan.TrialEnded) {
                navigate(generatePath(RoutePath.RepoHome, { workspaceSlug }), { replace: true });
            }
        } catch (error) {
            navigate("/", { replace: true });
            logError(error);
        }
    }

    function handleClick() {
        showNewMessage("Extend trial: ");
    }

    useEffect(() => {
        fetchUser();
    }, []);

    useEffect(() => {
        fetchWorkspace();
    }, [workspaceSlug]);

    return (
        <>
            <Header
                leftContent={<MainHeaderButton/>}
                hideRightContent/>
            <main className={classes.main}>
                <div className={classes.column}>
                    <LogoCard title="30-day trial has ended" illustration={Illustration.Angel}>
                        <p className={classes.text}>
                            To give Omlet another go, contact us to extend your trial.
                        </p>
                    </LogoCard>
                    <Button className={classes.button} onClick={handleClick}>Contact us</Button>
                </div>
            </main>
        </>
    );
}
