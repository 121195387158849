import { Fragment, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";

import classNames from "classnames";
import LZString from "lz-string";
import { generatePath, useParams } from "react-router-dom";

import { getDataAnalysis } from "../../api/api";
import { getDataCacheKey } from "../../api/urlBuilder";
import { AreaChart } from "../../common/chart/areaChart/AreaChart";
import { SingleDataAreaChart } from "../../common/chart/areaChart/SingleDataAreaChart";
import { LineChart } from "../../common/chart/lineChart/LineChart";
import { SingleDataLineChart } from "../../common/chart/lineChart/SingleDataLineChart";
import { getLegendItems } from "../../common/chart/utils";
import { transform } from "../../containers/analytics/dataTransformer";
import { Loading } from "../../library/Loading/Loading";
import { Tooltip } from "../../library/Tooltip/Tooltip";
import { logError } from "../../logger";
import { AccessLevel } from "../../models/AccessLevel";
import { AnalysisType } from "../../models/AnalysisType";
import { getCoreTag, getNonCoreTag, RESERVED_TAGS, type Tag } from "../../models/Tag";
import { useDataCacheStore } from "../../providers/DataCacheProvider/DataCacheProvider";
import { useStore } from "../../providers/StoreProvider/StoreProvider";
import { RoutePath } from "../../RoutePath";
import { trackDashboardPlayButton, trackDashboardTagChange } from "../../tracking";

import {
    getDashboardSections,
    getMainChart,
    getPredefinedCharts,
    getPredefinedTables,
    PredefinedChartType,
    type PredefinedTableType,
} from "./constants";
import { DashboardSection } from "./dashboardSection/DashboardSection";
import { DashboardSectionHeader } from "./dashboardSectionHeader/DashboardSectionHeader";
import { PlayButton } from "./dashboardSectionHeader/playButton/PlayButton";
import { DashboardTable } from "./dashboardTable/DashboardTable";
import { DropdownTags } from "./dropdownTags/DropdownTags";
import { FirstScanTooltip } from "./firstScanTooltip/FirstScanTooltip";
import { ChartInsight } from "./insight/ChartInsight";
import { PopularDashboardChart } from "./popularDashboardChart/PopularDashboardChart";
import { SetupSteps } from "./setupSteps/SetupSteps";
import { YoutubePlayer } from "./youtubePlayer/YoutubePlayer";

import classes from "./PopularCharts.module.css";

function EmptyState() {
    return (
        <p className={classes.emptyState}>
            No results with this selection.
        </p>
    );
}

interface StrongTextWithTooltipProps {
    text: string;
    tooltip: string;
}

function StrongTextWithTooltip({
    text,
    tooltip,
}: StrongTextWithTooltipProps) {
    const ref = useRef<HTMLElement>(null);

    return (
        <Tooltip content={tooltip}>
            <strong
                className={classes.strongTextWithTooltip}
                ref={ref}>
                {text}
            </strong>
        </Tooltip>
    );
}

function areAllLoaded(loadedEntities: Record<string, boolean>): boolean {
    return Object.values(loadedEntities).every(isLoaded => isLoaded);
}

export function PopularCharts() {
    const { workspaceSlug } = useParams();

    const scrollPositionRef = useRef<number>(0);
    const mainRef = useRef<HTMLElement>(null);

    const [youtubeVideoId, setYoutubeVideoId] = useState<string | null>(null);

    const {
        selectors: {
            getTags,
            getWorkspace,
            getAccessLevel,
            getDashboardScrollPosition,
        },
        actions: {
            setAnalyticsURL,
            setDashboardURL,
            setDashboardScrollPosition,
        },
    } = useStore();

    const initialScrollPosition = getDashboardScrollPosition();
    const workspace = getWorkspace();
    const accessLevel = getAccessLevel();
    const projects = workspace?.projects ?? [];
    const projectMap = Object.fromEntries(projects.map(p => [p.packageName, p]));
    const tags = getTags();
    const coreTag = useMemo(() => getCoreTag(tags), [tags]);
    const nonCoreTag = getNonCoreTag(coreTag);
    const allTags = [...tags, nonCoreTag, RESERVED_TAGS.UNTAGGED];
    const [mainChartComparedTag, setMainChartComparedTag] = useState<Tag>(nonCoreTag);
    const reservedTagSlugs = Object.values(RESERVED_TAGS).map(({ slug }) => slug);
    const userDefinedTags = useMemo(() => tags.filter(({ slug }) => !reservedTagSlugs.includes(slug)), [tags]);
    const hasUserDefinedTags = userDefinedTags.length > 0;
    const dashboardSections = useMemo(() => getDashboardSections(hasUserDefinedTags), [hasUserDefinedTags]);
    const [loadedCharts, setLoadedCharts] = useState(
        Object.fromEntries(
            dashboardSections.filter(({ charts }) => charts !== undefined)
                .flatMap(({ charts }) => charts!.map(chart => [chart, false]))
        )
    );
    const [loadedTables, setLoadedTables] = useState(
        Object.fromEntries(
            dashboardSections.filter(({ tables }) => tables !== undefined)
                .flatMap(({ tables }) => tables!.map(table => [table, false]))
        )
    );

    const tagMap = useMemo(() => Object.fromEntries(tags.map(t => [t.slug, t])), [tags]);
    const predefinedCharts = useMemo(() => getPredefinedCharts(coreTag, hasUserDefinedTags), [coreTag, hasUserDefinedTags]);
    const predefinedTables = useMemo(() => getPredefinedTables(coreTag), [coreTag]);

    const {
        selectors: { getAnalysisData },
        actions: { cacheAnalysisData },
    } = useDataCacheStore();

    const { analysisType, analysisSubject, filters, nonCoreFilters, title } = getMainChart(coreTag, mainChartComparedTag, hasUserDefinedTags);
    const mainChartDataCacheKey = getDataCacheKey({ workspaceSlug: workspaceSlug!, analysisType, analysisSubject, filters, nonCoreFilters });
    const analysisData = getAnalysisData(mainChartDataCacheKey);

    const chartData = useMemo(() =>
        analysisData && transform(analysisData, projectMap, allTags, analysisType, analysisSubject, undefined, workspaceSlug!)
    , [analysisData]);

    function handleChartLoad(chartType: PredefinedChartType) {
        setLoadedCharts(charts => ({ ...charts, [chartType]: true }));
    }

    function handleTableLoad(tableType: PredefinedTableType) {
        setLoadedTables(tables => ({ ...tables, [tableType]: true }));
    }

    useEffect(() => {
        if (tags.length === 0) {
            return;
        }

        async function fetchData() {
            try {
                let dataAnalysis = await getDataAnalysis(workspaceSlug!, analysisType, analysisSubject, filters);
                if (nonCoreFilters) {
                    const nonCoreDataAnalysis = await getDataAnalysis(workspaceSlug!, analysisType, analysisSubject, nonCoreFilters);
                    nonCoreDataAnalysis.forEach(({ key }) => key.childTag = nonCoreTag.slug);

                    dataAnalysis = dataAnalysis.concat(nonCoreDataAnalysis);
                }

                cacheAnalysisData(mainChartDataCacheKey, dataAnalysis);
            } catch (error) {
                logError(error);
            }
        }

        fetchData();
    }, [tags, mainChartComparedTag]);

    useEffect(() => {
        return () => {
            setDashboardScrollPosition(scrollPositionRef.current);
        };
    }, []);

    useEffect(() => {
        if (!areAllLoaded(loadedCharts) || !areAllLoaded(loadedTables) || !mainRef.current || !initialScrollPosition) {
            return;
        }
        mainRef.current.scrollTop = initialScrollPosition;

        if (mainRef.current.scrollTop === initialScrollPosition) {
            setDashboardScrollPosition(undefined);
        }
    }, [loadedCharts, loadedTables, initialScrollPosition]);

    useLayoutEffect(() => {
        const dashboardURL = generatePath(RoutePath.Dashboard, { workspaceSlug: workspaceSlug! });
        setAnalyticsURL(dashboardURL);
        setDashboardURL(dashboardURL);
    }, []);

    function handleScroll() {
        scrollPositionRef.current = mainRef.current!.scrollTop;
    }

    function handlePlayButtonClick(title: string, videoId: string) {
        trackDashboardPlayButton({ section: title, videoId });
        setYoutubeVideoId(videoId);
    }

    function handleYoutubePlayerClose() {
        setYoutubeVideoId(null);
    }

    function handleTagChange(value: Tag) {
        trackDashboardTagChange({ tag: value.slug });
        setMainChartComparedTag(value);
    }

    function renderComponentAdoptionOverTimeDescription() {
        return (
            <>
                Change in
                {" "}
                <StrongTextWithTooltip text={coreTag.name} tooltip={RESERVED_TAGS.CORE.tooltip}/>
                {" "}
                vs.
                {" "}
                <DropdownTags
                    workspaceSlug={workspaceSlug!}
                    tags={tags.filter(tag => tag.isActive)}
                    value={mainChartComparedTag}
                    linksDisabled={accessLevel === AccessLevel.Page}
                    onChange={handleTagChange}/>
                {" "}
                component usage over time
            </>
        );
    }

    function renderMainChartDescription() {
        if (userDefinedTags.length === 0) {
            return (
                <>
                    Change in total
                    {" "}
                    <StrongTextWithTooltip text={coreTag.name} tooltip={RESERVED_TAGS.CORE.tooltip}/>
                    {" "}
                    component usage over time
                </>
            );
        }

        return renderComponentAdoptionOverTimeDescription();
    }

    function renderMainChart() {
        if (!chartData) {
            return <Loading className={classes.loading}/>;
        }

        const searchParams = new URLSearchParams({
            type: analysisType,
            subject: analysisSubject,
        });

        if (!nonCoreFilters?.length) {
            searchParams.set("filters", LZString.compressToEncodedURIComponent(JSON.stringify(filters)));
        }

        function renderChart() {
            if (!chartData) {
                return null;
            }

            if (chartData.length === 0) {
                return <EmptyState/>;
            }

            if (chartData.length > 1) {
                const legendItems = getLegendItems(chartData, projectMap, tags, analysisType, analysisSubject);

                if (userDefinedTags.length === 0) {
                    return (
                        <LineChart
                            className={classes.componentAdoptionOverTimeChart}
                            margin={{ top: 5, right: 40, bottom: 48, left: 49 }}
                            data={chartData}
                            axisBottomItemCountHint={10}
                            tagMap={tagMap}/>
                    );
                }

                return (
                    <AreaChart
                        className={classes.componentAdoptionOverTimeChart}
                        data={chartData}
                        margin={{ top: 5, right: 40, bottom: 48, left: 49 }}
                        axisBottomTickPadding={16}
                        axisBottomTickSize={0}
                        gridLineColor="rgba(var(--background-tertiary-color-rgb), 0.2)"
                        drawGridOnTop
                        legendItems={legendItems}/>
                );
            }

            if (userDefinedTags.length === 0) {
                return (
                    <>
                        <SingleDataLineChart
                            className={classes.componentAdoptionOverTimeChart}
                            margin={{ top: 5, right: 21, bottom: 48, left: 49 }}
                            data={chartData[0]}
                            tagMap={tagMap}/>
                        <FirstScanTooltip/>
                    </>
                );
            }

            return (
                <>
                    <SingleDataAreaChart
                        className={classes.componentAdoptionOverTimeChart}
                        margin={{ top: 5, right: 21, bottom: 48, left: 49 }}
                        data={chartData[0]}/>
                    <FirstScanTooltip/>
                </>
            );
        }

        const link = accessLevel === AccessLevel.Page ? undefined : `view?${searchParams.toString()}`;

        return (
            <>
                <DashboardSectionHeader
                    className={classes.mainHeader}
                    link={link}
                    title={title}
                    description={renderMainChartDescription()}/>
                <div className={classes.chartContent}>
                    {renderChart()}
                    <SetupSteps
                        isRegularScansSetup={chartData.length > 2}
                        hasNonReservedTags={hasUserDefinedTags}/>
                </div>
            </>
        );
    }

    return (
        <main ref={mainRef} className={classes.popularCharts} onScroll={handleScroll}>
            <section className={classes.mainContent}>
                {renderMainChart()}
                {chartData && chartData.length > 1 && (
                    <ChartInsight
                        chartType={PredefinedChartType.ComponentAdoptionOverTime}
                        data={chartData}
                        analysisType={AnalysisType.DataOverTime}
                        comparedTag={mainChartComparedTag}/>
                )}
            </section>
            <div className={classNames(classes.sections, { [classes.hidden]: !areAllLoaded(loadedCharts) || !areAllLoaded(loadedTables) })}>
                {dashboardSections.map((section, index) => {
                    const charts = section.charts?.map(chart => predefinedCharts.find(({ chartType }) => chartType === chart)!);
                    const tables = section.tables?.map(table => predefinedTables.find(({ tableType }) => tableType === table)!);

                    return (
                        <Fragment key={`dashboard-section-${index}`}>
                            <DashboardSection
                                header={
                                    <DashboardSectionHeader
                                        title={section.title}
                                        description={section.description}
                                        rightContent={
                                            section.youtubeVideoId && <PlayButton onClick={() => handlePlayButtonClick(section.title, section.youtubeVideoId!)}/>
                                        }/>
                                }>
                                {
                                    charts?.map(chartParams => {
                                        const description = chartParams.chartType === PredefinedChartType.ComponentAdoptionOverTime
                                            ? renderComponentAdoptionOverTimeDescription()
                                            : chartParams.description;

                                        return (
                                            <PopularDashboardChart
                                                key={chartParams.chartType}
                                                workspaceSlug={workspaceSlug!}
                                                tags={allTags}
                                                projectMap={projectMap}
                                                chartType={chartParams.chartType}
                                                title={chartParams.title}
                                                description={description}
                                                analysisType={chartParams.analysisType}
                                                analysisSubject={chartParams.analysisSubject}
                                                filters={chartParams.filters}
                                                nonCoreFilters={chartParams.nonCoreFilters}
                                                fallbacks={chartParams.fallbacks}
                                                breakdownType={chartParams.breakdownType}
                                                emptyStateMessage={chartParams.emptyStateMessage}
                                                linksDisabled={accessLevel === AccessLevel.Page}
                                                readOnly={accessLevel === AccessLevel.ReadOnly}
                                                onLoad={handleChartLoad}/>
                                        );
                                    })
                                }
                                {
                                    tables?.map(tableParams =>
                                        <DashboardTable
                                            key={tableParams.tableType}
                                            workspaceSlug={workspaceSlug!}
                                            linksDisabled={accessLevel === AccessLevel.Page}
                                            onLoad={handleTableLoad}
                                            {...tableParams}/>
                                    )
                                }
                            </DashboardSection>
                            {index !== dashboardSections.length - 1 && <DashboardSection.Separator/>}
                        </Fragment>
                    );
                })}
            </div>
            {youtubeVideoId && <YoutubePlayer videoId={youtubeVideoId} onClose={handleYoutubePlayerClose}/>}
        </main>
    );
}
