import { useState } from "react";

import { createPortal } from "react-dom";

import {
    ComponentLimitExceededInstructions,
} from "../../common/ComponentLimitExceededInstructions/ComponentLimitExceededInstructions";
import { Callout, CalloutKind } from "../../library/Callout/Callout";
import { Dialog } from "../../library/Dialog/Dialog";
import { IconLock } from "../../library/icons/IconLock";
import { subscriptionPlanToString } from "../../models/SubscriptionPlan";
import { useStore } from "../../providers/StoreProvider/StoreProvider";
import { trackContactUsLinkClick } from "../../tracking";
import { getDaysBetween, pluralize } from "../../utils";

import classes from "./ComponentLimitExceededBanner.module.css";

function getHeader(periodEnd?: Date): string {
    if (!periodEnd) {
        return "Exceeded component limit";
    }

    const daysLeft = Math.max(1, getDaysBetween(new Date(), periodEnd));
    return daysLeft === 1 ? "Exceeded component limit — last day" : `Exceeded component limit — ${daysLeft} days left`;
}

export function ComponentLimitExceededBanner() {
    const {
        selectors: {
            getWorkspace,
        },
        actions: {
            openPricingTableDialog,
        },
    } = useStore();
    const workspace = getWorkspace();

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    if (!workspace?.subscription) {
        return null;
    }

    const totalNumOfComponents = workspace.subscription.features.numOfComponents;
    const usedNumOfComponents = workspace.numOfComponents;

    if (totalNumOfComponents >= usedNumOfComponents) {
        return null;
    }

    function handleButtonClick() {
        setIsDialogOpen(true);
    }

    function handleDialogClose() {
        setIsDialogOpen(false);
    }

    function handlePricingTableClick() {
        setIsDialogOpen(false);
        openPricingTableDialog("component_limit_exceeded_banner");
    }

    function handleContactUsClick() {
        trackContactUsLinkClick({ source: "component_limit_exceeded_banner" });
    }

    return (
        createPortal(
            <>
                {workspace.subscription.status === "trial"
                    ? (
                        <Callout
                            kind={CalloutKind.Default}
                            emoji="☝️"
                            className={classes.componentLimitExceededBanner}
                            header={`${pluralize("component", usedNumOfComponents)} scanned`}>
                            Your most recent scan includes {pluralize("component", usedNumOfComponents)}.
                            Once your trial is over, you can decide on a plan based on your number of components.
                            {" "}
                            <a
                                className={classes.cta}
                                href="/l/docs/pricing"
                                rel="nofollow external noopener noreferrer"
                                target="_blank">
                                Learn more
                            </a>
                        </Callout>
                    )
                    : (
                        <Callout
                            kind={CalloutKind.Critical}
                            emoji="🚒"
                            className={classes.componentLimitExceededBanner}
                            header={getHeader(workspace.componentLimitGracePeriodEndAt)}>
                            <div className={classes.content}>
                                You’re currently on the {subscriptionPlanToString(workspace.subscription.plan)}
                                {" "}
                                plan where you can analyze up to {totalNumOfComponents} components.
                                Your workspace is currently over the limit with a total of {usedNumOfComponents} components.
                                <button type="button" className={classes.cta} onClick={handleButtonClick}>
                                    See instructions
                                </button>
                            </div>
                        </Callout>
                    )
                }
                {isDialogOpen && (
                    <Dialog onClose={handleDialogClose} className={classes.componentLimitExceededDialog}>
                        <div className={classes.content}>
                            <IconLock />
                            <ComponentLimitExceededInstructions
                                workspace={workspace}
                                onPricingClick={handlePricingTableClick}
                                onContactUsClick={handleContactUsClick} />
                        </div>
                    </Dialog>
                )}
            </>
            ,
            document.body
        )
    );
}
