import { config } from "../../../../config/frontend";
import starterEmptyAnimationURL from "../../../assets/img/imgStarterEmpty.gif";
import { Button, ButtonAnchor, ButtonKind } from "../../../library/Button/Button";
import { Dialog } from "../../../library/Dialog/Dialog";
import { H2 } from "../../../library/Heading/Heading";
import { IconLock } from "../../../library/icons/IconLock";
import { useStore } from "../../../providers/StoreProvider/StoreProvider";
import { trackContactUsLinkClick } from "../../../tracking";

import classes from "./SavedChartsInfoDialog.module.css";

export function SavedChartsInfoDialog() {
    const { actions: { openPricingTableDialog, setIsSavedChartsInfoDialogVisible } } = useStore();

    function handlePricingClick() {
        setIsSavedChartsInfoDialogVisible(false);
        openPricingTableDialog("saved_charts_info_dialog");
    }

    function handleContactUsClick() {
        trackContactUsLinkClick({ source: "saved_charts_info_dialog" });
    }

    return (
        <Dialog
            className={classes.savedChartsInfoDialog}
            onClose={() => setIsSavedChartsInfoDialogVisible(false)}>
            <div className={classes.content}>
                <div className={classes.header}>
                    <IconLock/>
                    <H2 className={classes.h2}>
                        Build your custom dashboard with the Advanced Plan
                    </H2>
                    <p className={classes.p}>
                        With the Advanced Plan, you can save your frequently queried charts in your Saved Charts dashboard and quickly share it with your team.
                    </p>
                </div>
                <img
                    src={starterEmptyAnimationURL}
                    srcSet={`${starterEmptyAnimationURL} 2x`}
                    alt="Save chart from a new analysis"/>
                <div className={classes.footer}>
                    <p className={classes.p}>
                        Happy to chat about the plan details and pricing.
                    </p>
                    <div className={classes.actions}>
                        <ButtonAnchor
                            href={`${config.LANDING_PAGE_BASE_URL}/contact/`}
                            target="_blank"
                            onClick={handleContactUsClick}>
                            Contact us
                        </ButtonAnchor>
                        <Button
                            kind={ButtonKind.Secondary}
                            onClick={handlePricingClick}>
                            See plans
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}
