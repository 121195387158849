import { type AnalysisSubject } from "../models/AnalysisSubject";
import { type AnalysisType, getAnalysisTypeParam } from "../models/AnalysisType";
import { type Filter } from "../models/Filter";
import { intoDataAnalysisFilter } from "../models/Filter";

const base = "/api";

export function buildGetDataAnalysisURL(workspaceSlug: string, type: AnalysisType, subject: AnalysisSubject, filters?: Partial<Filter>[]) {
    const typeParam = getAnalysisTypeParam(type);
    let url = `${base}/workspaces/${workspaceSlug}/${typeParam}/${subject}`;

    const analysisFilters = intoDataAnalysisFilter(filters);
    if (analysisFilters) {
        url += `?${new URLSearchParams({ filters: JSON.stringify(analysisFilters) }).toString()}`;
    }

    return url;
}

interface DataCacheKeyParams {
    workspaceSlug: string;
    analysisType: AnalysisType;
    analysisSubject?: AnalysisSubject;
    filters?: Partial<Filter>[];
    nonCoreFilters?: Partial<Filter>[];
}

export function getDataCacheKey({
    workspaceSlug,
    analysisType,
    analysisSubject,
    filters,
    nonCoreFilters,
}: DataCacheKeyParams) {
    let key = `omlet:${workspaceSlug}:${analysisType}:${analysisSubject}`;

    if (filters) {
        key += `:${JSON.stringify(filters)}`;
    }

    if (nonCoreFilters) {
        key += `:${JSON.stringify(nonCoreFilters)}`;
    }

    return key;
}
