export function LogoLock() {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M14.334 0A7.333 7.333 0 0 0 7 7.333v4.714a4.668 4.668 0 0 0-4 4.62v10.666A4.667 4.667 0 0 0 7.667 32h16.667A4.667 4.667 0 0 0 29 27.333V16.667a4.668 4.668 0 0 0-4-4.62V7.333A7.333 7.333 0 0 0 17.667 0h-3.333zM21 12V7.333A3.333 3.333 0 0 0 17.667 4h-3.333A3.333 3.333 0 0 0 11 7.333V12h10z" fill="#262B2E"/>
            <rect x="5" y="14" width="22" height="16" rx="3" fill="#383E41"/>
            <rect x="7" y="16" width="18" height="12" rx="2" fill="#FDBD39"/>
            <mask id="ysyxo5k2ma" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="7" y="16" width="18" height="12">
                <rect x="7" y="16" width="18" height="12" rx="2" fill="#FDBD39"/>
            </mask>
            <g mask="url(#ysyxo5k2ma)">
                <path fillRule="evenodd" clipRule="evenodd" d="M11 31.413c6.741 2.467 14.206-.998 16.674-7.74 2.467-6.74-.998-14.206-7.74-16.673L11 31.413z" fill="#F69833" fillOpacity=".5"/>
            </g>
        </svg>
    );
}
