import { generatePath, Link } from "react-router-dom";

import { config } from "../../../config/frontend";
import { Button, ButtonAnchor, ButtonKind } from "../../library/Button/Button";
import { H2, H3 } from "../../library/Heading/Heading";
import { SubscriptionPlan, subscriptionPlanToString } from "../../models/SubscriptionPlan";
import { SubscriptionStatus } from "../../models/SubscriptionStatus";
import { type Workspace } from "../../models/Workspace";
import { RoutePath } from "../../RoutePath";
import { pluralize } from "../../utils";

import classes from "./ComponentLimitExceededInstructions.module.css";

interface Props {
    workspace: Workspace;
    onPricingClick: () => void;
    onContactUsClick: () => void;
}

export function ComponentLimitExceededInstructions({ workspace, onPricingClick, onContactUsClick }: Props) {
    const isPaidAdvancedPlan = workspace.subscription.plan === SubscriptionPlan.Advanced && workspace.subscription.status === SubscriptionStatus.Paid;
    const contactUsButton = (
        <ButtonAnchor
            kind={isPaidAdvancedPlan ? ButtonKind.Primary : ButtonKind.Secondary}
            href={`${config.LANDING_PAGE_BASE_URL}/contact/`}
            rel="nofollow external noopener noreferrer"
            target="_blank"
            onClick={onContactUsClick}>
            Contact us
        </ButtonAnchor>
    );
    return (
        <div className={classes.componentLimitInstructions}>
            <section>
                <H2 className={classes.h2}>
                    Exceeded component limit
                </H2>
                <p>
                    You’re currently on the
                    {" "}
                    {subscriptionPlanToString(workspace.subscription.plan)}
                    {" "}
                    plan where you can analyze up to
                    {" "}
                    {pluralize("component", workspace.subscription.features.numOfComponents)}
                    . To continue using Omlet without interruption,
                    {" "}
                    <strong>upgrade your plan</strong>
                    {" "}
                    or
                    {" "}
                    <strong>delete scans</strong>
                    {" "}
                    to lower your number of components.
                </p>
            </section>
            <section>
                <H3>
                    Upgrade your plan
                </H3>
                <p>
                    See plans below and upgrade to analyze as many components as you need.
                </p>
                <div className={classes.row}>
                    {isPaidAdvancedPlan
                        ? contactUsButton
                        : <>
                            <Button kind={ButtonKind.Primary} onClick={onPricingClick}>
                                See plans
                            </Button>
                            {contactUsButton}
                        </>
                    }
                </div>
            </section>
            <strong className={classes.or}>
                ...or
            </strong>
            <section>
                <H3>Delete scans</H3>
                <p>
                    If you believe Omlet analyzed your components incorrectly, you can delete your previous scans from the
                    {" "}
                    <Link to={generatePath(RoutePath.AllScans, { workspaceSlug: workspace.slug })} className={classes.link}>
                        All scans
                    </Link>
                    {" "}
                    page (found in the workspace menu at the top left) and scan again.
                    <br/>
                    <br/>
                    Before rescanning, learn more about how you can exclude certain components and/or directories:
                </p>
                <ButtonAnchor
                    kind={ButtonKind.Secondary}
                    href={"/l/docs/cli/include-param"}
                    rel="nofollow external noopener noreferrer"
                    target="_blank">
                    Learn more about excluding components
                </ButtonAnchor>
            </section>
        </div>
    );
}
