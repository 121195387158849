import { Dialog } from "../../../library/Dialog/Dialog";
import { useStore } from "../../../providers/StoreProvider/StoreProvider";

import { BillingInfo } from "./BillingInfo/BillingInfo";

import classes from "./BillingDialog.module.css";

export function BillingDialog() {
    const {
        actions: {
            closeBillingDialog,
        },
        selectors: {
            getWorkspace,
        },
    } = useStore();

    const workspace = getWorkspace();

    if (!workspace) {
        return null;
    }

    return (
        <Dialog
            className={classes.billingDialog}
            onClose={closeBillingDialog}>
            <BillingInfo
                subscription={workspace.subscription}
                numOfComponents={workspace.numOfComponents}
                numOfRecentAnalyses={workspace.numOfRecentAnalyses}
                workspaceSlug={workspace.slug}
            />
        </Dialog>
    );
}
