import { useEffect, useMemo, useState } from "react";

import { useQuery } from "@tanstack/react-query";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";

import {
    getCustomProperties,
    getLatestAnalysisComponent,
    getLatestAnalysisComponentDependencies,
    getLatestAnalysisComponentProps,
} from "../../api/api";
import { ButtonKind, ButtonLink } from "../../library/Button/Button";
import { IconBack } from "../../library/icons/IconBack";
import { IconDependencyTree } from "../../library/icons/IconDependencyTree";
import { IconProps } from "../../library/icons/IconProps";
import { logError } from "../../logger";
import { type Component } from "../../models/Component";
import { type ComponentProps } from "../../models/ComponentProps";
import { Feature } from "../../models/Feature";
import { isFeatureEnabled } from "../../models/Workspace";
import { useStore } from "../../providers/StoreProvider/StoreProvider";
import { compareString } from "../../sortUtils";
import {
    trackNavigateBackToComponentList,
    trackPropRowExpand,
    trackPropsTabClick,
} from "../../tracking";

import { ComponentDetailInfo } from "./componentDetailInfo/ComponentDetailInfo";
import { PropsTable } from "./propsTable/PropsTable";
import { PropUsages } from "./propUsages/PropUsages";
import { Tabs } from "./tabs/Tabs";
import { TreeViewWithReactFlowProvider as TreeView } from "./treeView/TreeView";

import classes from "./ComponentDetail.module.css";

function getComponentsWithLevel(
    mainComponentId: string,
    componentMap: Record<string, ComponentWithLevel>,
    dependencyMap: Record<string, Set<string>>,
    isPositiveStep: boolean,
) {
    const queue = [mainComponentId];
    const componentsWithLevel = [];

    while (queue.length > 0) {
        const currentComponentId = queue.shift()!;
        const currentComponent = componentMap[currentComponentId];
        if (!currentComponent) {
            continue;
        }
        const nextLevel = currentComponent.level + (isPositiveStep ? 1 : -1);
        for (const nextComponentId of dependencyMap[currentComponentId] ?? new Set()) {
            const nextComponent = componentMap[nextComponentId];
            if (!nextComponent || Number.isFinite(nextComponent.level)) {
                continue;
            }
            componentMap[nextComponentId].level = nextLevel;
            queue.push(nextComponentId);
            componentsWithLevel.push(componentMap[nextComponentId]);
        }
    }
    return componentsWithLevel;
}

interface DependencyTreeData {
    parents: ComponentWithLevel[];
    children: ComponentWithLevel[];
    dependencyMap: Record<string, Set<string>>;
    reverseDependencyMap: Record<string, Set<string>>;
}

export type ComponentWithLevel = Component & { level: number; };

type State<D> = {
    definitionId: string;
    data: D;
};

export function ComponentDetail() {
    const { workspaceSlug, componentSlug } = useParams();
    const [searchParams] = useSearchParams();
    const selectedProp = searchParams.get("selected_prop");
    const [expandedProps, setExpandedProps] = useState(new Set<string>());
    const navigate = useNavigate();
    const [dependencyTreeData, setDependencyTreeData] = useState<State<DependencyTreeData> | null>(null);
    const [componentProps, setComponentProps] = useState<State<ComponentProps> | null>(null);
    const { hash } = useLocation();
    const activeKey = hash.slice(1);

    const { selectors: { getComponentsURL, getWorkspace } } = useStore();
    const workspace = getWorkspace()!;

    const [component, setComponent] = useState<Component | undefined>(undefined);
    const [, definitionId] = useMemo(() => componentSlug?.split("::") ?? [], [componentSlug]);

    const { data: customProperties } = useQuery({
        queryKey: ["customProperties", workspaceSlug],
        async queryFn() {
            const customProperties = await getCustomProperties(workspaceSlug!);

            return Object.fromEntries(
                Object.entries(customProperties).sort(([k1], [k2]) => compareString(k1, k2))
            );
        },
        enabled: isFeatureEnabled(workspace, Feature.CustomProperties),
    });

    useEffect(() => {
        if (activeKey !== "props" || componentProps?.definitionId === definitionId) {
            return;
        }
        async function fetchComponentProps() {
            try {
                setComponentProps({
                    definitionId,
                    data: await getLatestAnalysisComponentProps(workspaceSlug!, encodeURIComponent(definitionId)),
                });
            } catch (error) {
                logError(error);

                navigate("..");
            }
        }
        setComponentProps(null);
        fetchComponentProps();
    }, [activeKey, workspaceSlug, definitionId]);

    useEffect(() => {
        async function fetchComponent() {
            try {
                setComponent(await getLatestAnalysisComponent(workspaceSlug!, encodeURIComponent(definitionId)));

            } catch (error) {
                logError(error);

                navigate("..");
            }
        }
        fetchComponent();
    }, [workspaceSlug, definitionId]);

    useEffect(() => {
        if (activeKey === "props" || dependencyTreeData?.definitionId === definitionId) {
            return;
        }

        async function fetchDependencies() {
            try {
                const { components, dependencies } = await getLatestAnalysisComponentDependencies(workspaceSlug!, encodeURIComponent(definitionId));
                const componentMap = Object.fromEntries(components.map(component => [component.definitionId, { level: Infinity, ...component }]));
                componentMap[definitionId].level = 0;
                const dependencyMap = dependencies.reduce<Record<string, Set<string>>>(
                    (acc, [source, target]) => {
                        if (!(source in acc)) {
                            acc[source] = new Set();
                        }
                        acc[source].add(target);
                        return acc;
                    },
                    {}
                );
                const reverseDependencyMap = dependencies.reduce<Record<string, Set<string>>>(
                    (acc, [ source, target]) => {
                        if (!(target in acc)) {
                            acc[target] = new Set();
                        }
                        acc[target].add(source);
                        return acc;
                    },
                    {}
                );
                const children = getComponentsWithLevel(definitionId, componentMap, dependencyMap, true);
                const parents = getComponentsWithLevel(definitionId, componentMap, reverseDependencyMap, false);
                setDependencyTreeData({
                    data: {
                        dependencyMap,
                        reverseDependencyMap,
                        parents,
                        children,
                    },
                    definitionId,
                });
            } catch (error) {
                logError(error);

                navigate("..");
            }
        }

        setDependencyTreeData(null);
        fetchDependencies();
    }, [activeKey, workspaceSlug, definitionId]);

    function handlePropClick(propName: string) {
        if (!expandedProps.has(propName)) {
            trackPropRowExpand();
        }
        setExpandedProps(prev => {
            const next = new Set(prev);
            if (next.has(propName)) {
                next.delete(propName);
            } else {
                next.add(propName);
            }
            return next;
        });
    }

    function handleTabClick(key: string) {
        if (key === "props") {
            trackPropsTabClick();
        }
    }

    const treeView = <TreeView
        loading={!dependencyTreeData || !component}
        mainComponent={component}
        parents={dependencyTreeData?.data.parents ?? []}
        children={dependencyTreeData?.data.children ?? []}
        reverseDependencyMap={dependencyTreeData?.data.reverseDependencyMap ?? {}}
        dependencyMap={dependencyTreeData?.data.dependencyMap ?? {}}/>;

    return (
        <main className={classes.componentDetail}>
            <div className={classes.leftPanel}>
                <nav>
                    <ButtonLink kind={ButtonKind.Secondary} to={getComponentsURL()} onClick={trackNavigateBackToComponentList} icon={<IconBack/>}>
                        All Components
                    </ButtonLink>
                </nav>
                <ComponentDetailInfo
                    component={component}
                    customProperties={customProperties}/>
            </div>
            {(
                selectedProp
                    ? <PropUsages />
                    : <Tabs items={[
                        {
                            key: "dependency-tree",
                            label: (
                                <>
                                    <IconDependencyTree />
                                    <div>
                                        Dependency Tree
                                    </div>
                                </>
                            ),
                            content: treeView,
                        },
                        {
                            key: "props",
                            label: (
                                <>
                                    <IconProps />
                                    <div>
                                        Props
                                    </div>
                                </>
                            ),
                            content: (
                                <PropsTable
                                    loading={!componentProps || !component}
                                    componentName={component?.name ?? ""}
                                    expandedProps={expandedProps}
                                    props={componentProps?.data.props ?? []}
                                    numberOfUsages={componentProps?.data.numberOfUsages ?? 0}
                                    onPropClick={handlePropClick} />
                            ),
                        },
                    ]}
                    onClick={handleTabClick}/>
            )}
        </main>
    );
}
