import { type AnalysisSubject } from "../../../models/AnalysisSubject";
import { getCompleteFilters, type Filter } from "../../../models/Filter";

import classes from "./EmptyState.module.css";

interface Props {
    analysisSubject?: AnalysisSubject;
    filters: Filter[];
    onRemoveAllFilters(): void;
}

export function EmptyState({
    analysisSubject,
    filters,
    onRemoveAllFilters,
}: Props) {
    const content = analysisSubject === undefined
        ? "Select from the options on the left to start analyzing usage data."
        : "No results with this selection.";

    const activeFilters = getCompleteFilters(filters);

    return (
        <div className={classes.emptyState}>
            {analysisSubject === undefined && <div className={classes.kaomoji}>{"ᕕ( ᐛ )ᕗ"}</div>}
            <div className={classes.content}>{content}</div>
            {analysisSubject !== undefined && activeFilters.length > 0 && (
                <button
                    className={classes.removeFiltersButton}
                    type="button"
                    onClick={onRemoveAllFilters}>
                    Remove filters
                </button>
            )}
        </div>
    );
}
