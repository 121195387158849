
import { config } from "../../../../config/frontend";
import { Button, ButtonAnchor, ButtonKind } from "../../../library/Button/Button";
import { Dialog } from "../../../library/Dialog/Dialog";
import { H2 } from "../../../library/Heading/Heading";
import { IconLock } from "../../../library/icons/IconLock";
import { type Subscription } from "../../../models/Subscription";
import { subscriptionPlanToString } from "../../../models/SubscriptionPlan";
import { useStore } from "../../../providers/StoreProvider/StoreProvider";
import { trackContactUsLinkClick } from "../../../tracking";
import { pluralize } from "../../../utils";

import classes from "./TagLimitDialog.module.css";

interface Props {
    subscription: Subscription;
    onClose(): void;
}

export function TagLimitDialog({
    subscription,
    onClose,
}: Props) {
    const { actions: { openPricingTableDialog } } = useStore();

    function handlePricingClick() {
        onClose();
        openPricingTableDialog("tag_limit_dialog");
    }

    function handleContactUsClick() {
        trackContactUsLinkClick({ source: "tag_limit_dialog" });
    }

    return (
        <Dialog
            className={classes.tagLimitDialog}
            onClose={onClose}>
            <div className={classes.content}>
                <div className={classes.header}>
                    <IconLock/>
                    <H2 className={classes.h2}>
                        More tags, more personalized insights
                    </H2>
                    <p className={classes.p}>
                        You’re currently on the {subscriptionPlanToString(subscription.plan)} plan
                        where you can have up to {pluralize("tag", subscription.features.tagLimit)}.
                        To create more tags, see plans below and contact us to upgrade your plan.
                    </p>
                </div>
                <div className={classes.footer}>
                    <Button onClick={handlePricingClick}>
                        See plans
                    </Button>
                    <ButtonAnchor
                        kind={ButtonKind.Secondary}
                        href={`${config.LANDING_PAGE_BASE_URL}/contact`}
                        target="_blank"
                        onClick={handleContactUsClick}>
                        Contact us
                    </ButtonAnchor>
                </div>
            </div>
        </Dialog>
    );
}
