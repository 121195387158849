import { Button } from "../../../library/Button/Button";
import { Dialog } from "../../../library/Dialog/Dialog";
import { H2 } from "../../../library/Heading/Heading";
import { useStore } from "../../../providers/StoreProvider/StoreProvider";

import { Illustration } from "./Illustration/Illustration";
import { Sparkles } from "./Sparkles/Sparkles";

import classes from "./PaymentSuccessDialog.module.css";

export function PaymentSuccessDialog() {
    const { actions: { closePaymentSuccessDialog } } = useStore();

    return (
        <Dialog onClose={closePaymentSuccessDialog} className={classes.paymentSuccessDialog}>
            <H2 className={classes.heading}>
                Thanks chef — your new plan is now active!
            </H2>
            <p className={classes.text}>
                You're now the proud owner of an Omlet plan, thanks for joining the fun!
            </p>
            <div className={classes.animation}>
                <Sparkles/>
                <Illustration className={classes.illustration}/>
                <Sparkles/>
            </div>
            <Button onClick={closePaymentSuccessDialog}>
                Go to dashboard
            </Button>
        </Dialog>
    );
}
