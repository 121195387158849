export enum Feature {
    AdvancedProps = "advancedProps",
    CustomProperties = "customProperties",
    SavedCharts = "savedCharts",
}

export function featureToString(feature: Feature): string {
    switch (feature) {
        case Feature.AdvancedProps:
            return "Advanced props usage";
        case Feature.SavedCharts:
            return "Save charts to dashboard";
        case Feature.CustomProperties:
            return "Custom properties";
    }
}
