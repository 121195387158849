import { useEffect, useRef, useState } from "react";

import classNames from "classnames";

import { Tooltip } from "../../../library/Tooltip/Tooltip";

import classes from "./TruncateMultiline.module.css";

interface Props {
    className?: string;
    maxLines: number;
    content: string;
}

export function TruncateMultiline({ className, maxLines, content }: Props) {
    const contentRef = useRef<HTMLSpanElement>(null);
    const [isClamped, setIsClamped] = useState(false);

    useEffect(() => {
        if (!contentRef.current) {
            return;
        }

        const contentStyles = window.getComputedStyle(contentRef.current);

        const referenceSpan = document.createElement("span");
        referenceSpan.textContent = content;

        referenceSpan.style.display = "inline-block";
        referenceSpan.style.visibility = "hidden";
        referenceSpan.style.fontFamily = contentStyles.fontFamily;
        referenceSpan.style.fontSize = contentStyles.fontSize;
        referenceSpan.style.lineHeight = contentStyles.lineHeight;
        referenceSpan.style.fontWeight = contentStyles.fontWeight;
        referenceSpan.style.letterSpacing = contentStyles.letterSpacing;
        referenceSpan.style.overflowWrap = contentStyles.overflowWrap;
        referenceSpan.style.width = contentStyles.width;

        document.body.append(referenceSpan);

        setIsClamped(contentRef.current.clientHeight < referenceSpan.clientHeight);

        referenceSpan.remove();
    }, [maxLines, content, contentRef.current]);

    return (
        <Tooltip content={isClamped ? content : undefined}>
            <span
                ref={contentRef}
                className={classNames(classes.truncateMultiline, className)}
                style={{ WebkitLineClamp: maxLines }}>
                {content}
            </span>
        </Tooltip>
    );
}
