import LZString from "lz-string";
import { generatePath } from "react-router-dom";

import { config } from "../../../config/frontend";
import { getColorMap, getNextColor, USER_DEFINED_TAG_COLORS } from "../../color";
import { type ChartDatum } from "../../common/chart/ChartDatum";
import { type ChartValue } from "../../common/chart/ChartValue";
import { ReservedProjectName } from "../../enums";
import { AnalysisSubject } from "../../models/AnalysisSubject";
import { AnalysisType } from "../../models/AnalysisType";
import { BreakdownType } from "../../models/BreakdownType";
import { type DataAnalysis } from "../../models/DataAnalysis";
import { type Filter } from "../../models/Filter";
import { FilterOperation } from "../../models/FilterOperation";
import { FilterType } from "../../models/FilterType";
import { type Project } from "../../models/Project";
import { type Tag, RESERVED_TAGS, NON_CORE_TAG_SLUG, createTag } from "../../models/Tag";
import { RoutePath } from "../../RoutePath";
import {
    compareChartDatumTagPercentage,
    compareProject,
    compareString,
    compareTag,
} from "../../sortUtils";
import { arrayGroup, generateSlug } from "../../utils";

function appendSearchParams(path: string, searchParams: Record<string, string | string[]>): string {
    const url = new URL(path, config.LANDING_PAGE_BASE_URL);
    for (const [key, values] of Object.entries(searchParams)) {
        if (!Array.isArray(values)) {
            url.searchParams.append(key, values);
            continue;
        }
        for (const value of values) {
            url.searchParams.append(key, value);
        }
    }
    return `${url.pathname}?${url.searchParams.toString()}`;
}

function appendFilter(path: string, filter: Filter): string {
    const url = new URL(path, config.LANDING_PAGE_BASE_URL);
    const filtersParam = url.searchParams.get("filters");

    let filters;
    if (filtersParam) {
        filters = JSON.parse(LZString.decompressFromEncodedURIComponent(filtersParam)) as Filter[];
        filters.push(filter);
    } else {
        filters = [filter];
    }

    url.searchParams.set("filters", LZString.compressToEncodedURIComponent(JSON.stringify(filters)));

    return `${url.pathname}?${url.searchParams.toString()}`;
}

function getChartDatumLink(analysisSubject: AnalysisSubject, workspaceSlug: string, label: string, id: string) {
    switch (analysisSubject) {
        case AnalysisSubject.Components:
            return generatePath(RoutePath.ComponentDetail, { workspaceSlug, componentSlug: encodeURIComponent(`${label}::${id}`) });
        case AnalysisSubject.Projects:
            return appendFilter(
                appendSearchParams(
                    generatePath(RoutePath.NewAnalytics, { workspaceSlug }),
                    { subject: AnalysisSubject.Components }
                ),
                {
                    type: FilterType.ProjectUsedIn,
                    operation: FilterOperation.Equals,
                    value: [id],
                }
            );
        case AnalysisSubject.Tags: {
            if (id === NON_CORE_TAG_SLUG) {
                return appendFilter(
                    appendSearchParams(
                        generatePath(RoutePath.NewAnalytics, { workspaceSlug }),
                        { subject: AnalysisSubject.Components }
                    ),
                    {
                        type: FilterType.Tag,
                        operation: FilterOperation.IsNotEqual,
                        value: [RESERVED_TAGS.CORE.slug],
                    }
                );
            } else {
                return appendFilter(
                    appendSearchParams(
                        generatePath(RoutePath.NewAnalytics, { workspaceSlug }),
                        { subject: AnalysisSubject.Components }
                    ),
                    {
                        type: FilterType.Tag,
                        operation: FilterOperation.Equals,
                        value: [id],
                    }
                );
            }
        }
    }
}

function getChartValueLink(analysisSubject: AnalysisSubject, breakdownType: BreakdownType, datumLink: string, barId: string) {
    if (analysisSubject === AnalysisSubject.Components) {
        switch (breakdownType) {
            case BreakdownType.ProjectUsedIn:
                return appendSearchParams(datumLink, { project: barId });
            default:
                return datumLink;
        }
    }
    switch (breakdownType) {
        case BreakdownType.ProjectDefined:
            return appendFilter(datumLink, {
                type: FilterType.ProjectDefined,
                operation: FilterOperation.Equals,
                value: [barId],
            });
        case BreakdownType.ProjectUsedIn:
            return appendFilter(datumLink, {
                type: FilterType.ProjectUsedIn,
                operation: FilterOperation.Equals,
                value: [barId],
            });
        case BreakdownType.Tag:
            if (barId === NON_CORE_TAG_SLUG) {
                return appendFilter(datumLink, {
                    type: FilterType.Tag,
                    operation: FilterOperation.IsNotEqual,
                    value: [RESERVED_TAGS.CORE.slug],
                });
            } else {
                return appendFilter(datumLink, {
                    type: FilterType.Tag,
                    operation: FilterOperation.Equals,
                    value: [barId],
                });
            }
        default:
        case undefined:
            return datumLink;
    }
}

function getProject(packageName: string, projectMap: Record<string, Project>): Project {
    // We store the projects from latest analyses
    // If the package name is not in the projectMap,
    // it's a legacy external package.
    return projectMap[packageName] ?? {
        name: packageName,
        packageName: packageName,
        slug: generateSlug(packageName),
        isInternal: false,
    };
}

function getAnalysisSubjectGroupKey(analysisSubject: AnalysisSubject): keyof DataAnalysis["key"] {
    switch (analysisSubject) {
        case AnalysisSubject.Components:
            return "childDefinitionId";
        case AnalysisSubject.Projects:
            return "parentPackageName";
        case AnalysisSubject.Tags:
            return "childTag";
    }
}

function getBreakdownTypeGroupKey(breakdownType: BreakdownType): keyof DataAnalysis["key"] {
    switch (breakdownType) {
        case BreakdownType.ProjectDefined:
            return "childPackageName";
        case BreakdownType.ProjectUsedIn:
            return "parentPackageName";
        case BreakdownType.Tag:
            return "childTag";
    }
}

function groupByAnalysisSubject(analyses: DataAnalysis[], analysisSubject: AnalysisSubject) {
    const groupKey = getAnalysisSubjectGroupKey(analysisSubject);

    return arrayGroup(analyses, analysis => {
        const key = analysis.key[groupKey];

        if (analysisSubject === AnalysisSubject.Tags && !key) {
            return RESERVED_TAGS.UNTAGGED.slug;
        }

        return key;
    });
}

function groupByBreakdownType(analyses: DataAnalysis[], breakdownType: BreakdownType) {
    const breakdownKey = getBreakdownTypeGroupKey(breakdownType);

    return arrayGroup(analyses, analysis => {
        const key = analysis.key[breakdownKey];

        if (!key) {
            if (breakdownType === BreakdownType.ProjectUsedIn) {
                return ReservedProjectName.None;
            } else if (breakdownType === BreakdownType.Tag) {
                return RESERVED_TAGS.UNTAGGED.slug;
            }
        }

        return key;
    });
}

type ComponentMap = Record<string, { packageName: string; componentName: string; tags: Set<string>; }>;

function getComponentMap(analyses: DataAnalysis[]): ComponentMap {
    const componentMap: ComponentMap = {};

    for (const { key: { childDefinitionId, childName, childPackageName, childTag } } of analyses) {
        if (!(childDefinitionId in componentMap)) {
            componentMap[childDefinitionId] = { packageName: childPackageName, componentName: childName, tags: childTag ? new Set([childTag]) : new Set() };
        } else if (childTag) {
            componentMap[childDefinitionId].tags.add(childTag);
        }
    }

    return componentMap;
}

function getChartValueData(
    key: string,
    componentMap: ComponentMap,
    tagMap: Record<string, Tag>,
    analysisSubject: AnalysisSubject
): Pick<ChartValue, "name" | "extra" | "tags"> {
    if (analysisSubject === AnalysisSubject.Components) {
        const { componentName: name, packageName: extra, tags } = componentMap[key];
        const childTags = [...tags];
        childTags.sort((a, b) => compareTag(tagMap[a], tagMap[b]));
        return { name, extra, tags: childTags };
    } else if (analysisSubject === AnalysisSubject.Tags) {
        const { name } = tagMap[key] ?? { name: key };

        return { name };
    }

    return { name: key };
}

function transformOverTimeData(
    analyses: DataAnalysis[],
    projectMap: Record<string, Project>,
    tagMap: Record<string, Tag>,
    analysisSubject: AnalysisSubject,
): ChartDatum[] {
    const componentMap = getComponentMap(analyses);
    const overTimeAnalysis = arrayGroup(analyses, analysis => analysis.key.analysisDate);


    const overTimeAnalysisGrouped = Object.fromEntries(
        Object.entries(overTimeAnalysis)
            .map(([key, value]) => [key, groupByAnalysisSubject(value, analysisSubject)])
    );

    // Convert data analysis to chart data
    let chartData: ChartDatum[] = Object.entries(overTimeAnalysisGrouped).map(([analysisDate, groupedAnalyses]) => {
        const values: ChartValue[] = Object.entries(groupedAnalyses)
            .map(([key, analyses]) => {
                const { name, extra, tags } = getChartValueData(key, componentMap, tagMap, analysisSubject);
                return {
                    id: key,
                    name,
                    extra,
                    value: analyses.reduce((sum, analysis) => sum + analysis.sumOfUsages, 0),
                    tags,
                };
            });

        return { id: analysisDate, label: analysisDate, values };
    });

    // Insert missing data points for each date
    // for example, in date T let's say there are 2 items (e.g. components), and in date T+1 there are 3 items
    // in order for chart to work properly the data points in each date must be consistent
    // so we're adding that missing item to date T with value 0
    const allIds = new Set(chartData.flatMap(({ values }) => values.map(({ id }) => id)));
    chartData = chartData.map(({ id, label, values }) => {
        const ids = new Set(values.map(({ id }) => id));
        const missingIds = [...allIds].filter(id => !ids.has(id));
        const newValues: ChartValue[] = [
            ...values,
            ...missingIds.map(id => {
                const { name, extra } = getChartValueData(id, componentMap, tagMap, analysisSubject);

                return {
                    id,
                    name,
                    extra,
                    value: 0,
                };
            }),
        ];

        switch (analysisSubject) {
            case AnalysisSubject.Components:
                newValues.sort((a, b) => {
                    if (a.value === b.value) {
                        return compareString(a.name, b.name);
                    }

                    return b.value - a.value;
                });
                break;
            case AnalysisSubject.Projects:
                newValues.sort((a, b) => compareString(a.name, b.name));
                break;
            case AnalysisSubject.Tags:
                newValues.sort((a, b) => compareTag(tagMap[a.id], tagMap[b.id]));
                newValues.forEach(value => {
                    value.color = tagMap[value.id]?.color;
                });
                break;
        }

        return { id, label, values: newValues };
    });

    chartData.sort((a, b) => new Date(a.label).getTime() - new Date(b.label).getTime());

    return chartData;
}

/*
Component usage data is duplicated if a component has multiple tags.
This is needed for breaking down usage count by tags and its a known issue (see OML-284).
This function dedupes usage counts to prevent that problem.
*/
function findSumOfUsageCount(analyses: DataAnalysis[]): number {
    const uniqueUsageCounts = analyses.reduce<Record<string, number>>((uniqueCounts, analysis) => {
        const key = `${analysis.key.parentPackageName}:${analysis.key.childDefinitionId}`;
        if (!(key in uniqueCounts)) {
            uniqueCounts[key] = analysis.sumOfUsages;
        }

        return uniqueCounts;
    }, {});

    return Object.values(uniqueUsageCounts).reduce((sum, count) => sum + count);
}

function transformLatestDataWithoutBreakdown(
    latestDataAnalysis: Record<string, DataAnalysis[]>,
    componentMap: ComponentMap,
    projectMap: Record<string, Project>,
    tagMap: Record<string, Tag>,
    analysisSubject: AnalysisSubject,
    workspaceSlug: string,
): ChartDatum[] {
    const reservedTags = Object.fromEntries(Object.values(RESERVED_TAGS).map(reservedTag => [reservedTag.slug, reservedTag]));

    const colorCountMap = (
        analysisSubject === AnalysisSubject.Components || analysisSubject === AnalysisSubject.Tags
            ? getColorMap(USER_DEFINED_TAG_COLORS,
                Object.values(latestDataAnalysis)
                    .map(analyses => analyses.map(({ key: { childTag } }) => childTag).filter(Boolean))
                    .filter(childTags => (
                        childTags.length > 0 && childTags.every(tag => !reservedTags[tag])
                        && tagMap[childTags[0]]
                    )).map(childTags => tagMap[childTags[0]].color)
            ) : {}
    );

    return Object.entries(latestDataAnalysis).map(([key, analyses]): ChartDatum => {
        let color: string | undefined;
        let id = "Usage count";
        let name = "Usage count";
        if (analysisSubject === AnalysisSubject.Components || analysisSubject === AnalysisSubject.Tags) {
            const childTags = analyses.map(({ key: { childTag } }) => childTag).filter(Boolean);
            const firstChildTag = childTags[0];
            const reservedChildTag = childTags.find(tag => reservedTags[tag]);

            if (reservedChildTag) {
                const reservedTag = reservedTags[reservedChildTag];
                const childTag = tagMap[reservedChildTag];

                color = reservedTag.color;
                id = reservedTag.slug;
                name = childTag?.name ?? reservedTag.name;
            } else if (childTags.length === 0) {
                color = RESERVED_TAGS.UNTAGGED.color;
                id = RESERVED_TAGS.UNTAGGED.slug;
                name = RESERVED_TAGS.UNTAGGED.name;
            } else if (tagMap[firstChildTag] === undefined) {
                color = getNextColor(colorCountMap);
                id = firstChildTag;
                name = firstChildTag;

                tagMap[firstChildTag] = createTag({
                    name: firstChildTag,
                    slug: firstChildTag,
                    color,
                });
                colorCountMap[color] += 1;
            } else {
                ({ color, slug: id, name } = tagMap[firstChildTag]);
            }
        }

        const { name: label, extra, tags } = getChartValueData(key, componentMap, tagMap, analysisSubject);
        return {
            id: key,
            label,
            link: getChartDatumLink(analysisSubject, workspaceSlug, label, key),
            values: [{
                id,
                name,
                value: findSumOfUsageCount(analyses),
                color,
                link: getChartDatumLink(analysisSubject, workspaceSlug, label, key),
                extra,
                tags,
            }],
        };
    });
}

function transformBrokenDownLatestDataAnalysis(
    brokenDownDataAnalyses: Record<string, DataAnalysis[]>,
    projectMap: Record<string, Project>,
    tagMap: Record<string, Tag>,
    breakdownType: BreakdownType,
    analysisSubject: AnalysisSubject,
    datumLink?: string,
): ChartValue[] {
    let values: ChartValue[];

    switch (breakdownType) {
        case BreakdownType.ProjectDefined:
            values = Object.entries(brokenDownDataAnalyses)
                .map(([projectName, analyses]) => ({
                    id: projectName,
                    name: projectName,
                    link: datumLink && getChartValueLink(analysisSubject, breakdownType, datumLink, projectName),
                    value: findSumOfUsageCount(analyses),
                }));

            values.sort((cv1, cv2) => {
                if (cv1.value === cv2.value) {
                    return compareProject(
                        getProject(cv1.name, projectMap),
                        getProject(cv2.name, projectMap),
                    );
                }

                return cv2.value - cv1.value;
            });
            break;
        case BreakdownType.ProjectUsedIn:
            values = Object.entries(brokenDownDataAnalyses)
                .filter(([projectName]) => projectName !== ReservedProjectName.None)
                .map(([projectName, analyses]) => ({
                    id: projectName,
                    name: projectName,
                    link: datumLink && getChartValueLink(analysisSubject, breakdownType, datumLink, projectName),
                    value: findSumOfUsageCount(analyses),
                }));

            values.sort((a, b) => compareString(a.name, b.name));
            break;
        case BreakdownType.Tag:
            values = Object.entries(brokenDownDataAnalyses)
                .map(([tag, analyses]) => ({
                    id: tag,
                    name: tagMap[tag]?.name ?? tag,
                    color: tagMap[tag]?.color,
                    link: datumLink && getChartValueLink(analysisSubject, breakdownType, datumLink, tag),
                    value: findSumOfUsageCount(analyses),
                }));

            values.sort((a, b) => compareTag(tagMap[a.id], tagMap[b.id]));
            break;
    }

    return values;
}

function transformLatestDataWithBreakdown(
    latestDataAnalysis: Record<string, DataAnalysis[]>,
    componentMap: ComponentMap,
    projectMap: Record<string, Project>,
    tagMap: Record<string, Tag>,
    analysisSubject: AnalysisSubject,
    breakdownType: BreakdownType,
    workspaceSlug: string,
): ChartDatum[] {
    const latestDataAnalysisWithBreakdown = Object.fromEntries(
        Object.entries(latestDataAnalysis)
            .map(([key, value]) => [key, groupByBreakdownType(value, breakdownType)])
    );

    return Object.entries(latestDataAnalysisWithBreakdown).map(([key, brokenDownDataAnalyses]): ChartDatum => {
        const { name: label } = getChartValueData(key, componentMap, tagMap, analysisSubject);
        const link = getChartDatumLink(analysisSubject, workspaceSlug, label, key);
        const values = transformBrokenDownLatestDataAnalysis(brokenDownDataAnalyses, projectMap, tagMap, breakdownType, analysisSubject, link);

        return {
            id: key,
            label,
            link: link,
            values,
        };
    });
}

function getTotalValue(datum: ChartDatum): number {
    return datum.values.reduce((sum, { value }) => sum + value, 0);
}

function transformLatestData(
    analysis: DataAnalysis[],
    projectMap: Record<string, Project>,
    tagMap: Record<string, Tag>,
    analysisSubject: AnalysisSubject,
    breakdownType: BreakdownType | undefined,
    workspaceSlug: string,
): ChartDatum[] {
    const componentMap = getComponentMap(analysis);
    const latestDataAnalysis = groupByAnalysisSubject(analysis, analysisSubject);

    const chartData = breakdownType === undefined
        ? transformLatestDataWithoutBreakdown(latestDataAnalysis, componentMap, projectMap, tagMap, analysisSubject, workspaceSlug)
        : transformLatestDataWithBreakdown(latestDataAnalysis, componentMap, projectMap, tagMap, analysisSubject, breakdownType, workspaceSlug);

    if (analysisSubject === AnalysisSubject.Tags) {
        chartData.sort((a, b) => {
            if (breakdownType) {
                return compareTag(tagMap[a.id], tagMap[b.id]);
            }
            if (a.id === RESERVED_TAGS.UNTAGGED.slug) {
                return 1;
            }
            if (b.id === RESERVED_TAGS.UNTAGGED.slug) {
                return -1;
            }
            return (getTotalValue(b) - getTotalValue(a)) || compareTag(tagMap[a.id], tagMap[b.id]);
        });
    } else if (breakdownType === BreakdownType.Tag) {
        chartData.sort(compareChartDatumTagPercentage);
    } else if (breakdownType === BreakdownType.ProjectUsedIn) {
        chartData.sort((a, b) => {
            if (b.values.length === a.values.length) {
                return compareString(a.label, b.label);
            }

            return b.values.length - a.values.length;
        });
    } else {
        chartData.sort((a, b) => {
            if (a.values[0].value === b.values[0].value) {
                return compareString(a.label, b.label);
            }

            return b.values[0].value - a.values[0].value;
        });
    }

    return chartData;
}


export function insertDuplicateData(data: ChartDatum[]): ChartDatum[] {
    if (data.length !== 1) {
        return data;
    }
    const [datum] = data;
    const duplicateDataLabel = new Date(new Date(datum.label).getTime() + 1).toISOString();
    return [
        datum,
        {
            id: duplicateDataLabel,
            label: duplicateDataLabel,
            values: datum.values,
        },
    ];
}

export function transform(
    analysis: DataAnalysis[],
    projectMap: Record<string, Project>,
    tags: Tag[],
    analysisType: AnalysisType,
    analysisSubject: AnalysisSubject,
    breakdownType: BreakdownType | undefined,
    workspaceSlug: string,
): ChartDatum[] {
    const tagMap = Object.fromEntries(tags.map(t => [t.slug, t]));
    switch (analysisType) {
        case AnalysisType.DataOverTime:
            return transformOverTimeData(analysis, projectMap, tagMap, analysisSubject);
        case AnalysisType.LatestData:
            return transformLatestData(analysis, projectMap, tagMap, analysisSubject, breakdownType, workspaceSlug);
    }
}

