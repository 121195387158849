import { type ReactNode, useState, useEffect, useMemo } from "react";

import LZString from "lz-string";
import { generatePath, Link } from "react-router-dom";

import { createSavedChart, getDataAnalysis } from "../../../api/api";
import { getDataCacheKey } from "../../../api/urlBuilder";
import { DashboardChart } from "../../../common/DashboardChart/DashboardChart";
import { SaveChartPopover } from "../../../common/SaveChartPopover/SaveChartPopover";
import { transform } from "../../../containers/analytics/dataTransformer";
import { ContextMenu } from "../../../library/ContextMenu/ContextMenu";
import { useToast } from "../../../library/Toast/Toast";
import { logError } from "../../../logger";
import { type AnalysisSubject } from "../../../models/AnalysisSubject";
import { AnalysisType } from "../../../models/AnalysisType";
import { type BreakdownType } from "../../../models/BreakdownType";
import { type DataAnalysis } from "../../../models/DataAnalysis";
import { type Filter } from "../../../models/Filter";
import { type Project } from "../../../models/Project";
import { type Tag, getCoreTag, getNonCoreTag } from "../../../models/Tag";
import { useDataCacheStore } from "../../../providers/DataCacheProvider/DataCacheProvider";
import { RoutePath } from "../../../RoutePath";
import { trackCreateSavedChart, trackPopularDashboardChartTitleClick } from "../../../tracking";
import { alertError } from "../../../utils";
import { type PredefinedChartType } from "../constants";
import { ChartInsight } from "../insight/ChartInsight";

interface Props {
    workspaceSlug: string;
    tags: Tag[];
    projectMap: Record<string, Project>;
    chartType: PredefinedChartType;
    analysisType: AnalysisType;
    analysisSubject: AnalysisSubject;
    filters: Filter[];
    nonCoreFilters?: Filter[];
    breakdownType?: BreakdownType;
    fallbacks?: {
        filters: Filter[];
        description?: string;
        title?: string;
    }[];
    title: string;
    description: ReactNode;
    emptyStateMessage: ReactNode;
    linksDisabled: boolean;
    readOnly: boolean;
    onLoad(chartType: PredefinedChartType): void;
}

interface State {
    filters?: Filter[];
    title: string;
    description: ReactNode;
}

export function PopularDashboardChart({
    workspaceSlug,
    tags,
    projectMap,
    chartType,
    analysisType,
    analysisSubject,
    filters: initialFilters,
    nonCoreFilters,
    breakdownType,
    title: defaultTitle,
    description: defaultDescription,
    emptyStateMessage,
    fallbacks = [],
    linksDisabled,
    readOnly,
    onLoad,
}: Props) {
    const toast = useToast();

    const [saveChartPopoverOpen, setSaveChartPopoverOpen] = useState(false);

    const coreTag = useMemo(() => getCoreTag(tags), [tags]);
    const nonCoreTag = getNonCoreTag(coreTag);
    const [state, setState] = useState<State>({
        filters: initialFilters,
        title: defaultTitle,
        description: defaultDescription,
    });

    const { filters, title, description } = state;

    const {
        selectors: { getAnalysisData },
        actions: { cacheAnalysisData, addSavedChartData },
    } = useDataCacheStore();

    const dataCacheKey = getDataCacheKey({ workspaceSlug, analysisType, analysisSubject, filters, nonCoreFilters });
    const analysisData = getAnalysisData(dataCacheKey);

    const chartData = useMemo(() =>
        analysisData && transform(analysisData, projectMap, tags, analysisType, analysisSubject, breakdownType, workspaceSlug)
    , [analysisData, breakdownType]);

    function getLink() {
        const searchParams = new URLSearchParams();

        if (analysisType !== AnalysisType.LatestData) {
            searchParams.set("type", analysisType);
        }

        if (analysisSubject) {
            searchParams.set("subject", analysisSubject);
        }

        if (!nonCoreFilters?.length && filters?.length) {
            searchParams.set("filters", LZString.compressToEncodedURIComponent(JSON.stringify(filters)));
        }

        if (breakdownType) {
            searchParams.set("breakdown", breakdownType);
        }

        const pathname = generatePath(RoutePath.NewAnalytics, { workspaceSlug });
        return `${pathname}?${searchParams.toString()}`;
    }

    async function handleSave(name: string, description: string) {
        try {
            setSaveChartPopoverOpen(false);

            const savedChart = await createSavedChart(workspaceSlug, {
                name,
                description,
                analysisType,
                analysisSubject,
                filters: filters ?? [],
                breakdownType,
            });

            trackCreateSavedChart({
                slug: savedChart.slug,
                name,
                description,
                analysisType,
                analysisSubject,
                breakdownType,
            });

            addSavedChartData(savedChart);

            toast.show(
                <>
                    <span>Chart saved to dashboard&nbsp;•&nbsp;</span>
                    <Link
                        to={generatePath(RoutePath.SavedCharts, { workspaceSlug })}
                        onClick={() => toast.hide()}>
                        Go to Saved Dashboard
                    </Link>
                </>,
                10000
            );
        } catch (error) {
            logError(error);
            alertError(error as Error);
        }
    }

    useEffect(() => {
        if (chartData !== null) {
            onLoad(chartType);
        }

        let abortController: AbortController | undefined;

        async function fetchDataAnalsis(dataFilters?: Filter[]): Promise<DataAnalysis[] | null> {
            try {
                abortController = new AbortController();

                const dataAnalysis = await getDataAnalysis(workspaceSlug, analysisType, analysisSubject, dataFilters, abortController.signal);
                if (nonCoreFilters) {
                    const nonCoreDataAnalysis = await getDataAnalysis(workspaceSlug, analysisType, analysisSubject, nonCoreFilters);
                    nonCoreDataAnalysis.forEach(({ key }) => key.childTag = nonCoreTag.slug);

                    dataAnalysis.push(...nonCoreDataAnalysis);
                }

                return dataAnalysis;
            } catch (error) {
                if (!(error instanceof DOMException) || error.name !== "AbortError") {
                    logError(error);

                    return [];
                }

                return null;
            }
        }

        async function fetchData() {
            const dataParams: State[] = [{
                filters,
                description,
                title,
            }, ...fallbacks.map(
                (fb => ({
                    filters: fb.filters,
                    title: fb.title ?? title,
                    description: fb.description ?? description,
                }))
            )];

            let selectedParams = dataParams[0];
            let analysisData: DataAnalysis[] | null = [];

            for (const params of dataParams) {
                try {
                    analysisData = await fetchDataAnalsis(params.filters);

                    if (analysisData && analysisData.length > 0) {
                        selectedParams = params;
                        break;
                    }
                } catch (error) {
                    logError(error);
                }
            }

            setState(selectedParams);

            if (analysisData) {
                const dataCacheKey = getDataCacheKey({ workspaceSlug, analysisType, analysisSubject, filters: selectedParams.filters, nonCoreFilters });
                cacheAnalysisData(dataCacheKey, analysisData);
            }

            onLoad(chartType);
        }

        fetchData();

        return () => {
            abortController?.abort();
        };
    }, []);

    function renderContextMenuItems() {
        if (linksDisabled || readOnly || nonCoreFilters !== undefined) {
            return null;
        }

        return (
            <ContextMenu.Button
                onClick={() => setSaveChartPopoverOpen(true)}>
                Save to dashboard
            </ContextMenu.Button>
        );
    }

    function renderInsight() {
        return (
            <ChartInsight chartType={chartType} data={chartData} analysisType={analysisType}/>
        );
    }

    function renderSaveChartPopover(anchor: HTMLElement | null) {
        if (!anchor || !saveChartPopoverOpen) {
            return null;
        }

        return (
            <SaveChartPopover
                anchor={anchor}
                offset={5}
                chartName={title}
                chartDescription={description as string}
                onSave={handleSave}
                onCancel={() => setSaveChartPopoverOpen(false)}/>
        );
    }

    return (
        <DashboardChart
            workspaceSlug={workspaceSlug}
            data={chartData}
            tags={tags}
            projectMap={projectMap}
            title={title}
            description={description}
            analysisType={analysisType}
            analysisSubject={analysisSubject}
            breakdownType={breakdownType}
            link={getLink()}
            linksDisabled={linksDisabled}
            contextMenuItems={renderContextMenuItems()}
            emptyStateMessage={emptyStateMessage}
            insight={renderInsight()}
            renderSaveChartPopover={renderSaveChartPopover}
            onHeaderClick={() => trackPopularDashboardChartTitleClick({ chartType })}/>
    );
}

