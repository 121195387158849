export function LogoGoogle() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20">
            <path d="M18.64 10.204c0-.638-.057-1.252-.164-1.84H10v3.48h4.844a4.14 4.14 0 0 1-1.796 2.717v2.258h2.908c1.702-1.567 2.684-3.874 2.684-6.615z" fill="#4285F4"/>
            <path d="M10 19c2.43 0 4.467-.806 5.956-2.18l-2.909-2.259c-.805.54-1.836.86-3.047.86-2.344 0-4.328-1.584-5.036-3.711H1.957v2.332A8.997 8.997 0 0 0 10 19z" fill="#34A853"/>
            <path d="M4.964 11.71A5.41 5.41 0 0 1 4.682 10c0-.593.102-1.17.282-1.71V5.958H1.957A8.997 8.997 0 0 0 1 10c0 1.452.348 2.827.957 4.042l3.007-2.332z" fill="#FBBC05"/>
            <path d="M10 4.58c1.321 0 2.508.454 3.44 1.345l2.582-2.58C14.462 1.891 12.426 1 10 1a8.997 8.997 0 0 0-8.043 4.958L4.964 8.29C5.672 6.163 7.656 4.58 10 4.58z" fill="#EA4335"/>
        </svg>
    );
}
