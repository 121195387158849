import { type PropsWithChildren } from "react";
import { useEffect } from "react";

import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import {
    type LoaderFunctionArgs,
    Navigate,
    Outlet,
    Route,
    RouterProvider,
    createBrowserRouter,
    createRoutesFromElements,
    matchPath,
    useLocation,
    useBeforeUnload,
    useOutletContext,
    useSearchParams,
} from "react-router-dom";

import { authenticateSharedPage, invalidateSharedPageAuthentication } from "./api/api";
import { WindowSizeProvider } from "./hooks/useWindowSize";
import { ToastProvider } from "./library/Toast/Toast";
import { AllScans } from "./pages/allScans/AllScans";
import { AnalyticsShell } from "./pages/analyticsShell/AnalyticsShell";
import { AppShell } from "./pages/appShell/AppShell";
import { Auth } from "./pages/auth/Auth";
import { CliLoginSuccess } from "./pages/auth/CliLoginSuccess";
import { LoginSuccess } from "./pages/auth/LoginSuccess";
import { LoginWithEmail } from "./pages/auth/LoginWithEmail";
import { ComponentDetail } from "./pages/componentDetail/ComponentDetail";
import { ComponentLimitExceeded } from "./pages/ComponentLimitExceeded/ComponentLimitExceeded";
import { Components } from "./pages/components/Components";
import { CreateWorkspace } from "./pages/createWorkspace/CreateWorkspace";
import { Dashboard } from "./pages/dashboard/Dashboard";
import { DataRetentionLimitReached } from "./pages/dataRetentionLimitReached/DataRetentionLimitReached";
import { Default } from "./pages/default/Default";
import { InvalidInvite } from "./pages/invalidInvite/InvalidInvite";
import { MemberLimitExceeded } from "./pages/memberLimitExceeded/MemberLimitExceeded";
import { NewAnalytics } from "./pages/newAnalytics/NewAnalytics";
import { Onboarding } from "./pages/onboarding/Onboarding";
import { PaymentSuccess } from "./pages/PaymentSuccess/PaymentSuccess";
import { PopularCharts } from "./pages/popularCharts/PopularCharts";
import { Pricing } from "./pages/Pricing/Pricing";
import { DesignerQuickStart } from "./pages/quickStart/designerQuickStart/DesignerQuickStart";
import { DeveloperQuickStart } from "./pages/quickStart/developerQuickStart/DeveloperQuickStart";
import { QuickStart } from "./pages/quickStart/QuickStart";
import { SavedChart } from "./pages/savedChart/SavedChart";
import { SavedCharts } from "./pages/savedCharts/SavedCharts";
import { SelectProfession } from "./pages/selectProfession/SelectProfession";
import { SubscriptionRequiredPage } from "./pages/subscriptionRequiredPage/SubscriptionRequiredPage";
import { TrialEnd } from "./pages/trialEnd/TrialEnd";
import { Tutorial } from "./pages/tutorial/Tutorial";
import { DataCacheProvider } from "./providers/DataCacheProvider/DataCacheProvider";
import { PreferencesStoreProvider } from "./providers/PreferencesStoreProvider/PreferencesStoreProvider";
import { StoreProvider } from "./providers/StoreProvider/StoreProvider";
import { RoutePath } from "./RoutePath";
import { trackVisit } from "./tracking";

function getMatchingRouteInfo(pathname: string) {
    const path = Object.entries(RoutePath).find(([_, value]) => matchPath(value, pathname));
    if (path) {
        return {
            page: path[0],
            route: path[1],
        };
    }

    return {
        page: "Unknown",
        route: RoutePath.Unknown,
    };
}

interface TitleWrapperProps {
    title: string;
}

function TitleWrapper({ title, children }: PropsWithChildren<TitleWrapperProps>) {
    const setTitle = useOutletContext<(title:string | null) => void>();
    useEffect(() => {
        setTitle(title);
        return () => setTitle(null);
    }, [title]);
    return <>
        {children}
    </>;
}

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route
            loader={rootLoader}
            element={
                <ToastProvider>
                    <Root/>
                </ToastProvider>
            }>
            <Route path={RoutePath.Login} element={<Auth/>}/>
            <Route path={RoutePath.LoginWithEmail} element={<LoginWithEmail/>}/>
            <Route path={RoutePath.CliLoginSuccess} element={<CliLoginSuccess/>}/>
            <Route path={RoutePath.LoginSuccess} element={<LoginSuccess/>}/>
            <Route path={RoutePath.InvalidInvite} element={<InvalidInvite/>}/>
            <Route path={RoutePath.SelectProfession} element={<SelectProfession/>}/>
            <Route path={RoutePath.CreateWorkspace} element={<CreateWorkspace/>}/>
            <Route path={RoutePath.Tutorial} element={<Tutorial/>}/>
            <Route path={RoutePath.QuickStart}>
                <Route index element={<QuickStart/>}/>
                <Route path="developer" element={<DeveloperQuickStart/>}/>
                <Route path="designer" element={<DesignerQuickStart/>}/>
            </Route>
            <Route path={RoutePath.Onboarding} element={<Onboarding/>}/>
            <Route path={RoutePath.MemberLimitExceeded} element={<MemberLimitExceeded />}/>
            <Route path={RoutePath.TrialEnd} element={<TrialEnd/>}/>
            <Route path={RoutePath.DataRetentionLimitReached} element={<DataRetentionLimitReached />}/>
            <Route path={RoutePath.ComponentLimitExceeded} element={<ComponentLimitExceeded/>}/>
            <Route path={RoutePath.Pricing} element={<Pricing/>}/>
            <Route path={RoutePath.RepoHome} element={<DataCacheProvider><AppShell/></DataCacheProvider>}>
                <Route path="all-scans" element={
                    <SubscriptionRequiredPage>
                        <TitleWrapper title="All Scans">
                            <AllScans/>
                        </TitleWrapper>
                    </SubscriptionRequiredPage>
                }/>
                <Route path="analytics" element={<AnalyticsShell/>}>
                    <Route element={<Dashboard/>}>
                        <Route index element={<PopularCharts/>}/>
                        <Route path="saved-charts" element={<SavedCharts/>}/>
                    </Route>
                    <Route path="saved-charts/:savedChartSlug" element={
                        <SubscriptionRequiredPage>
                            <SavedChart/>
                        </SubscriptionRequiredPage>
                    }/>
                    <Route path="view" element={
                        <SubscriptionRequiredPage>
                            <NewAnalytics/>
                        </SubscriptionRequiredPage>
                    }/>
                </Route>

                <Route path="components">
                    <Route index element={(
                        <SubscriptionRequiredPage allowEmptyQueryParams>
                            <Components/>
                        </SubscriptionRequiredPage>
                    )}/>
                    <Route path=":componentSlug" element={
                        <SubscriptionRequiredPage>
                            <ComponentDetail/>
                        </SubscriptionRequiredPage>
                    }/>
                </Route>
                <Route path="manage-tags" element={
                    <Navigate to="../components"/>
                }/>
                <Route path="payment-success" element={<PaymentSuccess/>}/>
                <Route path="pricing" element={<Pricing/>}/>
                <Route path="data-issues" element={<Navigate to={{ pathname: "../analytics", search: "?data_issue_dialog_open=true" }} replace />}/>
                {["", "*"].map((path, idx) => (
                    <Route path={path} element={<Navigate to="analytics" replace/>} key={idx}/>
                ))}
            </Route>
            <Route path={RoutePath.Unknown} element={<Default/>}/>
        </Route>
    )
);

async function rootLoader({ request }: LoaderFunctionArgs) {
    const page = new URL(request.url);
    const code = page.searchParams.get("token");

    if (code) {
        await authenticateSharedPage(code);
    }

    return null;
}

function Root() {
    const location = useLocation();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const routeInfo = getMatchingRouteInfo(location.pathname);
        const fromShare = routeInfo.route !== RoutePath.CliLoginSuccess && searchParams.has("token");

        if (routeInfo.route !== "*") {
            trackVisit({ path: location.pathname, page: routeInfo.page, route: routeInfo.route, fromShare });
        }
    }, [location]);

    useBeforeUnload(() => {
        invalidateSharedPageAuthentication();
    });

    return <Outlet/>;
}

const queryClient = new QueryClient();
export function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <WindowSizeProvider>
                <StoreProvider>
                    <PreferencesStoreProvider>
                        <RouterProvider router={router}/>
                    </PreferencesStoreProvider>
                </StoreProvider>
            </WindowSizeProvider>
        </QueryClientProvider>
    );
}
