import { config } from "../config/frontend";

import { type User } from "./models/User";

export function setUser(user: User) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,no-undef
    Intercom("boot", {
        "app_id": config.INTERCOM_APP_ID,
        "user_id": user.id,
        "email": user.email,
        "name": user.fullName,
        "avatar": { type: "avatar", image_url: user.avatarUrl },
        "user_hash": user.intercomHash,
    });
}

export function showNewMessage(prefix?: string) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,no-undef
    Intercom("showNewMessage", prefix);
}
