import { SegmentedControl } from "../../../../../library/SegmentedControl/SegmentedControl";
import { Tooltip } from "../../../../../library/Tooltip/Tooltip";
import { getAnalysisSubjectLabel, AnalysisSubject } from "../../../../../models/AnalysisSubject";

interface ButtonProps {
    value: AnalysisSubject;
}

function AnalysisSubjectButton({ value }: ButtonProps) {
    return (
        <SegmentedControl.Option value={value}>
            {getAnalysisSubjectLabel(value)}
        </SegmentedControl.Option>
    );
}

interface Props {
    subject?: AnalysisSubject;
    disabled?: boolean;
    onChange(subject: AnalysisSubject): void;
}

export function AnalysisSubjectPicker({
    subject,
    disabled = false,
    onChange,
}: Props) {
    return (
        <Tooltip content={disabled ? "Ask to join workspace to edit" : undefined} followCursor>
            <SegmentedControl value={subject} disabled={disabled} onChange={onChange}>
                <AnalysisSubjectButton value={AnalysisSubject.Components}/>
                <AnalysisSubjectButton value={AnalysisSubject.Projects}/>
                <AnalysisSubjectButton value={AnalysisSubject.Tags}/>
            </SegmentedControl>
        </Tooltip>
    );
}
